import React, { useState, useEffect, useRef } from "react"
// import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Button } from "../../components/ui/button"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs"
import { EyeIcon, EyeOffIcon, XIcon, Rocket, Zap, Code, Cpu, Database, Wifi, Cog, Terminal, Bot, Loader2 } from 'lucide-react'
import apiClient from "../../utils/apiClient";
// import { toast } from 'react-hot-toast';

const FloatingParticles = () => {
  const icons = [Zap, Code, Cpu, Database, Wifi, Cog, Terminal, Bot];

  return (
    <div className="absolute inset-0 overflow-hidden">
      {icons.map((Icon, i) => (
        <motion.div
          key={i}
          className="absolute"
          style={{
            //   top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
          animate={{
            y: [0, Math.random() * 100 - 30],
            x: [0, Math.random() * 100 - 50],
            scale: [1, Math.random() + 0.5, 1],
            opacity: [0, 1, 0],
          }}
          transition={{
            duration: Math.random() * 5 + 5,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        >
          <Icon className="h-6 w-6 text-blue-500" /> {/* Adjust size/color if needed */}
        </motion.div>
      ))}
    </div>
  );
}

const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return re.test(email)
}

const validatePassword = (password) => {
  const minLength = 8
  const hasUpperCase = /[A-Z]/.test(password)
  const hasLowerCase = /[a-z]/.test(password)
  const hasDigit = /\d/.test(password)
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password)

  return (
    password.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasDigit &&
    hasSpecialChar
  )
}

export default function LoginDropdown({ isOpen, onClose }) {
  const [showPassword, setShowPassword] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [registerError, setRegisterError] = useState('')
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [signupEmail, setSignupEmail] = useState('')
  const [signupPassword, setSignupPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [signupName, setSignupName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [resetToken, setResetToken] = useState('');
  const [isSendingReset, setIsSendingReset] = useState(false);
  const [buttonText, setButtonText] = useState('Send Reset Link');

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(signupEmail)) {
      setRegisterError('Please enter a valid email address');
      return;
    }

    if (!validatePassword(signupPassword)) {
      setRegisterError('Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character');
      return;
    }

    if (signupPassword !== confirmPassword) {
      setRegisterError('Passwords do not match');
      return;
    }

    if (!signupName.trim()) {
      setRegisterError('Please enter your name');
      return;
    }

    setRegisterError(''); // Clear any previous errors
    setIsLoading(true);

    try {
      const response = await apiClient.post('/auth/register', {
        name: signupName,
        email: signupEmail,
        password: signupPassword
      });

      if (response.status === 201) {
        const loginResponse = await apiClient.post('/auth/login', {
          email: signupEmail,
          password: signupPassword
        });

        if (loginResponse.data.accessToken) {
          localStorage.setItem('accessToken', loginResponse.data.accessToken);
          onClose();
        }
      }
    } catch (error) {
      setRegisterError(error.response?.data?.error || 'Registration failed');
      console.error('Registration error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(loginEmail)) {
      setLoginError('Please enter a valid email address');
      return;
    }

    if (!validatePassword(loginPassword)) {
      setLoginError('Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character');
      return;
    }

    setLoginError(''); // Clear any previous errors
    setIsLoading(true); // Add loading state

    try {
      const response = await apiClient.post('/auth/login', {
        email: loginEmail,
        password: loginPassword
      });

      if (response.data.accessToken) {
        localStorage.setItem('accessToken', response.data.accessToken);
        onClose(); // Close the modal
        // You might want to add some success feedback here
        // Or redirect to another page
      }
    } catch (error) {
      setLoginError(error.response?.data?.error || 'Login failed');
      console.error('Login error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setIsForgotPassword(true);
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setForgotPasswordError('');
    setIsSendingReset(true);
    console.log('Sending request...'); 

    try {
        const response = await apiClient.post('/auth/forgot-password', {
            email: forgotPasswordEmail,
        });

        if (response.status === 200) {
            setButtonText('Sent!');
            setTimeout(() => {
                setIsForgotPassword(false);
                setButtonText('Send Reset Link'); // Reset text when modal closes
            }, 1000);
        }
    } catch (error) {
        console.log('Error:', error);
        setForgotPasswordError(error.response?.data?.error || 'Failed to send reset email');
    } finally {
        setIsSendingReset(false);
        // alert('Email sent!');
    }
  };

  const handleResetLinkClick = (token) => {
    setResetToken(token);
    setIsResetPassword(true);
  };

  const formVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  }


  return (
    <div className="fixed inset-0 bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <Card className="w-full max-w-lg bg-white/90 backdrop-blur-md shadow-xl relative overflow-hidden">
        {/* <FloatingParticles /> */}
        <CardHeader className="relative z-10">
          <CardTitle className="text-3xl font-bold text-center bg-gradient-to-r from-blue-600 via-cyan-500 to-purple-600 bg-clip-text text-transparent flex items-center justify-center">
            <span className="text-orange-400">STEM</span> <Rocket className="mx-2 h-8 w-8 text-red-500" /> <span className="text-green-400">Verse</span>
          </CardTitle>
          <CardDescription className="text-center text-gray-600">Sign in to your account or create a new one</CardDescription>
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            <XIcon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </Button>
        </CardHeader>
        <CardContent className="relative z-10">
          <Tabs defaultValue="login" className="w-full">
            <TabsList className="grid w-full grid-cols-2 mb-4">
              <TabsTrigger value="login" className="data-[state=active]:bg-gradient-to-r data-[state=active]:from-blue-500 data-[state=active]:to-purple-500 data-[state=active]:text-white">Login</TabsTrigger>
              <TabsTrigger value="register" className="data-[state=active]:bg-gradient-to-r data-[state=active]:from-blue-500 data-[state=active]:to-purple-500 data-[state=active]:text-white">Register</TabsTrigger>
            </TabsList>
            <AnimatePresence mode="wait">
              <TabsContent value="login" key="login">
                <motion.form
                  id="login-form"
                  onSubmit={handleLoginSubmit}
                  className="space-y-4"
                  variants={formVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <div className="space-y-2">
                    <Label htmlFor="email" className="text-gray-700">Email</Label>
                    <Input
                      id="email"
                      type="email"
                      placeholder="Enter your email"
                      required
                      className="bg-white/50 border-cyan-200 focus:border-cyan-400 focus:ring-cyan-400"
                      value={loginEmail}
                      onChange={(e) => setLoginEmail(e.target.value)}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="password" className="text-gray-700">Password</Label>
                    <div className="relative">
                      <Input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        required
                        className="bg-white/50 border-cyan-200 focus:border-cyan-400 focus:ring-cyan-400"
                        value={loginPassword}
                        onChange={(e) => setLoginPassword(e.target.value)}
                      />
                      <Button
                        type="button"
                        variant="ghost"
                        size="icon"
                        className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <EyeOffIcon className="h-4 w-4 text-gray-500" />
                        ) : (
                          <EyeIcon className="h-4 w-4 text-gray-500" />
                        )}
                        <span className="sr-only">Toggle password visibility</span>
                      </Button>
                    </div>
                  </div>
                  {loginError && <p className="text-sm text-red-500">{loginError}</p>}
                  <Button type="submit" className="w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white">Sign In</Button>
                  <p className="text-sm text-gray-600 text-center">
                    <a href="#" onClick={handleForgotPassword} className="text-blue-500 hover:text-blue-700 underline underline-offset-4">Forgot Password?</a>
                  </p>
                </motion.form>
              </TabsContent>
              <TabsContent value="register" key="register">
                <motion.form
                  id="register-form"
                  onSubmit={handleRegisterSubmit}
                  className="space-y-4"
                  variants={formVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <div className="space-y-2">
                    <Label htmlFor="name" className="text-gray-700">Name</Label>
                    <Input id="name" placeholder="Enter your full name" required className="bg-white/50 border-cyan-200 focus:border-cyan-400 focus:ring-cyan-400" value={signupName} onChange={(e) => setSignupName(e.target.value)} />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="register-email" className="text-gray-700">Email</Label>
                    <Input
                      id="register-email"
                      type="email"
                      placeholder="Enter your email"
                      required
                      className="bg-white/50 border-cyan-200 focus:border-cyan-400 focus:ring-cyan-400"
                      value={signupEmail}
                      onChange={(e) => setSignupEmail(e.target.value)}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="register-password" className="text-gray-700">Password</Label>
                    <div className="relative">
                      <Input
                        id="register-password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Create a password"
                        required
                        className="bg-white/50 border-cyan-200 focus:border-cyan-400 focus:ring-cyan-400"
                        value={signupPassword}
                        onChange={(e) => setSignupPassword(e.target.value)}
                      />
                      <Button
                        type="button"
                        variant="ghost"
                        size="icon"
                        className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <EyeOffIcon className="h-4 w-4 text-gray-500" />
                        ) : (
                          <EyeIcon className="h-4 w-4 text-gray-500" />
                        )}
                        <span className="sr-only">Toggle password visibility</span>
                      </Button>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="confirm-password" className="text-gray-700">Confirm Password</Label>
                    <Input
                      id="confirm-password"
                      type="password"
                      placeholder="Confirm your password"
                      required
                      className="bg-white/50 border-cyan-200 focus:border-cyan-400 focus:ring-cyan-400"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  {registerError && <p className="text-sm text-red-500">{registerError}</p>}
                  <Button type="submit" className="w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white">Register</Button>
                </motion.form>
              </TabsContent>
            </AnimatePresence>
          </Tabs>
        </CardContent>
        <CardFooter className="flex justify-center">
          <p className="text-sm text-gray-600">
            By continuing, you agree to our{' '}
            <a href="#" className="text-blue-500 hover:text-blue-700 underline underline-offset-4">Terms of Service</a>
            {' '}and{' '}
            <a href="#" className="text-blue-500 hover:text-blue-700 underline underline-offset-4">Privacy Policy</a>.
          </p>
        </CardFooter>
      </Card>
      {isForgotPassword && (
        <div className="fixed inset-0 bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <Card className="w-full max-w-md bg-white/90 backdrop-blur-md shadow-xl relative overflow-hidden">
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-center">Forgot Password</CardTitle>
              <CardDescription className="text-center text-gray-600">Enter your email to receive a password reset link.</CardDescription>
              <Button
                variant="ghost"
                size="icon"
                className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
                onClick={() => setIsForgotPassword(false)}
              >
                <XIcon className="h-4 w-4" />
                <span className="sr-only">Close</span>
              </Button>
            </CardHeader>
            <CardContent>
              <form onSubmit={handleForgotPasswordSubmit} className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="forgot-password-email" className="text-gray-700">Email</Label>
                  <Input
                    id="forgot-password-email"
                    type="email"
                    placeholder="Enter your email"
                    required
                    className="bg-white/50 border-cyan-200 focus:border-cyan-400 focus:ring-cyan-400"
                    value={forgotPasswordEmail}
                    onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  />
                </div>
                {forgotPasswordError && <p className="text-sm text-red-500">{forgotPasswordError}</p>}
                <Button 
                    type="submit" 
                    disabled={isSendingReset}
                    className="w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white flex items-center justify-center"
                >
                    {isSendingReset ? (
                        <>
                            <Loader2 className="h-4 w-4 animate-spin mr-2" />
                            <span>Sending...</span>
                        </>
                    ) : (
                        buttonText
                    )}
                </Button>
              </form>
            </CardContent>
          </Card>
        </div>
      )}
      {/* {isResetPassword && (
        <div className="fixed inset-0 bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <Card className="w-full max-w-md bg-white/90 backdrop-blur-md shadow-xl relative overflow-hidden">
            <CardHeader>
              <CardTitle className="text-2xl font-bold text-center">Reset Password</CardTitle>
              <Button
                variant="ghost"
                size="icon"
                className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
                onClick={() => setIsResetPassword(false)}
              >
                <XIcon className="h-4 w-4" />
                <span className="sr-only">Close</span>
              </Button>
            </CardHeader>
            <CardContent>
              <ResetPasswordForm 
                token={resetToken} 
                onSuccess={() => {
                  setIsResetPassword(false);
                  alert('Password reset successfully! Please login with your new password.');
                }} 
              />
            </CardContent>
          </Card>
        </div>
      )} */}
    </div>
  )
}