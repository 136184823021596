import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Container, Grid, Paper, Button, LinearProgress, Typography, IconButton, CircularProgress, AppBar, Toolbar } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Webcam from 'react-webcam';
import * as tf from '@tensorflow/tfjs';
import * as posenet from '@tensorflow-models/posenet';
import * as knnClassifier from '@tensorflow-models/knn-classifier';
import html2canvas from 'html2canvas';
import { useNavigate, useLocation } from 'react-router-dom';
import mlImage from './image.png';

const PoseClassifier = ({ name, description, type }) => {
  const [classes, setClasses] = useState([
    { id: 1, name: 'Class 0', color: '#df4ef2', images: [] },
    { id: 2, name: 'Class 1', color: '#89f582', images: [] }
  ]);
  const [isPredicting, setIsPredicting] = useState(false);
  const [isTraining, setIsTraining] = useState(false);
  const [isModelTrained, setIsModelTrained] = useState(false);
  const [classifier, setClassifier] = useState(knnClassifier.create());
  const [activeWebcam, setActiveWebcam] = useState(null);
  const [net, setNet] = useState(null); // PoseNet model
  const [capturedPoses, setCapturedPoses] = useState([]);
  const [imageSrc, setImageSrc] = useState('');
  const [predictionResult, setPredictionResult] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // Loading state for webcam
  const location = useLocation();

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const loadPosenetModel = async () => {
      const posenetModel = await posenet.load();
      setNet(posenetModel);
    };
    loadPosenetModel(); // Load the PoseNet model on component mount
  }, []);

  // Toggle webcam for a specific class
  const toggleWebcamForClass = (classId) => {
    if (activeWebcam === classId) {
      setActiveWebcam(null);
    } else {
      setIsLoading(true); // Start loading
      setActiveWebcam(classId);

      // Simulate a short delay for camera to load
      setTimeout(() => {
        setIsLoading(false); // Stop loading once webcam feed is ready
      }, 1000); // Adjust this delay based on your webcam load time
    }
  };

  // Draw keypoints and skeleton
  const drawKeypointsAndSkeleton = (keypoints, ctx, scale = 1) => {
    keypoints.forEach(keypoint => {
      if (keypoint.score > 0.5) {  // Filter low-confidence keypoints
        const { y, x } = keypoint.position;
        ctx.beginPath();
        ctx.arc(x * scale, y * scale, 5, 0, 2 * Math.PI);
        ctx.fillStyle = 'aqua';
        ctx.fill();
      }
    });

    const adjacentKeyPoints = posenet.getAdjacentKeyPoints(keypoints, 0.5);
    adjacentKeyPoints.forEach(([keypoint1, keypoint2]) => {
      if (keypoint1.score > 0.5 && keypoint2.score > 0.5) {  // Filter low-confidence keypoints
        const { y: y1, x: x1 } = keypoint1.position;
        const { y: y2, x: x2 } = keypoint2.position;
        ctx.beginPath();
        ctx.moveTo(x1 * scale, y1 * scale);
        ctx.lineTo(x2 * scale, y2 * scale);
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'aqua';
        ctx.stroke();
      }
    });
  };
  const handleBackClick = () => {
    navigate('../'); // Navigate back to the previous page
  };

  // Capture and draw poses on the canvas
  useEffect(() => {
    const detectPose = async () => {
      if (webcamRef.current && net) {
        const video = webcamRef.current.video;
        if (video.readyState === 4) {
          const videoWidth = video.videoWidth;
          const videoHeight = video.videoHeight;

          // Set video and canvas dimensions
          webcamRef.current.video.width = 300; // Fixed width for webcam feed
          webcamRef.current.video.height = 300; // Fixed height for webcam feed

          const poseData = await net.estimateSinglePose(video, {
            flipHorizontal: false
          });

          const canvas = canvasRef.current;
          if (canvas) { // Check if canvasRef is not null
            const ctx = canvas.getContext('2d');

            // Match the canvas dimensions to the webcam feed
            canvas.width = 300; // Fixed width for canvas
            canvas.height = 300; // Fixed height for canvas

            // Clear the previous frame
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Draw the video frame onto the canvas
            ctx.drawImage(video, 0, 0, 300, 300); // Fixed dimensions

            // No need to scale the keypoints if canvas matches video size
            drawKeypointsAndSkeleton(poseData.keypoints, ctx, 1);

            // If capturing pose data
            if (capturedPoses.length > 0) {
              capturedPoses.forEach(pose => {
                classifier.addExample(pose.keypoints, pose.classId);
              });
            }
          }
        }
      }
      requestAnimationFrame(detectPose);
    };
    detectPose(); // Start pose detection loop
  }, [net, capturedPoses]);

  // Handle capturing the current pose
  const captureImage = useCallback(
    async (classId) => {
      if (canvasRef.current) {
        try {
          // Capture the canvas as a data URL asynchronously
          const canvas = canvasRef.current;
          const imageSrc = canvas.toDataURL('image/jpeg', 0.5); // Reduce quality to speed up processing

          if (imageSrc) {
            setClasses((prevClasses) =>
              prevClasses.map((cls) =>
                cls.id === classId ? { ...cls, images: [...cls.images, imageSrc] } : cls
              )
            );

            // Process the image with TensorFlow asynchronously
            const imgElement = new Image();
            imgElement.src = imageSrc;
            imgElement.onload = () => {
              tf.tidy(() => {
                const imgTensor = tf.browser.fromPixels(imgElement)
                  .resizeBilinear([224, 224]) // Smaller size for faster processing
                  .toFloat()
                  .expandDims(0); // Add batch dimension

                // Add the processed image to the classifier
                classifier.addExample(imgTensor, classId - 1);
                console.log(`Image added to class ${classId}`);
              });
            };
            imgElement.onerror = () => console.error('Error loading captured image');
          } else {
            console.error('Failed to capture image from canvas');
          }
        } catch (error) {
          console.error('Error during image capture:', error);
        }
      }
    },
    [canvasRef, classifier]
  );

  // Add image to KNN Classifier for training
  const addImageToClassifier = (imageSrc, classId) => {
    const imgElement = new Image();
    imgElement.src = imageSrc;
    imgElement.onload = () => {
      const imgTensor = tf.browser.fromPixels(imgElement)
        .resizeBilinear([224, 224]) // Resize to 224x224
        .toFloat()
        .expandDims(0); // Add batch dimension

      // Log the shape of the tensor
      console.log('Image tensor shape before adding to classifier:', imgTensor.shape);

      // Add to KNN classifier with the corresponding classId
      classifier.addExample(imgTensor, classId - 1); // Ensure classId matches the 0-indexed format
      console.log(`Added image to class: ${classId - 1}`);
    };

    // Handle image loading errors
    imgElement.onerror = () => {
      console.error('Error loading image for classification');
    };
  };
  const handleImageUpload = (event, classId) => {
    const imageSrc = URL.createObjectURL(event.target.files[0]);
    setClasses(prevClasses =>
      prevClasses.map(cls =>
        cls.id === classId ? { ...cls, images: [...cls.images, imageSrc] } : cls
      )
    );
    addImageToClassifier(imageSrc, classId);
  };
  // Handle model training
  const handleTraining = async () => {
    setIsTraining(true);

    classes.forEach(cls => {
      cls.images.forEach(imageSrc => {
        const imgElement = new Image();
        imgElement.src = imageSrc;
        imgElement.onload = () => {
          const imgTensor = tf.browser.fromPixels(imgElement)
            .resizeBilinear([224, 224])
            .toFloat()
            .expandDims(0);
          classifier.addExample(imgTensor, cls.id - 1);
        };
      });
    });

    await new Promise(resolve => setTimeout(resolve, 3000));
    setIsTraining(false);
    setIsModelTrained(true);
    alert('Training Complete!');
  };
  const addClass = () => {
    const newClassId = classes.length + 1;
    const colors = ['#34ebba', '#83f29d', '#b583f2', '#f2839d', '#d7fc5b'];
    setClasses([...classes, {
      id: newClassId,
      name: `class${newClassId}`,
      color: colors[newClassId % colors.length],
      images: []
    }]);
  };
  const predictFromCanvas = async () => {
    if (canvasRef.current && isPredicting && classifier?.getNumClasses() > 0) {
      tf.tidy(() => {
        const ctx = canvasRef.current.getContext('2d');
        const imgData = ctx.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
        const imgTensor = tf.browser.fromPixels(imgData)
          .resizeBilinear([224, 224])
          .toFloat()
          .expandDims(0);

        classifier.predictClass(imgTensor).then(prediction => {
          // Update state with prediction results
          setPredictionResult({
            classIndex: prediction.classIndex + 1,
            label: (parseInt(prediction.label) + 1).toString(),
            confidences: prediction.confidences,
          });
        });
      });
    }
  };

  const toggleWebcamForPrediction = () => {
    setIsPredicting(prev => !prev);
    setActiveWebcam(isPredicting ? null : 'prediction'); // Switch between prediction and class-specific webcams
    setPredictionResult('');  // Reset prediction result when toggling webcam
  };

  // Set up interval for prediction when webcam is active
  useEffect(() => {
    let interval;
    if (isPredicting) {
      interval = setInterval(predictFromCanvas, 1000); // 1-second interval
    }
    return () => clearInterval(interval);
  }, [isPredicting, classifier, classes]);

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) {
      console.error("Canvas context is not available");
    }
  }, []);
  return (
    <div className="min-h-screen bg-gray-50 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${mlImage})` }}>
      <div className="container mx-auto py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Training Section */}
          <div className="lg:col-span-2">
            <div className="p-6 mb-6 bg-transparent">
              <div className="flex justify-between items-center mb-6">
                <Typography variant="h5" className="font-medium">
                  Training
                </Typography>
                <Button
                  onClick={addClass}
                  className="bg-blue-500 text-white rounded-full px-5 py-2.5 flex items-center gap-2 hover:bg-blue-600 transition-colors"
                >
                  <AddIcon className="w-5 h-5" />
                  Add New Class
                </Button>
              </div>

              <div className="flex flex-col items-center space-y-6">
                {classes.map((cls) => (
                  <div key={cls.id} className="w-[400px] overflow-hidden bg-transparent">
                    <div
                      className="p-4 rounded-lg"
                      style={{ backgroundColor: cls.color }}
                    >
                      <div className="flex justify-between items-center">
                        <Typography className="text-white font-medium">
                          {cls.name}
                        </Typography>
                        <div className="flex gap-2">
                          <label className="bg-white hover:bg-gray-100 text-inherit rounded-full px-5 py-2.5 flex items-center gap-2 cursor-pointer transition-colors"
                            style={{ color: cls.color }}>
                            <UploadIcon className="w-5 h-5" />
                            Upload
                            <input
                              type="file"
                              className="hidden"
                              accept="image/*"
                              onChange={(e) => handleImageUpload(e, cls.id)}
                            />
                          </label>
                          <button
                            className="bg-white hover:bg-gray-100 rounded-full px-5 py-2.5 flex items-center gap-2 transition-colors"
                            style={{ color: cls.color }}
                            onClick={() => toggleWebcamForClass(cls.id)}
                          >
                            <CameraAltIcon className="w-5 h-5" />
                            {activeWebcam === cls.id ? 'Stop' : 'Start'}
                          </button>
                        </div>
                      </div>

                      {/* Webcam Section */}
                      {activeWebcam === cls.id && (
                        <div className="mt-4 flex flex-col items-center">
                          <div className="relative w-[300px] h-[300px]">
                            {isLoading ? (
                              <div className="flex flex-col items-center justify-center w-full h-full">
                                <CircularProgress />
                                <Typography className="mt-2">
                                  Opening Camera...
                                </Typography>
                              </div>
                            ) : (
                              <>
                                <Webcam
                                  audio={false}
                                  ref={webcamRef}
                                  screenshotFormat="image/jpeg"
                                  className="absolute top-0 left-0 w-[300px] h-[300px]"
                                  videoConstraints={{
                                    width: 300,
                                    height: 300,
                                    facingMode: "user",
                                  }}
                                />
                                <canvas
                                  ref={canvasRef}
                                  className="absolute top-0 left-0 w-[300px] h-[300px]"
                                />
                              </>
                            )}
                          </div>

                          <button
                            className="mt-4 bg-white hover:bg-gray-100 rounded-full px-5 py-2.5 transition-colors"
                            style={{ color: cls.color }}
                            onClick={() => captureImage(cls.id)}
                          >
                            Capture
                          </button>
                        </div>
                      )}

                      {/* Image Gallery */}
                      {cls.images.length > 0 && (
                        <div className="mt-4 flex flex-wrap gap-2 justify-center">
                          {cls.images.map((image, index) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Sample ${index + 1}`}
                              className="w-[50px] h-[50px] object-cover rounded"
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Preview Section */}
          <div className="lg:col-span-1">
            <div className="p-6 sticky top-6 bg-white rounded-lg shadow">
              <h5 className="font-medium text-xl mb-6">
                Preview
              </h5>

              <div className="space-y-4">
                <button
                  className={`w-full h-12 rounded-full px-5 py-2.5 transition-colors
                    ${isTraining || isModelTrained
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-blue-500 hover:bg-blue-600 text-white'}`}
                  onClick={handleTraining}
                  disabled={isTraining || isModelTrained}
                >
                  {isTraining ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Train Model'
                  )}
                </button>

                <button
                  className="w-full h-12 border border-blue-500 text-blue-500 hover:bg-blue-50 rounded-full px-5 py-2.5 transition-colors"
                  onClick={toggleWebcamForPrediction}
                >
                  {isPredicting ? 'Stop Preview' : 'Start Preview'}
                </button>

                {isPredicting && (
                  <div className="mt-6">
                    <div className="flex justify-center">
                      <div className="relative w-[300px] h-[300px]">
                        <Webcam
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          className="absolute top-0 left-0 w-[300px] h-[300px]"
                          videoConstraints={{
                            width: 300,
                            height: 300,
                            facingMode: "user",
                          }}
                        />
                        <canvas
                          ref={canvasRef}
                          className="absolute top-0 left-0 w-[300px] h-[300px]"
                        />
                      </div>
                    </div>

                    <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                      <h6 className="font-medium text-lg">
                        Prediction Results
                      </h6>
                      <div className="mt-2 space-y-4">
                        {predictionResult.confidences &&
                          Object.entries(predictionResult.confidences).map(([key, value]) => (
                            <div key={key} className="space-y-1">
                              <p className="text-gray-600 text-sm">
                                Class: {key}
                              </p>
                              <div className="w-full bg-gray-200 rounded-full h-2.5">
                                <div
                                  className="bg-blue-500 h-2.5 rounded-full transition-all duration-300"
                                  style={{ width: `${value * 100}%` }}
                                />
                              </div>
                              <p className="text-gray-600 text-sm">
                                Confidence: {(value * 100).toFixed(1)}%
                              </p>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoseClassifier;
