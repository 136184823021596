const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const Video = require('../../io/video');
const tf = require('@tensorflow/tfjs');
const handpose = require('@tensorflow-models/handpose');
const StageLayering = require('../../engine/stage-layering');
class Scratch3HandposeDetectionExtension {
    constructor(runtime) {
        this.runtime = runtime;
        this.video = this.runtime.ioDevices.video;
        this.model = null;
        this.landmarks = [];
        this.loadingModel = false;
        this.detectionInProgress = false;
        this.isDetectionVisible = true;
        this.handX = 0;
        this.handY = 0;
        this.handWidth = 0;
        this.handHeight = 0;
        this._initialize();
        this._initialize();
        this.penSkinId = -1;
        this.penDrawableId = -1;
        this._initializePenLayer();
    }

    async _initialize() {
        this.loadingModel = true;
        console.log('Loading hand pose model...');
        this.model = await handpose.load();
        this.loadingModel = false;
        console.log('Model loaded successfully!');
    }


    getInfo() {
        return {
            id: 'handPoseDetection',
            color1: '#FF5733',
            name: formatMessage({
                id: 'handPoseDetection.categoryName',
                default: 'Hand Pose Detection',
                description: 'Label for the hand pose detection extension category'
            }),
            blocks: [
                {
                    opcode: 'videoToggle',
                    text: formatMessage({
                        id: 'handPoseDetection.videoToggle',
                        default: 'turn video [VIDEO_STATE]',
                        description: 'Controls display of the video preview layer'
                    }),
                    blockType: BlockType.COMMAND,
                    arguments: {
                        VIDEO_STATE: {
                            type: ArgumentType.STRING,
                            menu: 'VIDEO_STATE',
                            defaultValue: 'off'
                        }
                    }
                },
                {
                    opcode: 'detectHandPose',
                    text: formatMessage({
                        id: 'handPoseDetection.detectHandPose',
                        default: 'detect hand pose',
                        description: 'Detects hand pose and shows landmarks on the screen'
                    }),
                    blockType: BlockType.COMMAND
                },


                {
                    opcode: 'moveSpriteWithHand',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'handPoseDetection.moveSpriteWithHand',
                        default: 'go to [FINGER] [PART]',
                        description: 'Move sprite according to hand movement'
                    }),
                    arguments: {
                        FINGER: {
                            type: ArgumentType.STRING,
                            menu: 'fingers',
                            defaultValue: 'thumb'
                        },
                        PART: {
                            type: ArgumentType.STRING,
                            menu: 'parts',
                            defaultValue: 'tip'
                        }
                    }
                },


                {
                    opcode: 'isHandDetected',
                    blockType: BlockType.BOOLEAN,
                    text: formatMessage({
                        id: 'handPoseDetection.isHandDetected',
                        default: 'is hand detected',
                        description: 'Boolean reporter for hand detection'
                    })
                },
                {
                    opcode: 'setDetectionVisibility',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'handPoseDetection.setDetectionVisibility',
                        default: '[VISIBILITY_STATE] detections',
                        description: 'Command to show or hide hand detections'
                    }),
                    arguments: {
                        VISIBILITY_STATE: {
                            type: ArgumentType.STRING,
                            menu: 'visibilityStates',
                            defaultValue: 'show'
                        }
                    }
                },
                {
                    opcode: 'clearCanvas',
                    text: formatMessage({
                        id: 'handPoseDetection.clearCanvas',
                        default: 'clear canvas',
                        description: 'Clears the drawing on the canvas'
                    }),
                    blockType: BlockType.COMMAND,
                    arguments: {}
                }


            ],
            menus: {
                VIDEO_STATE: {
                    acceptReporters: true,
                    items: [
                        { text: 'on', value: 'on' },
                        { text: 'off', value: 'off' }
                    ]
                },
                handProperties: {
                    acceptReporters: false,
                    items: ['x position', 'y position', 'width', 'height']
                },
                coordinates: {
                    acceptReporters: false,
                    items: ['x', 'y']
                },
                // 
                fingers: {
                    acceptReporters: false,
                    items: ['thumb', 'index', 'middle', 'ring', 'pinky']
                },
                parts: {
                    acceptReporters: false,
                    items: ['tip', 'middle', 'base']
                },
                visibilityStates: {
                    acceptReporters: false,
                    items: ['show', 'hide']
                },


            }

        };
    }

    videoToggle(args) {
        const state = args.VIDEO_STATE;

        // Retrieve the camera state from localStorage
        let isCameraOpen;
        try {
            isCameraOpen = JSON.parse(localStorage.getItem('isCameraOpen'));
        } catch (error) {
            console.warn("Error accessing localStorage for camera state:", error);
            isCameraOpen = false; // Default to false if there's an error
        }

        if (state === 'off') {
            if (!isCameraOpen) {
                alert('Camera is already off.');
            } else {
                this.runtime.ioDevices.video.disableVideo();
                localStorage.setItem('isCameraOpen', JSON.stringify(false));
            }
        } else {
            if (isCameraOpen) {
                alert('Camera is already on.');
            } else {
                this.runtime.ioDevices.video.enableVideo().then(() => {
                    this.runtime.ioDevices.video.mirror = true;
                    localStorage.setItem('isCameraOpen', JSON.stringify(true));
                }).catch(err => {
                    console.error("Error enabling video: ", err);
                });
            }
        }
    }

    _initializePenLayer() {
        if (this.penSkinId < 0 && this.runtime.renderer) {
            this.penSkinId = this.runtime.renderer.createPenSkin();
            this.penDrawableId = this.runtime.renderer.createDrawable(StageLayering.PEN_LAYER);
            this.runtime.renderer.updateDrawableSkinId(this.penDrawableId, this.penSkinId);
            console.log('Pen layer initialized:', {
                penSkinId: this.penSkinId,
                penDrawableId: this.penDrawableId
            });
        }
    }

    async detectHandPose() {
        if (!this.detectionInProgress && this.model) {
            this.detectionInProgress = true;
            console.log('Detecting hand pose...');

            const videoElement = this.video.provider.video;
            if (videoElement) {
                const predictions = await this.model.estimateHands(videoElement);

                if (predictions.length > 0) {
                    this.landmarks = predictions[0].landmarks;

                    const xs = this.landmarks.map(l => l[0]);
                    const ys = this.landmarks.map(l => l[1]);
                    const minX = Math.min(...xs);
                    const maxX = Math.max(...xs);
                    const minY = Math.min(...ys);
                    const maxY = Math.max(...ys);

                    // Update hand properties
                    this.handX = (minX + maxX) / 2;
                    this.handY = (minY + maxY) / 2;
                    this.handWidth = maxX - minX;
                    this.handHeight = maxY - minY;

                    console.log('Hand pose detected!');
                    if (this.isDetectionVisible) {
                        this._drawLandmarks();
                    }
                } else {
                    console.log('No hand detected.');
                    this.handX = 0;
                    this.handY = 0;
                    this.handWidth = 0;
                    this.handHeight = 0;
                    this._clearPenLayer();
                }
            } else {
                console.error('Video element not found.');
            }

            this.detectionInProgress = false;
        } else {
            console.error('Model is not loaded or detection is already in progress.');
        }
    }

    _drawLandmarks() {
         this._clearPenLayer();
        if (this.penSkinId < 0) {
            console.warn('Pen layer is not initialized properly.');
            return;
        }
        // Get the video element
        const videoElement = this.video.provider.video;

        // Fixed dimensions for Scratch stage
        const stageWidth = 480;
        const stageHeight = 360;

        // Calculate scaling factors
        const scaleX = stageWidth / videoElement.videoWidth;
        const scaleY = stageHeight / videoElement.videoHeight;

        const penAttributes = {
            color4f: [0, 1, 0, 1], // Lime green color [R, G, B, A]
            diameter: 2
        };

        const dotAttributes = {
            color4f: [0, 1, 0, 1], // Lime green color
            diameter: 8
        };

        // Define finger connections
        const fingerPairs = [
            [0, 1], [1, 2], [2, 3], [3, 4], // Thumb
            [0, 5], [5, 6], [6, 7], [7, 8], // Index finger
            [0, 9], [9, 10], [10, 11], [11, 12], // Middle finger
            [0, 13], [13, 14], [14, 15], [15, 16], // Ring finger
            [0, 17], [17, 18], [18, 19], [19, 20] // Pinky
        ];

        // Draw connections
        fingerPairs.forEach(pair => {
            const [i, j] = pair;
            const [x1, y1] = this.landmarks[i];
            const [x2, y2] = this.landmarks[j];

            // Scale coordinates, flip horizontally by reversing x
            const scratchX1 = stageWidth - (x1 * scaleX);
            const scratchY1 = y1 * scaleY;
            const scratchX2 = stageWidth - (x2 * scaleX);
            const scratchY2 = y2 * scaleY;

            // Convert to Scratch coordinate system (origin at center)
            const stageX1 = scratchX1 - (stageWidth / 2);
            const stageY1 = (stageHeight / 2) - scratchY1;
            const stageX2 = scratchX2 - (stageWidth / 2);
            const stageY2 = (stageHeight / 2) - scratchY2;

            // Draw line
            this.runtime.renderer.penLine(
                this.penSkinId,
                penAttributes,
                stageX1,
                stageY1,
                stageX2,
                stageY2
            );
        });

        // Draw landmark points
        this.landmarks.forEach(([x, y]) => {
            // Scale coordinates and flip horizontally by reversing x
            const scratchX = stageWidth - (x * scaleX);
            const scratchY = y * scaleY;

            // Convert to Scratch coordinate system
            const stageX = scratchX - (stageWidth / 2);
            const stageY = (stageHeight / 2) - scratchY;

            // Draw circle using small line segments
            const segments = 8;
            const radius = 2;
            for (let i = 0; i < segments; i++) {
                const angle1 = (i / segments) * 2 * Math.PI;
                const angle2 = ((i + 1) / segments) * 2 * Math.PI;

                const x1 = stageX + Math.cos(angle1) * radius;
                const y1 = stageY + Math.sin(angle1) * radius;
                const x2 = stageX + Math.cos(angle2) * radius;
                const y2 = stageY + Math.sin(angle2) * radius;

                this.runtime.renderer.penLine(
                    this.penSkinId,
                    dotAttributes,
                    x1,
                    y1,
                    x2,
                    y2
                );
            }
        });

        this.runtime.requestRedraw();
    }

    async moveSpriteWithHand(args) {
        const finger = args.FINGER;
        const part = args.PART;
        await this.detectHandPose()

        if (!this.landmarks || this.landmarks.length === 0) {
            console.error('No hand detected.');
            return;
        } else {
            console.log('Hand landmarks:', this.landmarks);
        }

        // Determine which landmark index to use based on finger and part
        let landmarkIndex;
        switch (finger) {
            case 'thumb':
                landmarkIndex = part === 'tip' ? 4 : part === 'middle' ? 3 : part === 'base' ? 2 : 1;
                break;
            case 'index':
                landmarkIndex = part === 'tip' ? 8 : part === 'middle' ? 7 : part === 'base' ? 6 : 5;
                break;
            case 'middle':
                landmarkIndex = part === 'tip' ? 12 : part === 'middle' ? 11 : part === 'base' ? 10 : 9;
                break;
            case 'ring':
                landmarkIndex = part === 'tip' ? 16 : part === 'middle' ? 15 : part === 'base' ? 14 : 13;
                break;
            case 'pinky':
                landmarkIndex = part === 'tip' ? 20 : part === 'middle' ? 19 : part === 'base' ? 18 : 17;
                break;
            default:
                console.error('Invalid finger or part.');
                return;
        }

        const [x, y] = this.landmarks[landmarkIndex];

        // console.log(`Selected finger: ${finger}, part: ${part}, landmark index: ${landmarkIndex}`);
        // console.log(`Hand coordinates (video): x = ${x}, y = ${y}`);

        // Convert the hand position to Scratch stage coordinates
        const videoWidth = this.video.provider.video.videoWidth;
        const videoHeight = this.video.provider.video.videoHeight;
        const stageWidth = this.runtime.constructor.STAGE_WIDTH;
        const stageHeight = this.runtime.constructor.STAGE_HEIGHT;
        // console.log(videoWidth, videoHeight, stageWidth, stageHeight)

        const spriteX = -1 * ((x / videoWidth - 0.5) * stageWidth);
        const spriteY = (0.5 - y / videoHeight) * stageHeight;

        console.log(`Hand coordinates (Scratch): spriteX = ${spriteX}, spriteY = ${spriteY}`);

        // Move the sprite to the new position
        const sprite = this.runtime.getEditingTarget();
        console.log('Sprite being moved:', sprite.getName());
        sprite.setXY(spriteX, spriteY);


    }
    _clearPenLayer() {
        if (this.penSkinId >= 0) {
            this.runtime.renderer.penClear(this.penSkinId);
            this.runtime.requestRedraw();
        }
    }
    isHandDetected() {
        return this.landmarks && this.landmarks.length > 0;
    }
    setDetectionVisibility(args) {
        const visibilityState = args.VISIBILITY_STATE;
        this.isDetectionVisible = (visibilityState === 'show');

        if (!this.isDetectionVisible) {
            this._clearPenLayer();
        } else if (this.landmarks && this.landmarks.length > 0) {
            this._drawLandmarks();
        }
    }
    clearCanvas() {
        this._clearPenLayer();
    }

}

module.exports = Scratch3HandposeDetectionExtension;