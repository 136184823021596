// import bindAll from 'lodash.bindall';
// import React from 'react';
// import PropTypes from 'prop-types';
// import {defineMessages, intlShape, injectIntl} from 'react-intl';
// import {connect} from 'react-redux';
// import log from '../lib/log';
// import sharedMessages from './shared-messages';

// import {
//     LoadingStates,
//     getIsLoadingUpload,
//     getIsShowingWithoutId,
//     onLoadedProject,
//     requestProjectUpload
// } from '../reducers/project-state';
// import {setProjectTitle} from '../reducers/project-title';
// import {
//     openLoadingProject,
//     closeLoadingProject
// } from '../reducers/modals';
// import {
//     closeFileMenu
// } from '../reducers/menus';

// const messages = defineMessages({
//     loadError: {
//         id: 'gui.projectLoader.loadError',
//         defaultMessage: 'The project file that was selected failed to load.',
//         description: 'An error that displays when a local project file fails to load.'
//     }
// });

// /**
//  * Higher Order Component to provide behavior for loading local project files into editor.
//  * @param {React.Component} WrappedComponent the component to add project file loading functionality to
//  * @returns {React.Component} WrappedComponent with project file loading functionality added
//  *
//  * <SBFileUploaderHOC>
//  *     <WrappedComponent />
//  * </SBFileUploaderHOC>
//  */
// const SBFileUploaderHOC = function (WrappedComponent) {
//     class SBFileUploaderComponent extends React.Component {
//         constructor(props) {
//             super(props);
//             bindAll(this, [
//                 'createFileObjects',
//                 'getProjectTitleFromFilename',
//                 'handleFinishedLoadingUpload',
//                 'handleStartSelectingFileUpload',
//                 'handleChange',
//                 'onload',
//                 'removeFileObjects',
//                 'openSpecificSB3File' // New method for opening specific SB3 file
//             ]);
//         }

//         componentDidUpdate(prevProps) {
//             if (this.props.isLoadingUpload && !prevProps.isLoadingUpload) {
//                 this.handleFinishedLoadingUpload(); // cue step 5 below
//             }
//         }

//         componentWillUnmount() {
//             this.removeFileObjects();
//         }

//         // step 1: this is where the upload process begins
//         handleStartSelectingFileUpload() {
//             this.createFileObjects(); // go to step 2
//         }

//         // step 2: create a FileReader and an <input> element, and issue a
//         // pseudo-click to it. That will open the file chooser dialog.
//         createFileObjects() {
//             // redo step 7, in case it got skipped last time and its objects are
//             // still in memory
//             this.removeFileObjects();
//             // create fileReader
//             this.fileReader = new FileReader();
//             this.fileReader.onload = this.onload;
//             // create <input> element and add it to DOM
//             this.inputElement = document.createElement('input');
//             this.inputElement.accept = '.sb,.sb2,.sb3';
//             this.inputElement.style = 'display: none;';
//             this.inputElement.type = 'file';
//             this.inputElement.onchange = this.handleChange; // connects to step 3
//             document.body.appendChild(this.inputElement);
//             // simulate a click to open file chooser dialog
//             this.inputElement.click();
//         }

//         // step 3: user has picked a file using the file chooser dialog.
//         // We don't actually load the file here, we only decide whether to do so.
//         handleChange(e) {
//             const {
//                 intl,
//                 isShowingWithoutId,
//                 loadingState,
//                 projectChanged,
//                 userOwnsProject
//             } = this.props;
//             const thisFileInput = e.target;
//             if (thisFileInput.files) { // Don't attempt to load if no file was selected
//                 this.fileToUpload = thisFileInput.files[0];

//                 // If user owns the project, or user has changed the project,
//                 // we must confirm with the user that they really intend to
//                 // replace it. (If they don't own the project and haven't
//                 // changed it, no need to confirm.)
//                 let uploadAllowed = true;
//                 if (userOwnsProject || (projectChanged && isShowingWithoutId)) {
//                     uploadAllowed = confirm( // eslint-disable-line no-alert
//                         intl.formatMessage(sharedMessages.replaceProjectWarning)
//                     );
//                 }
//                 if (uploadAllowed) {
//                     // cues step 4
//                     this.props.requestProjectUpload(loadingState);
//                 } else {
//                     // skips ahead to step 7
//                     this.removeFileObjects();
//                 }
//                 this.props.closeFileMenu();
//             }
//         }

//         // step 4 is below, in mapDispatchToProps

//         // step 5: called from componentDidUpdate when project state shows
//         // that project data has finished "uploading" into the browser
//         handleFinishedLoadingUpload() {
//             if (this.fileToUpload && this.fileReader) {
//                 // begin to read data from the file. When finished,
//                 // cues step 6 using the reader's onload callback
//                 this.fileReader.readAsArrayBuffer(this.fileToUpload);
//             } else {
//                 this.props.cancelFileUpload(this.props.loadingState);
//                 // skip ahead to step 7
//                 this.removeFileObjects();
//             }
//         }

//         // used in step 6 below
//         getProjectTitleFromFilename(fileInputFilename) {
//             if (!fileInputFilename) return '';
//             // only parse title with valid scratch project extensions
//             // (.sb, .sb2, and .sb3)
//             const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
//             if (!matches) return '';
//             return matches[1].substring(0, 100); // truncate project title to max 100 chars
//         }

//         // step 6: attached as a handler on our FileReader object; called when
//         // file upload raw data is available in the reader
//         onload() {
//             if (this.fileReader) {
//                 this.props.onLoadingStarted();
//                 const filename = this.fileToUpload && this.fileToUpload.name;
//                 let loadingSuccess = false;
//                 this.props.vm.loadProject(this.fileReader.result)
//                     .then(() => {
//                         if (filename) {
//                             const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
//                             this.props.onSetProjectTitle(uploadedProjectTitle);
//                         }
//                         loadingSuccess = true;
//                     })
//                     .catch(error => {
//                         log.warn(error);
//                         alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
//                     })
//                     .then(() => {
//                         this.props.onLoadingFinished(this.props.loadingState, loadingSuccess);
//                         // go back to step 7: whether project loading succeeded
//                         // or failed, reset file objects
//                         this.removeFileObjects();
//                     });
//             }
//         }

//         // step 7: remove the <input> element from the DOM and clear reader and
//         // fileToUpload reference, so those objects can be garbage collected
//         removeFileObjects() {
//             if (this.inputElement) {
//                 this.inputElement.value = null;
//                 document.body.removeChild(this.inputElement);
//             }
//             this.inputElement = null;
//             this.fileReader = null;
//             this.fileToUpload = null;
//         }

//         // New method to open a specific SB3 file from a local path
//         openSpecificSB3File = (filePath) => {
//             console.log(`Attempting to open SB3 file from path: ${filePath}`);
//             fetch(filePath)
//                 .then(response => {
//                     if (!response.ok) {
//                         throw new Error(`Network response was not ok: ${response.statusText}`);
//                     }
//                     return response.arrayBuffer();
//                 })
//                 .then(arrayBuffer => {
//                     console.log('Successfully fetched the SB3 file, loading project...');
//                     if (this.props.vm) {
//                         this.props.onLoadingStarted();
//                         this.props.vm.loadProject(arrayBuffer)
//                             .then(() => {
//                                 console.log('Project loaded successfully.');
//                                 const filename = filePath.split('/').pop();
//                                 const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
//                                 this.props.onSetProjectTitle(uploadedProjectTitle);
//                                 this.props.onLoadingFinished(this.props.loadingState, true);
//                             })
//                             .catch(error => {
//                                 console.error('Error loading project:', error);
//                                 alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
//                                 this.props.onLoadingFinished(this.props.loadingState, false);
//                             });
//                     } else {
//                         console.error('VM instance is not available.');
//                     }
//                 })
//                 .catch(error => {
//                     console.error('Error fetching SB3 file:', error);
//                     alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
//                     this.props.onLoadingFinished(this.props.loadingState, false);
//                 });
//         }
        

//         render() {
//             const {
//                 /* eslint-disable no-unused-vars */
//                 cancelFileUpload,
//                 closeFileMenu: closeFileMenuProp,
//                 isLoadingUpload,
//                 isShowingWithoutId,
//                 loadingState,
//                 onLoadingFinished,
//                 onLoadingStarted,
//                 onSetProjectTitle,
//                 projectChanged,
//                 requestProjectUpload: requestProjectUploadProp,
//                 userOwnsProject,
//                 /* eslint-enable no-unused-vars */
//                 ...componentProps
//             } = this.props;
//             return (
//                 <React.Fragment>
//                     <WrappedComponent
//                         onStartSelectingFileUpload={this.handleStartSelectingFileUpload}
//                         onOpenSpecificSB3File1={() => this.openSpecificSB3File('./asset/river.sb3')}
//                         {...componentProps}
//                     />
//                 </React.Fragment>
//             );
//         }
//     }

//     SBFileUploaderComponent.propTypes = {
//         canSave: PropTypes.bool,
//         cancelFileUpload: PropTypes.func,
//         closeFileMenu: PropTypes.func,
//         intl: intlShape.isRequired,
//         isLoadingUpload: PropTypes.bool,
//         isShowingWithoutId: PropTypes.bool,
//         loadingState: PropTypes.oneOf(LoadingStates),
//         onLoadingFinished: PropTypes.func,
//         onLoadingStarted: PropTypes.func,
//         onSetProjectTitle: PropTypes.func,
//         projectChanged: PropTypes.bool,
//         requestProjectUpload: PropTypes.func,
//         userOwnsProject: PropTypes.bool,
//         vm: PropTypes.shape({
//             loadProject: PropTypes.func
//         })
//     };

//     const mapStateToProps = (state, ownProps) => {
//         const loadingState = state.scratchGui.projectState.loadingState;
//         const user = state.session && state.session.session && state.session.session.user;
//         return {
//             isLoadingUpload: getIsLoadingUpload(loadingState),
//             isShowingWithoutId: getIsShowingWithoutId(loadingState),
//             loadingState: loadingState,
//             projectChanged: state.scratchGui.projectChanged,
//             userOwnsProject: ownProps.authorUsername && user &&
//                 (ownProps.authorUsername === user.username),
//             vm: state.scratchGui.vm
//         };
//     };

//     const mapDispatchToProps = (dispatch, ownProps) => ({
//         cancelFileUpload: loadingState => dispatch(onLoadedProject(loadingState, false, false)),
//         closeFileMenu: () => dispatch(closeFileMenu()),
//         // transition project state from loading to regular, and close
//         // loading screen and file menu
//         onLoadingFinished: (loadingState, success) => {
//             dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
//             dispatch(closeLoadingProject());
//             dispatch(closeFileMenu());
//         },
//         // show project loading screen
//         onLoadingStarted: () => dispatch(openLoadingProject()),
//         onSetProjectTitle: title => dispatch(setProjectTitle(title)),
//         // step 4: transition the project state so we're ready to handle the new
//         // project data. When this is done, the project state transition will be
//         // noticed by componentDidUpdate()
//         requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState))
//     });

//     // Allow incoming props to override redux-provided props. Used to mock in tests.
//     const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
//         {}, stateProps, dispatchProps, ownProps
//     );

//     return injectIntl(connect(
//         mapStateToProps,
//         mapDispatchToProps,
//         mergeProps
//     )(SBFileUploaderComponent));
// };

// export {
//     SBFileUploaderHOC as default
// };


// import bindAll from 'lodash.bindall';
// import React from 'react';
// import PropTypes from 'prop-types';
// import {defineMessages, intlShape, injectIntl} from 'react-intl';
// import {connect} from 'react-redux';
// import log from '../lib/log';
// import sharedMessages from './shared-messages';

// import {
//     LoadingStates,
//     getIsLoadingUpload,
//     getIsShowingWithoutId,
//     onLoadedProject,
//     requestProjectUpload
// } from '../reducers/project-state';
// import {setProjectTitle} from '../reducers/project-title';
// import {
//     openLoadingProject,
//     closeLoadingProject
// } from '../reducers/modals';
// import {
//     closeFileMenu
// } from '../reducers/menus';

// const messages = defineMessages({
//     loadError: {
//         id: 'gui.projectLoader.loadError',
//         defaultMessage: 'The project file that was selected failed to load.',
//         description: 'An error that displays when a local project file fails to load.'
//     }
// });

// /**
//  * Higher Order Component to provide behavior for loading local project files into editor.
//  * @param {React.Component} WrappedComponent the component to add project file loading functionality to
//  * @returns {React.Component} WrappedComponent with project file loading functionality added
//  *
//  * <SBFileUploaderHOC>
//  *     <WrappedComponent />
//  * </SBFileUploaderHOC>
//  */
// const SBFileUploaderHOC = function (WrappedComponent) {
//     class SBFileUploaderComponent extends React.Component {
//         constructor(props) {
//             super(props);
//             bindAll(this, [
//                 'createFileObjects',
//                 'getProjectTitleFromFilename',
//                 'handleFinishedLoadingUpload',
//                 'handleStartSelectingFileUpload',
//                 'handleChange',
//                 'onload',
//                 'removeFileObjects',
//                 'openSpecificSB3File' // Method to open specific SB3 file directly
//             ]);
//         }

//         componentDidUpdate(prevProps) {
//             if (this.props.isLoadingUpload && !prevProps.isLoadingUpload) {
//                 this.handleFinishedLoadingUpload(); // cue step 5 below
//             }
//         }

//         componentWillUnmount() {
//             this.removeFileObjects();
//         }

//         // Skip file explorer, directly open the SB3 file from path
//         handleStartSelectingFileUpload() {
//             this.openSpecificSB3File('./river.sb3'); // Directly use the file path
//         }

//         // step 2: create a FileReader and an <input> element, and issue a
//         // pseudo-click to it. That will open the file chooser dialog.
//         createFileObjects() {
//             // redo step 7, in case it got skipped last time and its objects are
//             // still in memory
//             this.removeFileObjects();
//             // create fileReader
//             this.fileReader = new FileReader();
//             this.fileReader.onload = this.onload;
//             // create <input> element and add it to DOM
//             this.inputElement = document.createElement('input');
//             this.inputElement.accept = '.sb,.sb2,.sb3';
//             this.inputElement.style = 'display: none;';
//             this.inputElement.type = 'file';
//             this.inputElement.onchange = this.handleChange; // connects to step 3
//             document.body.appendChild(this.inputElement);
//             // simulate a click to open file chooser dialog
//             this.inputElement.click();
//         }

//         // New method to open a specific SB3 file from a local path
//         openSpecificSB3File = (filePath) => {
//             console.log(`Attempting to open SB3 file from path: ${filePath}`);
//             fetch(filePath)
//                 .then(response => {
//                     if (!response.ok) {
//                         throw new Error(`Network response was not ok: ${response.statusText}`);
//                     }
//                     return response.arrayBuffer();
//                 })
//                 .then(arrayBuffer => {
//                     console.log('Successfully fetched the SB3 file, loading project...');
//                     if (this.props.vm) {
//                         this.props.onLoadingStarted();
//                         this.props.vm.loadProject(arrayBuffer)
//                             .then(() => {
//                                 console.log('Project loaded successfully.');
//                                 const filename = filePath.split('/').pop();
//                                 const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
//                                 this.props.onSetProjectTitle(uploadedProjectTitle);
//                                 this.props.onLoadingFinished(this.props.loadingState, true);
//                             })
//                             .catch(error => {
//                                 console.error('Error loading project:', error);
//                                 alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
//                                 this.props.onLoadingFinished(this.props.loadingState, false);
//                             });
//                     } else {
//                         console.error('VM instance is not available.');
//                     }
//                 })
//                 .catch(error => {
//                     console.error('Error fetching SB3 file:', error);
//                     alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
//                     this.props.onLoadingFinished(this.props.loadingState, false);
//                 });
//         }

//         // Rest of the unchanged methods

//         render() {
//             const {
//                 /* eslint-disable no-unused-vars */
//                 cancelFileUpload,
//                 closeFileMenu: closeFileMenuProp,
//                 isLoadingUpload,
//                 isShowingWithoutId,
//                 loadingState,
//                 onLoadingFinished,
//                 onLoadingStarted,
//                 onSetProjectTitle,
//                 projectChanged,
//                 requestProjectUpload: requestProjectUploadProp,
//                 userOwnsProject,
//                 /* eslint-enable no-unused-vars */
//                 ...componentProps
//             } = this.props;
//             return (
//                 <React.Fragment>
//                     <WrappedComponent
//                         onStartSelectingFileUpload={this.handleStartSelectingFileUpload}
//                         onOpenSpecificSB3File={() => this.openSpecificSB3File('./river.sb3')}
//                         {...componentProps}
//                     />
//                 </React.Fragment>
//             );
//         }
//     }

//     SBFileUploaderComponent.propTypes = {
//         canSave: PropTypes.bool,
//         cancelFileUpload: PropTypes.func,
//         closeFileMenu: PropTypes.func,
//         intl: intlShape.isRequired,
//         isLoadingUpload: PropTypes.bool,
//         isShowingWithoutId: PropTypes.bool,
//         loadingState: PropTypes.oneOf(LoadingStates),
//         onLoadingFinished: PropTypes.func,
//         onLoadingStarted: PropTypes.func,
//         onSetProjectTitle: PropTypes.func,
//         projectChanged: PropTypes.bool,
//         requestProjectUpload: PropTypes.func,
//         userOwnsProject: PropTypes.bool,
//         vm: PropTypes.shape({
//             loadProject: PropTypes.func
//         })
//     };

//     const mapStateToProps = (state, ownProps) => {
//         const loadingState = state.scratchGui.projectState.loadingState;
//         const user = state.session && state.session.session && state.session.session.user;
//         return {
//             isLoadingUpload: getIsLoadingUpload(loadingState),
//             isShowingWithoutId: getIsShowingWithoutId(loadingState),
//             loadingState: loadingState,
//             projectChanged: state.scratchGui.projectChanged,
//             userOwnsProject: ownProps.authorUsername && user &&
//                 (ownProps.authorUsername === user.username),
//             vm: state.scratchGui.vm
//         };
//     };

//     const mapDispatchToProps = (dispatch, ownProps) => ({
//         cancelFileUpload: loadingState => dispatch(onLoadedProject(loadingState, false, false)),
//         closeFileMenu: () => dispatch(closeFileMenu()),
//         // transition project state from loading to regular, and close
//         // loading screen and file menu
//         onLoadingFinished: (loadingState, success) => {
//             dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
//             dispatch(closeLoadingProject());
//             dispatch(closeFileMenu());
//         },
//         // show project loading screen
//         onLoadingStarted: () => dispatch(openLoadingProject()),
//         onSetProjectTitle: title => dispatch(setProjectTitle(title)),
//         // step 4: transition the project state so we're ready to handle the new
//         // project data. When this is done, the project state transition will be
//         // noticed by componentDidUpdate()
//         requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState))
//     });

//     // Allow incoming props to override redux-provided props. Used to mock in tests.
//     const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
//         {}, stateProps, dispatchProps, ownProps
//     );

//     return injectIntl(connect(
//         mapStateToProps,
//         mapDispatchToProps,
//         mergeProps
//     )(SBFileUploaderComponent));
// };

// export {
//     SBFileUploaderHOC as default
// };


// import bindAll from 'lodash.bindall';
// import React from 'react';
// import PropTypes from 'prop-types';
// import {defineMessages, intlShape, injectIntl} from 'react-intl';
// import {connect} from 'react-redux';
// import log from '../lib/log';
// import sharedMessages from './shared-messages';

// import {
//     LoadingStates,
//     getIsLoadingUpload,
//     getIsShowingWithoutId,
//     onLoadedProject,
//     requestProjectUpload
// } from '../reducers/project-state';
// import {setProjectTitle} from '../reducers/project-title';
// import {
//     openLoadingProject,
//     closeLoadingProject
// } from '../reducers/modals';
// import {
//     closeFileMenu
// } from '../reducers/menus';

// const messages = defineMessages({
//     loadError: {
//         id: 'gui.projectLoader.loadError',
//         defaultMessage: 'The project file that was selected failed to load.',
//         description: 'An error that displays when a local project file fails to load.'
//     }
// });

// const SBFileUploaderHOC = function (WrappedComponent) {
//     class SBFileUploaderComponent extends React.Component {
//         constructor(props) {
//             super(props);
//             bindAll(this, [
//                 'createFileObjects',
//                 'getProjectTitleFromFilename',
//                 'handleFinishedLoadingUpload',
//                 'handleStartSelectingFileUpload',
//                 'handleChange',
//                 'onload',
//                 'removeFileObjects',
//                 'openSpecificSB3File' // New method for opening specific SB3 file
//             ]);
//         }

//         componentDidUpdate(prevProps) {
//             if (this.props.isLoadingUpload && !prevProps.isLoadingUpload) {
//                 this.handleFinishedLoadingUpload(); 
//             }
//         }

//         componentWillUnmount() {
//             this.removeFileObjects();
//         }

//         handleStartSelectingFileUpload() {
//             this.createFileObjects(); 
//         }

//         createFileObjects() {
//             this.removeFileObjects(); // Remove any previous file objects
//             this.fileReader = new FileReader(); // Initialize the FileReader
//             this.fileReader.onload = this.onload; // Bind the onload event to process the file
        
//             // Instead of creating an input element for file upload, we load the file from a given path.
//             this.loadFileFromPath('./assets/river.sb3'); // Load the SB3 file directly from the path
//         }
        
//         // New function to load the file from a specified path
//         loadFileFromPath(filePath) {
//             fetch(filePath)
//     .then(response => {
//         if (!response.ok) {
//             throw new Error('Network response was not ok');
//         }
//         return response.arrayBuffer();
//     })
//     .then(arrayBuffer => {
//         console.log("Successfully fetched SB3 file");
//         this.fileToUpload = new Blob([arrayBuffer], { type: 'application/octet-stream' });
//         this.fileReader.readAsArrayBuffer(this.fileToUpload);
//     })
//     .catch(error => {
//         console.error('Error loading file:', error);
//     });


//         }
        
//         handleChange(e) {
//             // No longer necessary to handle file input changes since we're directly loading from the path
//             console.log("File input handling is skipped in this version.");
//         }
        
//         handleFinishedLoadingUpload() {
//             if (this.fileToUpload && this.fileReader) {
//                 this.fileReader.readAsArrayBuffer(this.fileToUpload); // Read the file as ArrayBuffer if it exists
//             } else {
//                 this.props.cancelFileUpload(this.props.loadingState);
//                 this.removeFileObjects();
//             }
//         }
        
//         getProjectTitleFromFilename(fileInputFilename) {
//             if (!fileInputFilename) return '';
//             const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
//             if (!matches) return '';
//             return matches[1].substring(0, 100); // Extract the title from the file name
//         }
        
//         onload() {
//             if (this.fileReader) {
//                 this.props.onLoadingStarted();
//                 const filename = this.fileToUpload && this.fileToUpload.name;
//                 let loadingSuccess = false;
        
//                 // Load the project using the FileReader result
//                 this.props.vm.loadProject(this.fileReader.result)
//                     .then(() => {
//                         if (filename) {
//                             const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
//                             this.props.onSetProjectTitle(uploadedProjectTitle);
//                         }
//                         loadingSuccess = true;
//                     })
//                     .catch(error => {
//                         console.warn(error);  // Debug error message
//                         alert(this.props.intl.formatMessage(messages.loadError));
//                     })
//                     .then(() => {
//                         // Debugging log to inspect parameters passed to onLoadingFinished
//                         console.log("loadingState:", this.props.loadingState);
//                         console.log("loadingSuccess:", loadingSuccess);
        
//                         // Ensure loadingState is not undefined
//                         if (!this.props.loadingState) {
//                             console.error("loadingState is undefined! Providing fallback value.");
//                             this.props.loadingState = {};  // Provide fallback empty object
//                         }
        
//                         // Ensure loadingState has a 'type' property before accessing it
//                         const loadingState = this.props.loadingState || {};
//                         const loadingSuccess = loadingState.type ? true : false; // Add safety check for `type`
                        
//                         // Call onLoadingFinished with a fallback for undefined objects
//                         this.props.onLoadingFinished(this.props.loadingState, loadingSuccess);
//                         this.removeFileObjects();
//                     });
//             }
//         }
        
//          removeFileObjects() {
//             if (this.inputElement) {
//                 this.inputElement.value = null;
//                 document.body.removeChild(this.inputElement);
//             }
//             this.inputElement = null;
//             this.fileReader = null;
//             this.fileToUpload = null;
//         }

//         openSpecificSB3File = (filePath) => {
//             console.log(`Attempting to open SB3 file from path: ${filePath}`);
//             fetch(filePath)
//                 .then(response => {
//                     if (!response.ok) {
//                         throw new Error(`Network response was not ok: ${response.statusText}`);
//                     }
//                     return response.arrayBuffer();
//                 })
//                 .then(arrayBuffer => {
//                     console.log('Successfully fetched the SB3 file, loading project...');
//                     if (this.props.vm) {
//                         this.props.onLoadingStarted();
//                         this.props.vm.loadProject(arrayBuffer)
//                             .then(() => {
//                                 console.log('Project loaded successfully.');
//                                 const filename = filePath.split('/').pop();
//                                 const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
//                                 this.props.onSetProjectTitle(uploadedProjectTitle);
//                                 this.props.onLoadingFinished(this.props.loadingState, true);
//                             })
//                             .catch(error => {
//                                 console.error('Error loading project:', error);
//                                 alert(this.props.intl.formatMessage(messages.loadError)); 
//                                 this.props.onLoadingFinished(this.props.loadingState, false);
//                             });
//                     } else {
//                         console.error('VM instance is not available.');
//                     }
//                 })
//                 .catch(error => {
//                     console.error('Error fetching SB3 file:', error);
//                     alert(this.props.intl.formatMessage(messages.loadError)); 
//                     this.props.onLoadingFinished(this.props.loadingState, false);
//                 });
//         }

//         render() {
//             const {
//                 cancelFileUpload,
//                 closeFileMenu: closeFileMenuProp,
//                 isLoadingUpload,
//                 isShowingWithoutId,
//                 loadingState,
//                 onLoadingFinished,
//                 onLoadingStarted,
//                 onSetProjectTitle,
//                 projectChanged,
//                 requestProjectUpload: requestProjectUploadProp,
//                 userOwnsProject,
//                 ...componentProps
//             } = this.props;

//             return (
//                 <React.Fragment>
//                     <WrappedComponent
//                         onStartSelectingFileUpload={this.handleStartSelectingFileUpload}
//                         onOpenSpecificSB3File1={() => this.openSpecificSB3File('./asset/river.sb3')}
//                         {...componentProps}
//                     />
//                     {/* Adding a button for manually testing the specific SB3 file load */}
//                     <button onClick={() => this.openSpecificSB3File('./asset/river.sb3')}>
//                         Open river.sb3
//                     </button>
//                 </React.Fragment>
//             );
//         }
//     }

//     SBFileUploaderComponent.propTypes = {
//         canSave: PropTypes.bool,
//         cancelFileUpload: PropTypes.func,
//         closeFileMenu: PropTypes.func,
//         intl: intlShape.isRequired,
//         isLoadingUpload: PropTypes.bool,
//         isShowingWithoutId: PropTypes.bool,
//         loadingState: PropTypes.oneOf(LoadingStates),
//         onLoadingFinished: PropTypes.func,
//         onLoadingStarted: PropTypes.func,
//         onSetProjectTitle: PropTypes.func,
//         projectChanged: PropTypes.bool,
//         requestProjectUpload: PropTypes.func,
//         userOwnsProject: PropTypes.bool,
//         vm: PropTypes.shape({
//             loadProject: PropTypes.func
//         })
//     };

//     const mapStateToProps = (state, ownProps) => {
//         const loadingState = state.scratchGui.projectState.loadingState;
//         const user = state.session && state.session.session && state.session.session.user;
//         return {
//             isLoadingUpload: getIsLoadingUpload(loadingState),
//             isShowingWithoutId: getIsShowingWithoutId(loadingState),
//             loadingState: loadingState,
//             projectChanged: state.scratchGui.projectChanged,
//             userOwnsProject: ownProps.authorUsername && user &&
//                 (ownProps.authorUsername === user.username),
//             vm: state.scratchGui.vm
//         };
//     };

//     const mapDispatchToProps = (dispatch, ownProps) => ({
//         cancelFileUpload: loadingState => dispatch(onLoadedProject(loadingState, false, false)),
//         closeFileMenu: () => dispatch(closeFileMenu()),
//         onLoadingFinished: (loadingState, success) => {
//             dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
//             dispatch(closeLoadingProject());
//             dispatch(closeFileMenu());
//         },
//         onLoadingStarted: () => dispatch(openLoadingProject()),
//         onSetProjectTitle: title => dispatch(setProjectTitle(title)),
//         requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState))
//     });

//     const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
//         {}, stateProps, dispatchProps, ownProps
//     );

//     return injectIntl(connect(
//         mapStateToProps,
//         mapDispatchToProps,
//         mergeProps
//     )(SBFileUploaderComponent));
// };

// export {
//     SBFileUploaderHOC as default
// };


// import bindAll from 'lodash.bindall';
// import React from 'react';
// import PropTypes from 'prop-types';
// import {defineMessages, intlShape, injectIntl} from 'react-intl';
// import {connect} from 'react-redux';
// import log from '../lib/log';
// import sharedMessages from './shared-messages';
// import { 
//     LoadingStates, 
//     getIsLoadingUpload, 
//     getIsShowingWithoutId, 
//     onLoadedProject, 
//     requestProjectUpload 
// } from '../reducers/project-state';
// import {setProjectTitle} from '../reducers/project-title';
// import { 
//     openLoadingProject, 
//     closeLoadingProject 
// } from '../reducers/modals';
// import { closeFileMenu } from '../reducers/menus';

// const messages = defineMessages({
//     loadError: {
//         id: 'gui.projectLoader.loadError',
//         defaultMessage: 'The project file that was selected failed to load.',
//         description: 'An error that displays when a local project file fails to load.'
//     }
// });

// const SBFileUploaderHOC = function (WrappedComponent) {
//     class SBFileUploaderComponent extends React.Component {
//         constructor(props) {
//             super(props);
//             bindAll(this, [
//                 'createFileObjects',
//                 'getProjectTitleFromFilename',
//                 'handleFinishedLoadingUpload',
//                 'handleStartSelectingFileUpload',
//                 'handleChange',
//                 'onload',
//                 'removeFileObjects',
//                 'openSpecificSB3File'
//             ]);
//         }
        
//         componentDidUpdate(prevProps) {
//             if (this.props.isLoadingUpload && !prevProps.isLoadingUpload) {
//                 this.handleFinishedLoadingUpload();
//             }
//         }
        
//         componentWillUnmount() {
//             this.removeFileObjects();
//         }
        
//         handleStartSelectingFileUpload() {
//             this.createFileObjects();
//         }
        
//         createFileObjects() {
//             this.removeFileObjects();
//             this.fileReader = new FileReader();
//             this.fileReader.onload = this.onload;
//             this.loadFileFromPath('./assets/river.sb3');
//         }
        
//         loadFileFromPath(filePath) {
//             fetch(filePath)
//                 .then(response => {
//                     if (!response.ok) {
//                         throw new Error('Network response was not ok');
//                     }
//                     return response.arrayBuffer();
//                 })
//                 .then(arrayBuffer => {
//                     console.log("Successfully fetched SB3 file");
//                     this.fileToUpload = new Blob([arrayBuffer], { type: 'application/octet-stream' });
//                     this.fileReader.readAsArrayBuffer(this.fileToUpload);
//                 })
//                 .catch(error => {
//                     console.error('Error loading file:', error);
//                 });
//         }
        
//         handleChange(e) {
//             console.log("File input handling is skipped in this version.");
//         }
        
//         handleFinishedLoadingUpload() {
//             if (this.fileToUpload && this.fileReader) {
//                 this.fileReader.readAsArrayBuffer(this.fileToUpload);
//             } else {
//                 this.props.cancelFileUpload(this.props.loadingState);
//                 this.removeFileObjects();
//             }
//         }
        
//         getProjectTitleFromFilename(fileInputFilename) {
//             if (!fileInputFilename) return '';
//             const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
//             if (!matches) return '';
//             return matches[1].substring(0, 100);
//         }
        
//         onload() {
//             if (this.fileReader) {
//                 this.props.onLoadingStarted();
//                 const filename = this.fileToUpload && this.fileToUpload.name;
//                 let loadingSuccess = false;
        
//                 // Ensure vm and loadingState are valid
//                 if (this.props.vm && this.props.vm.loadProject) {
//                     this.props.vm.loadProject(this.fileReader.result)
//                         .then(() => {
//                             if (filename) {
//                                 const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
//                                 this.props.onSetProjectTitle(uploadedProjectTitle);
//                             }
//                             loadingSuccess = true;
//                         })
//                         .catch(error => {
//                             console.warn(error);
//                             alert(this.props.intl.formatMessage(messages.loadError));
//                         })
//                         .finally(() => {
//                             // Ensure loadingState is valid before accessing its properties
//                             const validLoadingState = this.props.loadingState || {};
//                             this.props.onLoadingFinished(validLoadingState, loadingSuccess);
//                             this.removeFileObjects();
//                         });
//                 } else {
//                     console.error("VM or loadProject is undefined.");
//                     this.props.onLoadingFinished({}, false);
//                 }
//             } else {
//                 console.error("FileReader is not initialized.");
//                 this.props.onLoadingFinished({}, false);
//             }
//         }
        
//         removeFileObjects() {
//             if (this.inputElement) {
//                 this.inputElement.value = null;
//                 document.body.removeChild(this.inputElement);
//             }
//             this.inputElement = null;
//             this.fileReader = null;
//             this.fileToUpload = null;
//         }
        
//         openSpecificSB3File = (filePath) => {
//             console.log(`Attempting to open SB3 file from path: ${filePath}`);
        
//             fetch(filePath)
//                 .then(response => {
//                     if (!response.ok) {
//                         throw new Error(`Network response was not ok: ${response.statusText}`);
//                     }
//                     return response.arrayBuffer();
//                 })
//                 .then(arrayBuffer => {
//                     console.log('Successfully fetched the SB3 file, loading project...');
        
//                     // Ensure vm is valid before calling its methods
//                     if (this.props.vm && this.props.vm.loadProject) {
//                         this.props.onLoadingStarted();
        
//                         // Use the arrayBuffer directly to load the project
//                         this.props.vm.loadProject(arrayBuffer)
//                             .then(() => {
//                                 console.log('Project loaded successfully.');
//                                 const filename = filePath.split('/').pop();
//                                 const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
//                                 this.props.onSetProjectTitle(uploadedProjectTitle);
//                                 this.props.onLoadingFinished(this.props.loadingState, true);
//                             })
//                             .catch(error => {
//                                 console.error('Error loading project:', error);
//                                 alert(this.props.intl.formatMessage(messages.loadError));
//                                 this.props.onLoadingFinished(this.props.loadingState, false);
//                             });
//                     } else {
//                         console.error('VM instance or loadProject method is not available.');
//                     }
//                 })
//                 .catch(error => {
//                     console.error('Error fetching SB3 file:', error);
//                     alert(this.props.intl.formatMessage(messages.loadError));
//                     this.props.onLoadingFinished(this.props.loadingState, false);
//                 });
//         }

//         render() {
//             const {
//                 cancelFileUpload,
//                 closeFileMenu: closeFileMenuProp,
//                 isLoadingUpload,
//                 isShowingWithoutId,
//                 loadingState,
//                 onLoadingFinished,
//                 onLoadingStarted,
//                 onSetProjectTitle,
//                 projectChanged,
//                 requestProjectUpload: requestProjectUploadProp,
//                 userOwnsProject,
//                 ...componentProps
//             } = this.props;

//             return (
//                 <React.Fragment>
//                     <WrappedComponent
//                         onStartSelectingFileUpload={this.handleStartSelectingFileUpload}
//                         onOpenSpecificSB3File1={this.handleStartSelectingFileUpload}
//                         {...componentProps}
//                     />
//                     <button onClick={() => this.openSpecificSB3File('./assets/river.sb3')}>
//                         Open river.sb3
//                     </button>
//                 </React.Fragment>
//             );
//         }
//     }

//     SBFileUploaderComponent.propTypes = {
//         canSave: PropTypes.bool,
//         cancelFileUpload: PropTypes.func,
//         closeFileMenu: PropTypes.func,
//         intl: intlShape.isRequired,
//         isLoadingUpload: PropTypes.bool,
//         isShowingWithoutId: PropTypes.bool,
//         loadingState: PropTypes.oneOf(LoadingStates),
//         onLoadingFinished: PropTypes.func,
//         onLoadingStarted: PropTypes.func,
//         onSetProjectTitle: PropTypes.func,
//         projectChanged: PropTypes.bool,
//         requestProjectUpload: PropTypes.func,
//         userOwnsProject: PropTypes.bool,
//         vm: PropTypes.shape({
//             loadProject: PropTypes.func
//         })
//     };

//     const mapStateToProps = (state, ownProps) => {
//         const loadingState = state.scratchGui.projectState.loadingState;
//         const user = state.session && state.session.session && state.session.session.user;
//         return {
//             isLoadingUpload: getIsLoadingUpload(loadingState),
//             isShowingWithoutId: getIsShowingWithoutId(loadingState),
//             loadingState: loadingState,
//             projectChanged: state.scratchGui.projectChanged,
//             userOwnsProject: ownProps.authorUsername && user && (ownProps.authorUsername === user.username),
//             vm: state.scratchGui.vm
//         };
//     };

//     const mapDispatchToProps = (dispatch, ownProps) => ({
//         cancelFileUpload: loadingState => dispatch(onLoadedProject(loadingState, false, false)),
//         closeFileMenu: () => dispatch(closeFileMenu()),
//         onLoadingFinished: (loadingState, success) => {
//             dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
//             dispatch(closeLoadingProject());
//             dispatch(closeFileMenu());
//         },
//         onLoadingStarted: () => dispatch(openLoadingProject()),
//         onSetProjectTitle: title => dispatch(setProjectTitle(title)),
//         requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState))
//     });

//     return injectIntl(connect(
//         mapStateToProps,
//         mapDispatchToProps
//     )(SBFileUploaderComponent));
// };

// export {
//     SBFileUploaderHOC as default
// };

// import bindAll from 'lodash.bindall';
// import React from 'react';
// import PropTypes from 'prop-types';
// import {defineMessages, intlShape, injectIntl} from 'react-intl';
// import {connect} from 'react-redux';
// import log from '../lib/log';
// import sharedMessages from './shared-messages';

// import {
//     LoadingStates,
//     getIsLoadingUpload,
//     getIsShowingWithoutId,
//     onLoadedProject,
//     requestProjectUpload
// } from '../reducers/project-state';
// import {setProjectTitle} from '../reducers/project-title';
// import {
//     openLoadingProject,
//     closeLoadingProject
// } from '../reducers/modals';
// import {
//     closeFileMenu
// } from '../reducers/menus';

// const messages = defineMessages({
//     loadError: {
//         id: 'gui.projectLoader.loadError',
//         defaultMessage: 'The project file that was selected failed to load.',
//         description: 'An error that displays when a local project file fails to load.'
//     }
// });

// /**
//  * Higher Order Component to provide behavior for loading local project files into editor.
//  * @param {React.Component} WrappedComponent the component to add project file loading functionality to
//  * @returns {React.Component} WrappedComponent with project file loading functionality added
//  *
//  * <SBFileUploaderHOC>
//  *     <WrappedComponent />
//  * </SBFileUploaderHOC>
//  */
// const SBFileUploaderHOC = function (WrappedComponent) {
//     class SBFileUploaderComponent extends React.Component {
//         constructor (props) {
//             super(props);
//             bindAll(this, [
//                 'createFileObjects',
//                 'getProjectTitleFromFilename',
//                 'handleFinishedLoadingUpload',
//                 'handleStartSelectingFileUpload',
//                 'handleChange',
//                 'onload',
//                 'removeFileObjects'
//             ]);
//         }
//         componentDidUpdate (prevProps) {
//             if (this.props.isLoadingUpload && !prevProps.isLoadingUpload) {
//                 this.handleFinishedLoadingUpload(); // cue step 5 below
//             }
//         }
//         componentWillUnmount () {
//             this.removeFileObjects();
//         }
//         // step 1: this is where the upload process begins
//         handleStartSelectingFileUpload () {
//             this.createFileObjects(); // go to step 2
//         }
//         // step 2: create a FileReader and an <input> element, and issue a
//         // pseudo-click to it. That will open the file chooser dialog.
//         createFileObjects () {
//             this.removeFileObjects();
        
//             // Define the file path to load the SB3 file directly
//             const filePath = './assets/Season.sb3'; // Ensure this path is correct
        
//             // Fetch the SB3 file from the server
//             fetch(filePath)
//                 .then(response => {
//                     // Check if the response status is 404 or other errors
//                     if (!response.ok) {
//                         throw new Error(`File not found at ${filePath}. Status: ${response.status}`);
//                     }
//                     return response.arrayBuffer();
//                 })
//                 .then(buffer => {
//                     // Create a File object with the SB3 file content
//                     this.fileToUpload = new File([buffer], 'river.sb3', { type: 'application/octet-stream' });
//                     this.fileReader = new FileReader();
//                     this.fileReader.onload = this.onload;
        
//                     // Start the file upload process
//                     this.handleFinishedLoadingUpload();
//                 })
//                 .catch(error => {
//                     // Log the error for debugging
//                     console.error('Error loading the SB3 file:', error);
//                     alert('Failed to load the SB3 file. Please check the file path or server setup.');
//                 });
//         }
        
//         // step 3: user has picked a file using the file chooser dialog.
//         // We don't actually load the file here, we only decide whether to do so.
//         handleChange (e) {
//             const {
//                 intl,
//                 isShowingWithoutId,
//                 loadingState,
//                 projectChanged,
//                 userOwnsProject
//             } = this.props;
//             const thisFileInput = e.target;
//             if (thisFileInput.files) { // Don't attempt to load if no file was selected
//                 this.fileToUpload = thisFileInput.files[0];

//                 // If user owns the project, or user has changed the project,
//                 // we must confirm with the user that they really intend to
//                 // replace it. (If they don't own the project and haven't
//                 // changed it, no need to confirm.)
//                 let uploadAllowed = true;
//                 if (userOwnsProject || (projectChanged && isShowingWithoutId)) {
//                     uploadAllowed = confirm( // eslint-disable-line no-alert
//                         intl.formatMessage(sharedMessages.replaceProjectWarning)
//                     );
//                 }
//                 if (uploadAllowed) {
//                     // cues step 4
//                     this.props.requestProjectUpload(loadingState);
//                 } else {
//                     // skips ahead to step 7
//                     this.removeFileObjects();
//                 }
//                 this.props.closeFileMenu();
//             }
//         }
//         // step 4 is below, in mapDispatchToProps

//         // step 5: called from componentDidUpdate when project state shows
//         // that project data has finished "uploading" into the browser
//         handleFinishedLoadingUpload () {
//             if (this.fileToUpload && this.fileReader) {
//                 // begin to read data from the file. When finished,
//                 // cues step 6 using the reader's onload callback
//                 this.fileReader.readAsArrayBuffer(this.fileToUpload);
//             } else {
//                 this.props.cancelFileUpload(this.props.loadingState);
//                 // skip ahead to step 7
//                 this.removeFileObjects();
//             }
//         }
//         // used in step 6 below
//         getProjectTitleFromFilename (fileInputFilename) {
//             if (!fileInputFilename) return '';
//             // only parse title with valid scratch project extensions
//             // (.sb, .sb2, and .sb3)
//             const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
//             if (!matches) return '';
//             return matches[1].substring(0, 100); // truncate project title to max 100 chars
//         }
//         // step 6: attached as a handler on our FileReader object; called when
//         // file upload raw data is available in the reader
//         onload () {
//             if (this.fileReader) {
//                 this.props.onLoadingStarted();
//                 const filename = this.fileToUpload && this.fileToUpload.name;
//                 let loadingSuccess = false;
//                 this.props.vm.loadProject(this.fileReader.result)
//                     .then(() => {
//                         if (filename) {
//                             const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
//                             this.props.onSetProjectTitle(uploadedProjectTitle);
//                         }
//                         loadingSuccess = true;
//                     })
//                     .catch(error => {
//                         log.warn(error);
//                         alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
//                     })
//                     .then(() => {
//                         this.props.onLoadingFinished(this.props.loadingState, loadingSuccess);
//                         // go back to step 7: whether project loading succeeded
//                         // or failed, reset file objects
//                         this.removeFileObjects();
//                     });
//             }
//         }
//         // step 7: remove the <input> element from the DOM and clear reader and
//         // fileToUpload reference, so those objects can be garbage collected
//         removeFileObjects () {
//             if (this.inputElement) {
//                 this.inputElement.value = null;
//                 document.body.removeChild(this.inputElement);
//             }
//             this.inputElement = null;
//             this.fileReader = null;
//             this.fileToUpload = null;
//         }
//         render () {
//             const {
//                 /* eslint-disable no-unused-vars */
//                 cancelFileUpload,
//                 closeFileMenu: closeFileMenuProp,
//                 isLoadingUpload,
//                 isShowingWithoutId,
//                 loadingState,
//                 onLoadingFinished,
//                 onLoadingStarted,
//                 onSetProjectTitle,
//                 projectChanged,
//                 requestProjectUpload: requestProjectUploadProp,
//                 userOwnsProject,
//                 /* eslint-enable no-unused-vars */
//                 ...componentProps
//             } = this.props;
//             return (
//                 <React.Fragment>
//                     <WrappedComponent
//                         onStartSelectingFileUpload={this.handleStartSelectingFileUpload}
//                         {...componentProps}
//                     />
//                 </React.Fragment>
//             );
//         }
//     }

//     SBFileUploaderComponent.propTypes = {
//         canSave: PropTypes.bool,
//         cancelFileUpload: PropTypes.func,
//         closeFileMenu: PropTypes.func,
//         intl: intlShape.isRequired,
//         isLoadingUpload: PropTypes.bool,
//         isShowingWithoutId: PropTypes.bool,
//         loadingState: PropTypes.oneOf(LoadingStates),
//         onLoadingFinished: PropTypes.func,
//         onLoadingStarted: PropTypes.func,
//         onSetProjectTitle: PropTypes.func,
//         projectChanged: PropTypes.bool,
//         requestProjectUpload: PropTypes.func,
//         userOwnsProject: PropTypes.bool,
//         vm: PropTypes.shape({
//             loadProject: PropTypes.func
//         })
//     };
//     const mapStateToProps = (state, ownProps) => {
//         const loadingState = state.scratchGui.projectState.loadingState;
//         const user = state.session && state.session.session && state.session.session.user;
//         return {
//             isLoadingUpload: getIsLoadingUpload(loadingState),
//             isShowingWithoutId: getIsShowingWithoutId(loadingState),
//             loadingState: loadingState,
//             projectChanged: state.scratchGui.projectChanged,
//             userOwnsProject: ownProps.authorUsername && user &&
//                 (ownProps.authorUsername === user.username),
//             vm: state.scratchGui.vm
//         };
//     };
//     const mapDispatchToProps = (dispatch, ownProps) => ({
//         cancelFileUpload: loadingState => dispatch(onLoadedProject(loadingState, false, false)),
//         closeFileMenu: () => dispatch(closeFileMenu()),
//         // transition project state from loading to regular, and close
//         // loading screen and file menu
//         onLoadingFinished: (loadingState, success) => {
//             dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
//             dispatch(closeLoadingProject());
//             dispatch(closeFileMenu());
//         },
//         // show project loading screen
//         onLoadingStarted: () => dispatch(openLoadingProject()),
//         onSetProjectTitle: title => dispatch(setProjectTitle(title)),
//         // step 4: transition the project state so we're ready to handle the new
//         // project data. When this is done, the project state transition will be
//         // noticed by componentDidUpdate()
//         requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState))
//     });
//     // Allow incoming props to override redux-provided props. Used to mock in tests.
//     const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
//         {}, stateProps, dispatchProps, ownProps
//     );
//     return injectIntl(connect(
//         mapStateToProps,
//         mapDispatchToProps,
//         mergeProps
//     )(SBFileUploaderComponent));
// };

// export {
//     SBFileUploaderHOC as default
// };

import bindAll from 'lodash.bindall';
import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, intlShape, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import log from '../lib/log';
import sharedMessages from './shared-messages';

import {
    LoadingStates,
    getIsLoadingUpload,
    getIsShowingWithoutId,
    onLoadedProject,
    requestProjectUpload
} from '../reducers/project-state';
import {setProjectTitle} from '../reducers/project-title';
import {
    openLoadingProject,
    closeLoadingProject
} from '../reducers/modals';
import {
    closeFileMenu
} from '../reducers/menus';

const messages = defineMessages({
    loadError: {
        id: 'gui.projectLoader.loadError',
        defaultMessage: 'The project file that was selected failed to load.',
        description: 'An error that displays when a local project file fails to load.'
    }
});

/**
 * Higher Order Component to provide behavior for loading local project files into editor.
 * @param {React.Component} WrappedComponent the component to add project file loading functionality to
 * @returns {React.Component} WrappedComponent with project file loading functionality added
 *
 * <SBFileUploaderHOC>
 *     <WrappedComponent />
 * </SBFileUploaderHOC>
 */
const SBFileUploaderHOC = function (WrappedComponent) {
    class SBFileUploaderComponent extends React.Component {
        constructor (props) {
            super(props);
            bindAll(this, [
                'createFileObjects',
                'getProjectTitleFromFilename',
                'handleFinishedLoadingUpload',
                'handleStartSelectingFileUpload',
                'handleChange',
                'onload',
                'removeFileObjects'
            ]);
        }
        componentDidUpdate (prevProps) {
            if (this.props.isLoadingUpload && !prevProps.isLoadingUpload) {
                this.handleFinishedLoadingUpload(); // cue step 5 below
            }
        }
        componentWillUnmount () {
            this.removeFileObjects();
        }
        // step 1: this is where the upload process begins
        handleStartSelectingFileUpload () {
            this.createFileObjects(); // go to step 2
        }
        // step 2: modified to load the file from a local path instead of opening a file explorer
        createFileObjects () {
            // redo step 7, in case it got skipped last time and its objects are
            // still in memory
            this.removeFileObjects();

            // Set up the file reader
            this.fileReader = new FileReader();
            this.fileReader.onload = this.onload;

            // Fetch the local file from the path './asset/river.sb3'
            fetch('./assets/river.sb3')
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok.');
                    }
                    return response.blob();  // get the blob from the file
                })
                .then(blob => {
                    this.fileToUpload = new File([blob], 'river.sb3', { type: 'application/octet-stream' });
                    this.fileReader.readAsArrayBuffer(this.fileToUpload);  // Trigger reading file content
                })
                .catch(error => {
                    console.error('Failed to load the project file:', error);
                    alert(this.props.intl.formatMessage(messages.loadError));
                    this.removeFileObjects();
                });
        }

        // step 3: this method is no longer needed for manual file selection, but keeping it
        handleChange (e) {
            // This step is now skipped because the file is loaded directly from a local path
            this.props.closeFileMenu();
        }

        // step 4 is below, in mapDispatchToProps

        // step 5: called from componentDidUpdate when project state shows
        // that project data has finished "uploading" into the browser
        handleFinishedLoadingUpload () {
            if (this.fileToUpload && this.fileReader) {
                // No need to re-read the file here, as it's already being read in createFileObjects
                // This block can be omitted or customized further if needed
            } else {
                this.props.cancelFileUpload(this.props.loadingState);
                this.removeFileObjects();
            }
        }

        // used in step 6 below
        getProjectTitleFromFilename (fileInputFilename) {
            if (!fileInputFilename) return '';
            const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
            if (!matches) return '';
            return matches[1].substring(0, 100); // truncate project title to max 100 chars
        }

        // step 6: handler when file upload raw data is available in the reader
        onload () {
            if (this.fileReader) {
                this.props.onLoadingStarted();
                const filename = this.fileToUpload && this.fileToUpload.name;
                let loadingSuccess = false;
                this.props.vm.loadProject(this.fileReader.result)
                    .then(() => {
                        if (filename) {
                            const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
                            this.props.onSetProjectTitle(uploadedProjectTitle);
                        }
                        loadingSuccess = true;
                    })
                    .catch(error => {
                        log.warn(error);
                        alert(this.props.intl.formatMessage(messages.loadError));
                    })
                    .then(() => {
                        this.props.onLoadingFinished(this.props.loadingState, loadingSuccess);
                        this.removeFileObjects();
                    });
            }
        }

        // step 7: clean up file objects from memory
        removeFileObjects () {
            this.fileReader = null;
            this.fileToUpload = null;
        }

        render () {
            const {
                /* eslint-disable no-unused-vars */
                cancelFileUpload,
                closeFileMenu: closeFileMenuProp,
                isLoadingUpload,
                isShowingWithoutId,
                loadingState,
                onLoadingFinished,
                onLoadingStarted,
                onSetProjectTitle,
                projectChanged,
                requestProjectUpload: requestProjectUploadProp,
                userOwnsProject,
                /* eslint-enable no-unused-vars */
                ...componentProps
            } = this.props;
            return (
                <React.Fragment>
                    <WrappedComponent
                        onStartSelectingFileUpload={this.handleStartSelectingFileUpload}
                        {...componentProps}
                    />
                </React.Fragment>
            );
        }
    }

    SBFileUploaderComponent.propTypes = {
        canSave: PropTypes.bool,
        cancelFileUpload: PropTypes.func,
        closeFileMenu: PropTypes.func,
        intl: intlShape.isRequired,
        isLoadingUpload: PropTypes.bool,
        isShowingWithoutId: PropTypes.bool,
        loadingState: PropTypes.oneOf(LoadingStates),
        onLoadingFinished: PropTypes.func,
        onLoadingStarted: PropTypes.func,
        onSetProjectTitle: PropTypes.func,
        projectChanged: PropTypes.bool,
        requestProjectUpload: PropTypes.func,
        userOwnsProject: PropTypes.bool,
        vm: PropTypes.shape({
            loadProject: PropTypes.func
        })
    };

    const mapStateToProps = (state, ownProps) => {
        const loadingState = state.scratchGui.projectState.loadingState;
        const user = state.session && state.session.session && state.session.session.user;
        return {
            isLoadingUpload: getIsLoadingUpload(loadingState),
            isShowingWithoutId: getIsShowingWithoutId(loadingState),
            loadingState: loadingState,
            projectChanged: state.scratchGui.projectChanged,
            userOwnsProject: ownProps.authorUsername && user &&  
                (ownProps.authorUsername === user.username),
            vm: state.scratchGui.vm
        };
    };

    const mapDispatchToProps = (dispatch, ownProps) => ({      
        cancelFileUpload: loadingState => dispatch(onLoadedProject(loadingState, false, false)),
        closeFileMenu: () => dispatch(closeFileMenu()),
        onLoadingFinished: (loadingState, success) => {
            dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
            dispatch(closeLoadingProject());
            dispatch(closeFileMenu());
        },
        onLoadingStarted: () => dispatch(openLoadingProject()),
        onSetProjectTitle: title => dispatch(setProjectTitle(title)),
        requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState))
    });

    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );

    return injectIntl(connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(SBFileUploaderComponent));
};

export {
    SBFileUploaderHOC as default
};
