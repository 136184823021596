import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Container, Paper, Typography, Button, CircularProgress, LinearProgress } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Webcam from "react-webcam";
import * as tf from '@tensorflow/tfjs';
import * as knnClassifier from '@tensorflow-models/knn-classifier';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import mlImage from './image.png';

const PictoBloxMLEnvironment = ({ name, description, type }) => {
  const [classes, setClasses] = useState([
    { id: 1, name: 'class1', color: '#df4ef2', images: [] },
    { id: 2, name: 'class2', color: '#89f582', images: [] }
  ]);
  const classifier = useMemo(() => knnClassifier.create(), []);
  const [isTraining, setIsTraining] = useState(false);
  const [isModelTrained, setIsModelTrained] = useState(false);
  const webcamRef = useRef(null);
  const [activeWebcam, setActiveWebcam] = useState(null);
  const [isPredicting, setIsPredicting] = useState(false);
  const [predictionResult, setPredictionResult] = useState({});
  const navigate = useNavigate();

  // Webcam configuration
  const videoConstraints = {
    width: 320,
    height: 240,
    facingMode: "user"
  };

  const handleImageUpload = useCallback((event, classId) => {
    const imageSrc = URL.createObjectURL(event.target.files[0]);
    setClasses(prevClasses =>
      prevClasses.map(cls =>
        cls.id === classId ? { ...cls, images: [...cls.images, imageSrc] } : cls
      )
    );
    addImageToClassifier(imageSrc, classId);
  }, []);

  useEffect(() => {
    tf.setBackend('cpu').then(() => tf.ready());
  }, []);

  const captureImage = useCallback((classId) => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setClasses(prevClasses =>
        prevClasses.map(cls =>
          cls.id === classId ? { ...cls, images: [...cls.images, imageSrc] } : cls
        )
      );
      addImageToClassifier(imageSrc, classId);
    }
  }, [webcamRef]);

  const addImageToClassifier = useCallback((imageSrc, classId) => {
    const imgElement = new Image();
    imgElement.src = imageSrc;
    imgElement.onload = () => {
      const imgTensor = tf.browser.fromPixels(imgElement)
        .resizeBilinear([224, 224])
        .toFloat()
        .expandDims(0);
      classifier.addExample(imgTensor, classId - 1);
      imgTensor.dispose();
    };
  }, [classifier]);

  const validateImagesForTraining = () => classes.every(cls => cls.images.length >= 5);

  const handleTraining = useCallback(async () => {
    if (!validateImagesForTraining()) {
      alert('Both classes must have at least 5 images!');
      return;
    }
    setIsTraining(true);
    await new Promise(resolve => setTimeout(resolve, 2000));
    setIsTraining(false);
    setIsModelTrained(true);
  }, [classes]);

  const addClass = useCallback(() => {
    const newClassId = classes.length + 1;
    const colors = ['#e57373', '#81c784', '#64b5f6', '#ffd54f', '#a1887f'];
    setClasses([...classes, {
      id: newClassId,
      name: `class${newClassId}`,
      color: colors[newClassId % colors.length],
      images: []
    }]);
  }, [classes]);

  const toggleWebcamForClass = (classId) => {
    setActiveWebcam(activeWebcam === classId ? null : classId);
  };

  const toggleWebcamForPrediction = () => {
    setIsPredicting(prev => !prev);
    setActiveWebcam(isPredicting ? null : 'prediction');
    // Clear prediction results when stopping the testing
    if (isPredicting) {
      setPredictionResult({});
    }
  };

  useEffect(() => {
    let animationFrameId;

    const predictFromWebcam = async () => {
      if (webcamRef.current && isPredicting && classifier.getNumClasses() > 0) {
        const imgSrc = webcamRef.current.getScreenshot();
        const imgElement = new Image();
        imgElement.src = imgSrc;
        imgElement.onload = async () => {
          const imgTensor = tf.browser.fromPixels(imgElement)
            .resizeBilinear([224, 224])
            .toFloat()
            .expandDims(0);
          try {
            const result = await classifier.predictClass(imgTensor);
            const adjustedClassIndex = result.classIndex + 1;
            const predictedClass = classes.find(cls => cls.id === adjustedClassIndex)?.name || 'Unknown';
            setPredictionResult({
              classIndex: adjustedClassIndex,
              label: predictedClass,
              confidences: result.confidences || {},
            });
          } catch (error) {
            setPredictionResult({ label: 'Prediction failed', confidences: {} });
          } finally {
            imgTensor.dispose();
          }
        };
      }
      animationFrameId = requestAnimationFrame(predictFromWebcam);
    };

    if (isPredicting) {
      animationFrameId = requestAnimationFrame(predictFromWebcam);
    }
    return () => {
      cancelAnimationFrame(animationFrameId);
      // Clear prediction results when the effect is cleaned up
      if (!isPredicting) {
        setPredictionResult({});
      }
    };
  }, [isPredicting, classifier, classes]);

  return (
    <div className="min-h-screen bg-gray-50 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${mlImage})` }}
    >
      <Container className="py-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <Paper className="p-6 mb-6 bg-transparent shadow-none">
              <div className="flex justify-between items-center mb-6">
                <Typography variant="h5" className="font-medium">Training</Typography>
                <Button onClick={addClass} variant="outlined" startIcon={<AddIcon />}
                  className="bg-blue-500 text-white rounded-full px-5 py-2"
                >Add New Class</Button>
              </div>

              <div className="flex flex-col items-center space-y-6">
                {classes.map((cls) => (
                  <Paper key={cls.id} elevation={1} className="overflow-hidden w-full bg-transparent shadow-none">
                    <div className="p-4" style={{ backgroundColor: cls.color }}>
                      <div className="flex justify-between items-center">
                        <Typography className="text-white font-medium">{cls.name}</Typography>
                        <div className="flex gap-2">
                          <Button variant="contained" component="label" className="bg-white hover:bg-gray-100"
                            style={{ color: cls.color, borderRadius: '25px', padding: '10px 20px' }} startIcon={<UploadIcon />}>
                            Upload
                            <input type="file" hidden accept="image/*" onChange={(e) => handleImageUpload(e, cls.id)} />
                          </Button>
                          <Button variant="contained" className="bg-white hover:bg-gray-100"
                            style={{ color: cls.color, borderRadius: '25px', padding: '10px 20px' }} startIcon={<CameraAltIcon />}
                            onClick={() => toggleWebcamForClass(cls.id)}>
                            {activeWebcam === cls.id ? 'Stop' : 'Start'}
                          </Button>
                        </div>
                      </div>

                      {/* Webcam and Captured Images Section */}
                      <div className="mt-4">
                        {activeWebcam === cls.id && (
                          <div className="flex flex-col items-center">
                            <div className="w-[320px] h-[240px] overflow-hidden rounded-lg">
                              <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                                className="w-full h-full object-cover"
                              />
                            </div>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => captureImage(cls.id)}
                              className="mt-2"
                            >
                              Capture
                            </Button>
                          </div>
                        )}

                        {/* Display Captured Images with Reduced Spacing */}
                        {cls.images.length > 0 && (
                          <div className="mt-2">
                            <Typography className="text-white mb-1">Captured Images:</Typography>
                            <div className="flex flex-wrap gap-1">
                              {cls.images.map((img, index) => (
                                <div key={index} className="w-16 h-16 rounded overflow-hidden">
                                  <img
                                    src={img}
                                    alt={`Captured ${index + 1}`}
                                    className="object-cover w-full h-full"
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Paper>
                ))}

                <Button variant="contained" color="primary" onClick={handleTraining} disabled={!validateImagesForTraining() || isTraining}
                  className="mt-4 rounded-full px-6 py-3 font-medium w-52">
                  {isTraining ? 'Training...' : 'Train Model'}
                </Button>
                {isTraining && (
                  <div className="w-full mt-4">
                    <LinearProgress />
                  </div>
                )}
              </div>
            </Paper>
          </div>

          {/* Testing and Prediction Results Section */}
          <div>
            <Paper className="p-6 bg-transparent shadow-none">
              <Typography variant="h5" className="font-medium mb-6">Testing</Typography>
              {isModelTrained ? (
                <Button variant="contained" color="secondary" onClick={toggleWebcamForPrediction} className="mb-4 rounded-full px-6 py-3">
                  {isPredicting ? 'Stop Testing' : 'Start Testing'}
                </Button>
              ) : (
                <Typography className="text-gray-500 mb-4">Please train the model before testing.</Typography>
              )}

              {/* Webcam Preview for Testing */}
              {activeWebcam === 'prediction' && (
                <div className="w-[320px] h-[240px] overflow-hidden rounded-lg mb-4">
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    className="w-full h-full object-cover"
                  />
                </div>
              )}

              {/* Enhanced Prediction Result Section with Confidence Bars */}
              {isPredicting && Object.keys(predictionResult).length > 0 && (
                <Paper elevation={2} className="p-4 mt-6">
                  <Typography variant="h6" className="font-medium mb-4">Prediction Results</Typography>
                  
                  {/* Confidence bars for each class */}
                  <div className="space-y-4">
                    {classes.map((cls) => {
                      const confidence = (predictionResult.confidences[cls.id - 1] || 0) * 100;
                      const isHighestConfidence = cls.id === predictionResult.classIndex;
                      
                      return (
                        <div key={cls.id} className="space-y-1">
                          <div className="flex justify-between items-center">
                            <Typography className="text-sm font-medium">
                              {cls.name}
                              {isHighestConfidence && (
                                <span className="ml-2 px-2 py-0.5 bg-green-100 text-green-800 text-xs rounded-full">
                                  Predicted
                                </span>
                              )}
                            </Typography>
                            <Typography className="text-sm font-medium">
                              {confidence.toFixed(1)}%
                            </Typography>
                          </div>
                          <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                            <div
                              className="h-full transition-all duration-300 ease-in-out rounded-full"
                              style={{
                                width: `${confidence}%`,
                                backgroundColor: cls.color
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Paper>
              )}
            </Paper>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default PictoBloxMLEnvironment;