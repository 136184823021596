import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const UnityWebPlayerComponent = ({ onClose }) => {
    const [isLoading, setIsLoading] = useState(true);

    const handleUnityLoaded = () => {
        setIsLoading(false);
    };

    return (
        <div>
            <div>
                {/* <h2>Unity Web Player</h2> */}
                {isLoading ? (
                    <p>Loading Unity content...</p>
                ) : (
                    <>
                        {/* Unity Web Player embed code */}
                        <object id="UnityObject" classID="clsid:444785F1-DE89-4295-863A-D46C3A781394"
                            width="420" height="750"
                            codebase="http://webplayer.unity3d.com/download_webplayer/UnityWebPlayer.cab#version=2,0,0,0">
                            <param name="unity3d" value="WebPlayer.unity3d" />
                            <embed id="UnityEmbed" src="./mlpbeta/mlpbeta.unity3d" width="420" height="750"
                                type="application/vnd.unity" pluginspage="http://www.unity3d.com/unity-web-player-2.x" />
                        </object>
                        <button onClick={onClose}>Close</button>
                    </>
                )}
            </div>
        </div>
    );
};

export const openUnityWebPlayerModal = () => {
    const modalRoot = document.createElement('div');
    document.body.appendChild(modalRoot);

    const handleClose = () => {
        ReactDOM.unmountComponentAtNode(modalRoot);
        document.body.removeChild(modalRoot);
    };

    ReactDOM.render(<UnityWebPlayerComponent onClose={handleClose} />, modalRoot);
};

export default UnityWebPlayerComponent;
