import React, { useState, useEffect } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import closeIcon from '../../components/cards/icon--close.svg';
import soccerImage from './game_img/soccer_game.png';
import brickBreakerImage from './game_img/bricks_game.png';
import racingCarImage from './game_img/racing_car.png'; // You'll need to add this image

const Soccer = ({ onLoadingProgress, onLoaded }) => {
    const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
        loaderUrl: "/Build/build-runsoccer.loader.js",
        dataUrl: "/Build/build-runsoccer.data/webgl.data",
        frameworkUrl: "/Build/build-runsoccer.framework.js/build.framework.js",
        codeUrl: "/Build/build-runsoccer.wasm/build.wasm",
    });

    useEffect(() => {
        onLoadingProgress(loadingProgression);
    }, [loadingProgression, onLoadingProgress]);

    useEffect(() => {
        if (isLoaded) {
            onLoaded();
        }
    }, [isLoaded, onLoaded]);

    return (
        <div className="w-full h-full">
            <Unity unityProvider={unityProvider} className="w-full h-full" />
        </div>
    );
};

const BrickBreaker = ({ onLoadingProgress, onLoaded }) => {
    const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
        loaderUrl: "Build/game.loader.js",
        dataUrl: "Build/game.data/webgl.data",
        frameworkUrl: "Build/game.framework.js/build.framework.js",
        codeUrl: "Build/game.wasm/build.wasm",
    });

    useEffect(() => {
        onLoadingProgress(loadingProgression);
    }, [loadingProgression, onLoadingProgress]);

    useEffect(() => {
        if (isLoaded) {
            onLoaded();
        }
    }, [isLoaded, onLoaded]);

    return (
        <div className="w-full h-full">
            <Unity unityProvider={unityProvider} className="w-full h-full" />
        </div>
    );
};

const RacingCar = ({ onLoadingProgress, onLoaded }) => {
    const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
        loaderUrl: "Build/Build.loader.js",
        dataUrl: "Build/Build.data/webgl.data",
        frameworkUrl: "Build/Build.framework.js/build.framework.js",
        codeUrl: "Build/Build.wasm/build.wasm",
    });

    useEffect(() => {
        onLoadingProgress(loadingProgression);
    }, [loadingProgression, onLoadingProgress]);

    useEffect(() => {
        if (isLoaded) {
            onLoaded();
        }
    }, [isLoaded, onLoaded]);

    return (
        <div className="w-full h-full">
            <Unity unityProvider={unityProvider} className="w-full h-full" />
        </div>
    );
};

const UnityGameModal = ({ isOpen, onClose }) => {
    const [gameType, setGameType] = useState(null);
    const [isGameStarted, setIsGameStarted] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setIsGameStarted(false);
            setGameType(null);
            setLoadingProgress(0);
            setIsLoaded(false);
        }
    }, [isOpen]);

    if (!isOpen) return null;

    const handleCardClick = (type) => {
        setGameType(type.toLowerCase().replace(' ', '-'));
        setIsGameStarted(true);
        setLoadingProgress(0);
        setIsLoaded(false);
    };

    const handleBackClick = () => {
        setIsGameStarted(false);
        setGameType(null);
        setLoadingProgress(0);
        setIsLoaded(false);
    };

    const handleLoadingProgress = (progress) => {
        setLoadingProgress(progress);
    };

    const handleLoaded = () => {
        setIsLoaded(true);
    };

    const gameCards = [
        { type: 'Soccer', image: soccerImage },
        { type: 'Brick Breaker', image: brickBreakerImage },
        { type: 'Racing Car', image: racingCarImage }
    ];

    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center"
            onClick={(e) => {
                if (e.target === e.currentTarget) onClose();
            }}
        >
            <div className="bg-white rounded-lg w-full h-full max-h-screen max-w-screen relative overflow-hidden">
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 bg-indigo-500 text-white px-3 py-1 rounded-full hover:bg-indigo-600 transition-colors z-50"
                >
                    <img src={closeIcon} alt="Close" />
                </button>

                {!isGameStarted && (
                    <div className="absolute top-4 left-4 flex space-x-4">
                        {gameCards.map((game) => (
                            <div
                                key={game.type}
                                onClick={() => handleCardClick(game.type)}
                                className="cursor-pointer bg-gray-100 rounded-lg p-4 shadow-lg hover:shadow-xl transition-shadow w-80 text-center"
                            >
                                <img
                                    src={game.image}
                                    alt={`${game.type} Game`}
                                    className="mb-4 w-full h-40 object-cover rounded-md"
                                />
                                <div className="text-lg font-semibold text-gray-700">{game.type} Game</div>
                                <p className="text-sm text-gray-500">Click to play the {game.type} game</p>
                            </div>
                        ))}
                    </div>
                )}

                {isGameStarted && (
                    <div className="relative w-full h-full">
                        {gameType === 'soccer' && <Soccer onLoadingProgress={handleLoadingProgress} onLoaded={handleLoaded} />}
                        {gameType === 'brick-breaker' && <BrickBreaker onLoadingProgress={handleLoadingProgress} onLoaded={handleLoaded} />}
                        {gameType === 'racing-car' && <RacingCar onLoadingProgress={handleLoadingProgress} onLoaded={handleLoaded} />}

                        {/* Loading bar - Centered and half width */}
                        {!isLoaded && (
                            <div className="absolute inset-0 bg-white bg-opacity-90 z-10 flex items-center justify-center">
                                <div className="w-1/8 max-w-md">
                                    <p className="text-lg font-bold mb-2 text-center">
                                        Loading Game... {Math.round(loadingProgress * 100)}%
                                    </p>
                                    <div className="w-full h-2 bg-gray-300 rounded overflow-hidden">
                                        <div
                                            className="bg-purple-500 h-full transition-all duration-300 ease-out"
                                            style={{ width: `${loadingProgress * 100}%` }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <button
                            onClick={handleBackClick}
                            className="absolute left-4 bottom-4 bg-gray-500 text-white px-4 py-2 rounded-full hover:bg-gray-600 transition-colors z-50"
                        >
                            Back to Games
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UnityGameModal;