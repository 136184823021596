import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { Button } from "../ui/button"
import { ChevronRight, ChevronLeft, X } from "lucide-react"
import closeIcon from '../../components/cards/icon--close.svg'
import arvrIcon from './ar-vr_img/ar.jpg'
import arvrPdf from './ar_vr.pdf'
import arvrflower from './ar-vr_img/ar_vr_flower.jpg'
import arvrfrog from './ar-vr_img/ar_vr_frog.jpg'

export default function Arvrdocs({ isOpen, onClose }) {
  const [currentStep, setCurrentStep] = useState(1)
  const [zoomedImage, setZoomedImage] = useState(null)
  const [currentProject, setCurrentProject] = useState("Life Cycle of Frog")

  if (!isOpen) return null

  const projects = [
    {
      name: "Life Cycle of Frog",
      steps: [
        {
          title: "Open XR Blocks platform and sign in",
          content: [
            {
              text: "Navigate to the XR Blocks platform website and locate the sign-in button, usually found in the top right corner of the page.",
              image: arvrfrog
            },
            {
              text: "Enter your credentials to log in to your account. If you don't have an account, you'll need to create one before proceeding. Look for a 'Sign Up' or 'Create Account' option.",
              image: null
            }
          ]
        },
        {
          title: "Click on new project",
          content: [
            {
              text: "Once logged in, you'll be taken to your dashboard. Look for a button or link labeled 'New Project' or 'Create Project'.",
              image: null
            },
            {
              text: "Click on this button to start a fresh project. You may be prompted to choose a project type or template - select the appropriate option for an AR/VR project.",
              image: null
            }
          ]
        },
        {
          title: "Click on Assets to select Life cycle of Frog",
          content: [
            {
              text: "In your new project workspace, find the 'Assets' section. This is typically located in a sidebar or top menu.",
              image: null
            },
            {
              text: "Within the Assets library, search for 'Life cycle of Frog'. You may need to use filters or categories to narrow down your search.",
              image: null
            },
            {
              text: "Once you've found the 'Life cycle of Frog' asset, select it to add it to your project. This might involve clicking an 'Add' button or dragging the asset into your project workspace.",
              image: null
            }
          ]
        }
      ]
    },
    {
      name: "Parts of Flower",
      steps: [
        {
          title: "Open XR blocks",
          content: [
            {
              text: "Locate the XR Blocks application on your device. The icon typically represents virtual or augmented reality.",
              image: arvrflower
            },
            {
              text: "Click or tap on the XR Blocks icon to launch the application. Ensure you have a stable internet connection for the best experience.",
              image: null
            }
          ]
        },
        {
          title: "Click on '+' new project",
          content: [
            {
              text: "Once the XR Blocks interface loads, look for a '+' icon or a 'New Project' button. This is usually prominently displayed on the main screen or in the top menu bar.",
              image: null
            },
            {
              text: "Click on the '+' or 'New Project' button. You may be presented with different project types or templates - choose the one most suitable for creating an educational AR/VR experience about flower parts.",
              image: null
            }
          ]
        },
        {
          title: "Click on 'Assets'",
          content: [
            {
              text: "In your new project workspace, locate the 'Assets' section. This might be a tab, button, or menu item, typically found in the sidebar or top navigation.",
              image: null
            },
            {
              text: "Click on 'Assets' to open the asset library. This is where you'll find various 3D models, textures, and other resources for your project.",
              image: null
            }
          ]
        }
      ]
    }
  ]

  const handleProjectChange = (projectName) => {
    setCurrentProject(projectName)
    setCurrentStep(1)  // Reset to the first step when changing projects
  }

  const renderSteps = (steps) => {
    return (
      <div className="space-y-8 max-w-3xl mx-auto">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            {steps.map((step, index) => (
              <div key={index} className={currentStep === index + 1 ? "" : "hidden"}>
                <h3 className="text-2xl font-bold mb-4 text-center text-emerald-800">{step.title}</h3>
                {step.content.map((content, contentIndex) => (
                  <motion.div
                    key={contentIndex}
                    className="mb-6"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: contentIndex * 0.2 }}
                  >
                    <p className="text-lg mb-4 text-gray-800">{content.text}</p>
                    {content.image&&<div className="relative">
                      <img
                        src={content.image}
                        width={600}
                        height={300}
                        alt="AR and VR concept illustration"
                        className="rounded-lg mx-auto my-6 cursor-pointer transition-transform hover:scale-105 border-2 border-emerald-500 shadow-md"

                      />
                      <div className="absolute inset-0 bg-emerald-100 bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity rounded-lg w-[600px] h-[315px] mx-auto"
                        onClick={() => setZoomedImage(content.image)}>
                        <span className="text-emerald-800 text-lg font-bold">Click to zoom</span>
                      </div>
                    </div>}
                  </motion.div>
                ))}
                <div className="flex justify-between items-center mt-6">
                  <Button
                    variant="outline"
                    onClick={() => setCurrentStep(prev => Math.max(1, prev - 1))}
                    disabled={currentStep === 1}
                    className="bg-emerald-200 text-emerald-800 hover:bg-emerald-300 border-emerald-500"
                  >
                    <ChevronLeft className="mr-2 h-4 w-4" /> Previous
                  </Button>
                  <span className="text-sm text-emerald-800">Step {currentStep} of {steps.length}</span>
                  <Button
                    variant="outline"
                    onClick={() => setCurrentStep(prev => Math.min(steps.length, prev + 1))}
                    disabled={currentStep === steps.length}
                    className="bg-emerald-200 text-emerald-800 hover:bg-emerald-300 border-emerald-500"
                  >
                    Next <ChevronRight className="ml-2 h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    )
  }

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gradient-to-br from-emerald-100 via-teal-50 to-cyan-100">
      <div className="bg-gradient-to-br from-emerald-100 via-teal-50 to-cyan-100 text-gray-900 p-4 rounded-lg shadow-xl w-full mx-auto">
        <button
          onClick={onClose}
          className="fixed right-4 top-4 bg-indigo-500 text-white px-3 py-1 rounded-full hover:bg-indigo-600 transition-colors z-50"
        >
          <img
            src={closeIcon}
            alt="Close"
          />
        </button>
        <div className="container mx-auto">
          <motion.h1
            className="text-4xl font-bold mb-6 text-center text-emerald-800"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            AR/VR Educational Projects Guide
          </motion.h1>

          <Tabs defaultValue="intro" className="space-y-4">
            <TabsList className="justify-center bg-white bg-opacity-70 rounded-lg p-1 shadow-md">
              <TabsTrigger value="intro" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Introduction</TabsTrigger>
              <TabsTrigger value="setup" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Setup Guide</TabsTrigger>
              <TabsTrigger value="applications" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Applications</TabsTrigger>
              <TabsTrigger value="tips" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Tips</TabsTrigger>
              <TabsTrigger value="projects" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Projects</TabsTrigger>
              <TabsTrigger value="Document" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Document</TabsTrigger>
            </TabsList>

            <TabsContent value="intro">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-center text-emerald-800 text-3xl">Introduction to AR/VR</CardTitle>
                </CardHeader>
                <CardContent className="max-w-3xl mx-auto space-y-6">
                  <motion.p
                    className="text-lg text-gray-800"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    Augmented Reality (AR) and Virtual Reality (VR) are exciting technologies that change how we see and interact with the world around us. They're like special tools that can show you amazing things!
                  </motion.p>
                  <motion.div
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 0.4 }}
                    className="relative"
                  >
                    <img
                      src={arvrIcon}
                      width={600}
                      height={300}
                      alt="AR and VR concept illustration"
                      className="rounded-lg mx-auto my-6 cursor-pointer transition-transform hover:scale-105 border-2 border-emerald-500 shadow-md"

                    />
                    <div className="absolute inset-0 bg-emerald-100 bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity rounded-lg w-[600px] h-[315px] mx-auto"
                      onClick={() => setZoomedImage(arvrIcon)}>
                      <span className="text-emerald-800 text-lg font-bold">Click to zoom</span>
                    </div>
                  </motion.div>
                  <motion.h2
                    className="text-2xl font-bold text-emerald-800"
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.6 }}
                  >
                    What is Augmented Reality (AR)?
                  </motion.h2>
                  <motion.p
                    className="text-gray-800"
                    initial={{ x: 50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.8 }}
                  >
                    Augmented Reality is like adding a layer of digital information to the real world. It uses your device's camera to show you the world around you, but then adds cool digital elements on top, like 3D animals or floating text!
                  </motion.p>
                  {/* <motion.div
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 1 }}
                    className="relative"
                  >
                    <img
                      src={arvrIcon}
                      width={600}
                      height={300}
                      alt="AR and VR concept illustration"
                      className="rounded-lg mx-auto my-6 cursor-pointer transition-transform hover:scale-105 border-2 border-emerald-500 shadow-md"

                    />
                    <div className="absolute inset-0 bg-emerald-100 bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity rounded-lg w-[600px] h-[315px] mx-auto"
                      onClick={() => setZoomedImage(arvrIcon)}>
                      <span className="text-emerald-800 text-lg font-bold">Click to zoom</span>
                    </div>
                  </motion.div> */}
                  <motion.h2
                    className="text-2xl font-bold text-emerald-800"
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 1.2 }}
                  >
                    What is Virtual Reality (VR)?
                  </motion.h2>
                  <motion.p
                    className="text-gray-800"
                    initial={{ x: 50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 1.4 }}
                  >
                    Virtual Reality is like stepping into a whole new world! When you put on a VR headset, it's as if you've been transported to a different place. You can look around, move, and interact with this new digital world as if you were really there!
                  </motion.p>
                  {/* <motion.div
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 1.6 }}
                    className="relative"
                  >
                    <img
                      src={arvrIcon}
                      width={600}
                      height={300}
                      alt="AR and VR concept illustration"
                      className="rounded-lg mx-auto my-6 cursor-pointer transition-transform hover:scale-105 border-2 border-emerald-500 shadow-md"

                    />
                    <div className="absolute inset-0 bg-emerald-100 bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity rounded-lg w-[600px] h-[315px] mx-auto"
                      onClick={() => setZoomedImage(arvrIcon)}>
                      <span className="text-emerald-800 text-lg font-bold">Click to zoom</span>
                    </div>
                  </motion.div> */}
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="setup">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-emerald-800 text-3xl">Setup Guide for AR/VR</CardTitle>
                </CardHeader>
                <CardContent>
                  <ol className="list-decimal list-inside space-y-2 text-gray-800">
                    <li>Open TeachXR.co</li>
                    <li>Download and install for both Windows and Android</li>
                    <li>On Windows, open the zip file and click the .exe file to open the XR blocks platform</li>
                    <li>Sign in to "sketchfab" as well</li>
                    <li>Use the same login on Windows and Android to see saved projects on your phone</li>
                    <li>Use AR mode</li>
                    <li>On Android, download the "sensexr" app to connect via Bluetooth with your phone</li>
                    <li>Add code and experience VR</li>
                  </ol>
                  <p className="mt-4 text-gray-800">This setup is great for exploring nature, old buildings, and many other interesting places!</p>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="applications">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-emerald-800 text-3xl">Fun Ways to Use AR/VR in Learning</CardTitle>
                </CardHeader>
                <CardContent>
                  <ul className="list-disc list-inside space-y-2 text-gray-800">
                    <li><strong className="text-emerald-700">Virtual Field Trips:</strong> Visit amazing places like museums, space, or under the sea!</li>
                    <li><strong className="text-emerald-700">Interactive Learning:</strong> Learn about animals, stars, and history by seeing them come to life!</li>
                    <li><strong className="text-emerald-700">Creative Expression:</strong> Make awesome 3D art in virtual worlds!</li>
                    <li><strong className="text-emerald-700">Language Learning:</strong> Practice new languages in fun, virtual environments!</li>
                    <li><strong className="text-emerald-700">STEM Education:</strong> Explore cool science and math concepts in 3D!</li>
                  </ul>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="tips">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-emerald-800 text-3xl">Tips for Parents and Teachers</CardTitle>
                </CardHeader>
                <CardContent>
                  <ul className="list-disc list-inside space-y-2 text-gray-800">
                    <li><strong className="text-emerald-700">Start Small:</strong> Begin with short VR sessions and gradually make them longer</li>
                    <li><strong className="text-emerald-700">Talk About It:</strong> Discuss what you learned and saw in VR after each session</li>
                    <li><strong className="text-emerald-700">Stay Comfy:</strong> Watch out for any signs of dizziness or discomfort</li>
                    <li><strong className="text-emerald-700">Take Breaks:</strong> Remember to rest your eyes and move around between VR adventures</li>
                  </ul>
                  <p className="mt-4 text-gray-800">By choosing engaging content and creating a safe space for VR, you can give kids amazing learning experiences!</p>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="projects">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-center text-emerald-800 text-3xl">AR/VR Projects</CardTitle>
                  <CardDescription className="text-center text-emerald-700">Step-by-step guides for creating cool AR/VR educational projects</CardDescription>
                </CardHeader>
                <CardContent>
                  <Tabs defaultValue={projects[0].name} onValueChange={handleProjectChange}>
                    <TabsList className="justify-center bg-emerald-100 rounded-lg p-1">
                      {projects.map((project, index) => (
                        <TabsTrigger key={index} value={project.name} className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">
                          {project.name}
                        </TabsTrigger>
                      ))}
                    </TabsList>
                    {projects.map((project, index) => (
                      <TabsContent key={index} value={project.name}>
                        {renderSteps(project.steps)}
                      </TabsContent>
                    ))}
                  </Tabs>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="Document">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-emerald-800 text-3xl">Document for AR/VR projects</CardTitle>
                </CardHeader>
                <CardContent>
                  <iframe src={arvrPdf} width='100%' height='680px' />
                </CardContent>
              </Card>
            </TabsContent>

          </Tabs>

          {zoomedImage && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[60]">
              {console.log(zoomedImage)}
              <div className="relative max-w-4xl max-h-[90vh]">
                <img
                  src={zoomedImage}
                  width={1200}
                  height={600}
                  alt="Zoomed image"
                  className="rounded-lg border-4 border-emerald-500 shadow-xl max-w-full max-h-full object-contain"
                />
                <Button
                  className="absolute top-2 right-2 bg-emerald-700 hover:bg-emerald-800 text-white"
                  onClick={() => setZoomedImage(null)}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}