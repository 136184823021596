const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const axios = require('axios');
const codeGeneration = require('../codeGeneration');

let setupCode = '';
let defineCode = '';
let loopCode = '';
let bodyCode = '';
let initializedPins = new Set(); // To track initialized pins
let definedFunctions = new Set();
let definedVariable = new Set();

class Scratch3ActuatorsBlocks {
    constructor(runtime) {
        this.runtime = runtime;
    }

    getInfo() {
        return {
            id: 'actuators',
            color1: '#cd950c',
            name: formatMessage({
                id: 'actuators.categoryName',
                default: 'Actuators',
                description: 'Actuators extension category'
            }),
            blocks: [
                {
                    opcode: 'cntBuzzer',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'actuators.cntBuzzer',
                        default: 'Connect buzzer',
                        description: 'connect to buzzer'
                    })
                },
                {
                    opcode: 'buzzerState',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'actuators.buzzerState',
                        default: 'Turn [state] the buzzer',
                        description: 'set buzzer state'
                    }),
                    arguments: {
                        state: {
                            type: ArgumentType.STRING,
                            menu: 'stateMenu',
                            defaultValue: 'on'
                        },
                    }
                },
                {
                    opcode: 'contMotor',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'actuators.defMotor',
                        default: 'Connect [motor] motor',
                        description: 'connect Variable'
                    }),
                    arguments: {
                        motor: {
                            type: ArgumentType.STRING,
                            menu: 'motorMenu',
                            defaultValue: 'left'
                        }
                    }
                },
                {
                    opcode: 'rotateMotor',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'actuators.rotateMotor',
                        default: 'rotate [motor] motor in [direction]',
                        description: 'rotate motor'
                    }),
                    arguments: {
                        motor: {
                            type: ArgumentType.STRING,
                            menu: 'motorMenu',
                            defaultValue: 'left'
                        },
                        direction: {
                            type: ArgumentType.STRING,
                            menu: 'directionMenu',
                            defaultvalue: 'clockwise'
                        }
                    }
                },
                {
                    opcode: 'atchServo',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'actuators.atchServo',
                        default: 'attach the servo',
                        description: 'connect servo'
                    })
                },
                {
                    opcode: 'rotateServo',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'actuators.rotateServo',
                        default: 'rotate servo motor by [degree] degree',
                        description: 'rotate motor'
                    }),
                    arguments: {
                        degree: {
                            type: ArgumentType.ANGLE,
                            defaultvalue: '0'
                        }
                    }
                },
            ],
            menus: {
                stateMenu: {
                    items: ['on', 'off']
                },
                motorMenu: {
                    items: ['left', 'right']
                },
                directionMenu: {
                    items: ['clockwise', 'anti_clockwise']
                }
            }
        };
    }
    buzzerState(args) {
        let state = args.state
        if (state === 'on') {
            state = 'HIGH'
        }
        else {
            state = 'LOW'
        }
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `digitalWrite(buz,${state});`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `digitalWrite(buz,${state});`;
        }
        this.accumulateCode();
        this.resetCode();
    }
    cntBuzzer() {
        // Initialize the pins in setup code if not already done
        if (!initializedPins.has('trigirPin')) {
            defineCode += '#define buz 13\n';
            setupCode += 'pinMode(buz, OUTPUT);\n';
            initializedPins.add('trigirPin');
        }
        this.accumulateCode();
        // Optionally, reset the setup code for subsequent blocks
        this.resetCode();
    }
    contMotor(args) {
        const motor = args.motor
        if (motor === 'left') {
            if (!definedVariable.has('lmotor')) {
                defineCode += `#define m1a 8
                #define m1b 9`;
                setupCode += `pinMode(m1a, OUTPUT);
                pinMode(m1b, OUTPUT);`;
            }
            definedVariable.add('lmotor');
        }
        if (motor === 'right') {
            if (!definedVariable.has('rmotor')) {
                defineCode += `#define m2a 10
                #define m2b 11`;
                setupCode += `pinMode(m2a, OUTPUT);
                pinMode(m2b, OUTPUT);`;
            }
            definedVariable.add('rmotor');
        }
        this.accumulateCode();
        this.resetCode();
    }
    rotateMotor(args) {
        const motor = args.motor;
        const direction = args.direction;
        let codeToAdd = '';

        // Define the function name based on motor and direction
        const functionName = `${motor}_${direction}`;
        const motorPinA = motor === 'left' ? 'm1a' : 'm2a';
        const motorPinB = motor === 'left' ? 'm1b' : 'm2b';
        const pinStateA = direction === 'clockwise' ? 'HIGH' : 'LOW';
        const pinStateB = direction === 'clockwise' ? 'LOW' : 'HIGH';

        // Check if the function has already been defined
        if (!definedFunctions.has(functionName)) {
            bodyCode += `
            void ${functionName}() {
                digitalWrite(${motorPinA}, ${pinStateA});
                digitalWrite(${motorPinB}, ${pinStateB});
            }\n`;
            definedFunctions.add(functionName);
        }

        // Add the function call to the relevant code section based on context
        codeToAdd = `${functionName}();\n`;

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }

        this.accumulateCode();
        this.resetCode();
    }
    atchServo() {
        if (!definedVariable.has('setservo')) {
            defineCode += `#include <Servo.h>
            Servo servo;`;
        }
        definedVariable.add('setservo');
        if (!initializedPins.has('servopin')) {
            setupCode += ` servo.attach(12);`;
        }
        initializedPins.add('servopin');
        this.accumulateCode();
        this.resetCode();
    }
    rotateServo(args) {
        const degree = args.degree;
        const MIN_ANGLE = 0;    // Define your minimum angle
        const MAX_ANGLE = 180;
        let codeToAdd = '';
        if (degree < MIN_ANGLE || degree > MAX_ANGLE) {
            // console.error;
            return (`Enter the angle between ${MIN_ANGLE} and ${MAX_ANGLE}`); // Exit the function if the angle is out of range
          }
        // Add the function call to the relevant code section based on context
        codeToAdd = `servo.write(${degree});\n`;

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }

        this.accumulateCode();
        this.resetCode();
    }
    accumulateCode() {
        codeGeneration.accumulateCode(defineCode, bodyCode, setupCode, loopCode);
    }
    // Method to reset code variables if needed
    resetCode() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
    }
    full_reset() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
        initializedPins.clear();
        definedFunctions.clear();
        definedVariable.clear();
    }
}

module.exports = Scratch3ActuatorsBlocks;
