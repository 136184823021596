import React from 'react';
import App from '../../App.js';

const MLEnv = () => {
    return (
        <div
            style={{
                position: 'fixed',            // Fixes the component on the viewport
                top: 0,
                left: 0,
                width: '100vw',               // Full width of the viewport
                height: '100vh',              // Full height of the viewport
                backgroundColor: 'white',     // Background color for full-screen effect
                zIndex: 1000,                 // Ensures it overlays other content
                overflow: 'auto',             // Allows scrolling if content overflows
            }}
        >
            <App />
        </div>
    );
};

export default MLEnv;
