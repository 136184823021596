const Avrgirl = require('avrgirl-arduino');
const axios = require('axios');
const Scratch3RoboticArmBlocks = require('../../../../stemblocks-VM/src/extensions/Robotic_Arm/index');
const Scratch3ArduinoUnoBlocks = require('../../../../stemblocks-VM/src/extensions/scratch3_arduino/index.js');
const Scratch3ArduinoRoboticCarBlocks = require('../../../../stemblocks-VM/src/extensions/scratch3_car/index.js');
const Scratch3RoboticSpiderBlocks = require('../../../../stemblocks-VM/src/extensions/Robotic_Spider/index.js');
const Scratch3SensorsBlocks = require('../../../../stemblocks-VM/src/extensions/sensors/index.js');
const Scratch3ActuatorsBlocks = require('../../../../stemblocks-VM/src/extensions/actuators/index.js');
const StemEngineBlocks = require('../../../../stemblocks-VM/src/extensions/stemengine/index.js');
const codeGeneration = require('../../../../stemblocks-VM/src/extensions/codeGeneration.js');
import { string } from "prop-types";
import apiClient from "../../utils/apiClient.js";
import { ESPLoader } from 'esptool-js';

async function generateAndUploadCode(port) {
    // Retrieve selected board from localStorage
    let selectedBoardObject = localStorage.getItem('selectedBoard');
    if (!selectedBoardObject) {
        alert("Please select a board before proceeding.");
        return;
    }
    selectedBoardObject = JSON.parse(selectedBoardObject);
    const selectedBoard = selectedBoardObject.name || '';
    const isESP32 = selectedBoard.toLowerCase() === 'esp32';
    const deviceType = isESP32 ? 'esp32' : 'arduino';
    const boardConfig = isESP32 ? { board: 'esp32:esp32:esp32' } : { board: 'arduino:avr:uno' };

    console.log("Selected Board:", selectedBoard);

    // Instantiate block classes
    const arduinoBlocks = new Scratch3ArduinoUnoBlocks();
    const armBlocks = new Scratch3RoboticArmBlocks();
    const carBlocks = new Scratch3ArduinoRoboticCarBlocks();
    const spiderBlocks = new Scratch3RoboticSpiderBlocks();
    const sensorBlock = new Scratch3SensorsBlocks();
    const actuatorBlock = new Scratch3ActuatorsBlocks();
    const stemEngine = new StemEngineBlocks();

    const finalCode = codeGeneration.generateFinalCode();
    console.log('Generated Code:', finalCode);

    try {
        // Send code for compilation
        const response = await apiClient.post('/iot/burnCode', {
            code: finalCode,
            deviceType,
            board: boardConfig.board
        }, {
            responseType: 'arraybuffer'
        });

        const firmwareBuffer = Buffer.from(response.data);
        console.log("Received compiled file. Size:", firmwareBuffer.length, "bytes");

        if (selectedBoard === 'Arduino Uno') {
            await flashArduinoUno(firmwareBuffer);
        } else if (isESP32) {
            await flashESP32(port, firmwareBuffer); // Pass the port as a parameter
        } else {
            alert("Unsupported board type. Please select a valid board.");
        }
    } catch (error) {
        console.error('Error during flashing:', error);
        alert(`Error: ${error.message}`);
    } finally {
        resetAllBlocks(arduinoBlocks, armBlocks, carBlocks, spiderBlocks, sensorBlock, actuatorBlock, stemEngine);
    }
}

async function flashESP32(port, firmwareBuffer) {
    try {
        const loader = new ESPLoader(
            { port: port },
            { 
                baudrate: 921600,
                debug: true,
            }
        );

        console.log(`Serial port ${port} Connecting....`);
        await loader.connect();

        console.log("Uploading stub...");
        await loader.run_stub();
        console.log("Stub running...");

        console.log("Changing baud rate to 921600");
        console.log("Changed.");

        console.log("Configuring flash size...");

        // Application partition data
        const applicationData = firmwareBuffer.slice(36160);

        // Calculate proportional progress steps
        const progressAddresses = [
            { percent: 10, address: 0x10000 },
            { percent: 20, address: 0x1b7b3 },
            { percent: 30, address: 0x27708 },
            { percent: 40, address: 0x2cc92 },
            { percent: 50, address: 0x323e0 },
            { percent: 60, address: 0x37bd3 },
            { percent: 70, address: 0x3d0cd },
            { percent: 80, address: 0x42402 },
            { percent: 90, address: 0x478a9 },
            { percent: 100, address: 0x502b1 }
        ];

        // Calculate slice sizes based on total application data length
        const totalLength = applicationData.length;
        
        for (let i = 0; i < progressAddresses.length; i++) {
            const currentStep = progressAddresses[i];
            const nextStep = progressAddresses[i + 1];
            
            // Calculate start and end positions for slicing
            const startPos = Math.floor((currentStep.percent / 100) * totalLength);
            const endPos = nextStep 
                ? Math.floor((nextStep.percent / 100) * totalLength) 
                : totalLength;
            
            const dataToWrite = applicationData.slice(startPos, endPos);

            console.log(`Writing at 0x${currentStep.address.toString(16).padStart(8, '0')}... (${currentStep.percent} %)`);

            await loader.write_flash({
                flashSize: "4MB",
                flashMode: "QIO",
                flashFreq: "80m",
                eraseAll: false,
                compress: true,
                fileArray: [{ 
                    data: dataToWrite, 
                    address: currentStep.address 
                }],
            });
        }

        await loader.hard_reset();
        console.log("Hard resetting via RTS pin...");

        alert("ESP32 flashing completed successfully!");
    } catch (error) {
        console.error("Error during ESP32 flashing:", error);
        alert(`Flashing failed: ${error.message}`);
    }
}

async function flashArduinoUno(firmwareBuffer) {
    console.log("Flashing Arduino Uno...");
    const avrgirl = new Avrgirl({ board: 'uno' });

    await new Promise((resolve, reject) => {
        avrgirl.flash(firmwareBuffer, (error) => {
            if (error) {
                console.error('Error flashing Arduino board:', error);
                reject(error);
            } else {
                console.log('Code successfully burned to the Arduino board');
                alert('Arduino Uno flashing completed successfully');
                resolve();
            }
        });
    });
}

function resetAllBlocks(...blocks) {
    blocks.forEach(block => block.full_reset && block.full_reset());
    codeGeneration.resetCode();
    console.log('Code has been reset');
}

export default generateAndUploadCode;
