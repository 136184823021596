import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../ui/card"
import { Button } from "../../ui/button"
import { ChevronRight, ChevronLeft, X } from "lucide-react"
import closeIcon from '../../../components/cards/icon--close.svg'
import designIcon from '../design/designIcon.png'
import designPdf from '../design/design.pdf'



export default function appdev({ isOpen, onClose }) {
  const [currentStep, setCurrentStep] = useState(1)
  const [zoomedImage, setZoomedImage] = useState(null)
  const [currentProject, setCurrentProject] = useState("Life Cycle of Frog")

  if (!isOpen) return null

  const projects = [
    {
      name: "App development",
      steps: [
        {
          title: "Open Mit app platform and sign in",
          content: [
           
            {
              text: "Enter your credentials to log in to your account. If you don't have an account, you'll need to create one before proceeding. Look for a 'Sign Up' or 'Create Account' option.",
              image: null
            }
          ]
        },
       
        
       
       
       
       
      ]
    },
    
  ]

  const handleProjectChange = (projectName) => {
    setCurrentProject(projectName)
    setCurrentStep(1)  // Reset to the first step when changing projects
  }

  const renderSteps = (steps) => {
    return (
      <div className="space-y-8 max-w-3xl mx-auto">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            {steps.map((step, index) => (
              <div key={index} className={currentStep === index + 1 ? "" : "hidden"}>
                <h3 className="text-2xl font-bold mb-4 text-center text-emerald-800">{step.title}</h3>
                {step.content.map((content, contentIndex) => (
                  <motion.div
                    key={contentIndex}
                    className="mb-6"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: contentIndex * 0.2 }}
                  >
                    <p className="text-lg mb-4 text-gray-800">{content.text}</p>
                    {content.image&&<div className="relative">
                      <img
                        src={content.image}
                        width={600}
                        height={300}
                        alt="AR and VR concept illustration"
                        className="rounded-lg mx-auto my-6 cursor-pointer transition-transform hover:scale-105 border-2 border-emerald-500 shadow-md"

                      />
                      <div className="absolute inset-0 bg-emerald-100 bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity rounded-lg w-[600px] h-[315px] mx-auto"
                        onClick={() => setZoomedImage(content.image)}>
                        <span className="text-emerald-800 text-lg font-bold">Click to zoom</span>
                      </div>
                    </div>}
                  </motion.div>
                ))}
                <div className="flex justify-between items-center mt-6">
                  <Button
                    variant="outline"
                    onClick={() => setCurrentStep(prev => Math.max(1, prev - 1))}
                    disabled={currentStep === 1}
                    className="bg-emerald-200 text-emerald-800 hover:bg-emerald-300 border-emerald-500"
                  >
                    <ChevronLeft className="mr-2 h-4 w-4" /> Previous
                  </Button>
                  <span className="text-sm text-emerald-800">Step {currentStep} of {steps.length}</span>
                  <Button
                    variant="outline"
                    onClick={() => setCurrentStep(prev => Math.min(steps.length, prev + 1))}
                    disabled={currentStep === steps.length}
                    className="bg-emerald-200 text-emerald-800 hover:bg-emerald-300 border-emerald-500"
                  >
                    Next <ChevronRight className="ml-2 h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    )
  }

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gradient-to-br from-emerald-100 via-teal-50 to-cyan-100">
      <div className="bg-gradient-to-br from-emerald-100 via-teal-50 to-cyan-100 text-gray-900 p-4 rounded-lg shadow-xl w-full mx-auto">
        <button
          onClick={onClose}
          className="fixed right-4 top-4 bg-indigo-500 text-white px-3 py-1 rounded-full hover:bg-indigo-600 transition-colors z-50"
        >
          <img
            src={closeIcon}
            alt="Close"
          />
        </button>
        <div className="container mx-auto">
          <motion.h1
            className="text-4xl font-bold mb-6 text-center text-emerald-800"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            3D printing Educational Projects Guide
          </motion.h1>

          <Tabs defaultValue="intro" className="space-y-4">
            <TabsList className="justify-center bg-white bg-opacity-70 rounded-lg p-1 shadow-md">
              <TabsTrigger value="intro" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Introduction</TabsTrigger>
              {/* <TabsTrigger value="setup" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Setup Guide</TabsTrigger> */}
              <TabsTrigger value="applications" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Applications</TabsTrigger>
              <TabsTrigger value="tips" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Tips</TabsTrigger>
              <TabsTrigger value="projects" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Projects</TabsTrigger>
              <TabsTrigger value="Document" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Document</TabsTrigger>
            </TabsList>

            <TabsContent value="intro">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-center text-emerald-800 text-3xl">Introduction to 3D Printing</CardTitle>
                </CardHeader>
                <CardContent className="max-w-3xl mx-auto space-y-6">
                  <motion.p
                    className="text-lg text-gray-800"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                  App development is the process of creating software applications that run on mobile devices, desktops, or web platforms. It involves planning, designing, coding, testing, and maintaining an app to meet the needs of users. The process typically begins with an idea or problem to solve, followed by the development of a user-friendly interface and the logic that powers the app's functionality.. 
                  </motion.p>
                  <motion.div
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 0.4 }}
                    className="relative"
                  >
                    <img
                      src={designIcon}
                      width={600}
                      height={300}
                      alt="AR and VR concept illustration"
                      className="rounded-lg mx-auto my-6 cursor-pointer transition-transform hover:scale-105 border-2 border-emerald-500 shadow-md"

                    />
                    <div className="absolute inset-0 bg-emerald-100 bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity rounded-lg w-[600px] h-[315px] mx-auto"
                      onClick={() => setZoomedImage(designIcon)}>
                      <span className="text-emerald-800 text-lg font-bold">Click to zoom</span>
                    </div>
                  </motion.div>
                 
                </CardContent>
              </Card>
            </TabsContent>

            

            <TabsContent value="applications">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-emerald-800 text-3xl">Fun Ways to Use 3D design in Learning</CardTitle>
                </CardHeader>
                <CardContent>
                  <ul className="list-disc list-inside space-y-2 text-gray-800">
                    <li><strong className="text-emerald-700"> Architecture and Construction:</strong> Architects use 3D printing to create detailed scale models of buildings, landscapes, and urban designs. This allows for better visualization and presentation of projects.
                    In construction, 3D printing technology is advancing toward creating houses and structures, allowing for more affordable and sustainable building methods.</li>
                    <li><strong className="text-emerald-700">Art and Fashion:</strong> Artists and designers use 3D printing to create custom sculptures, jewelry, and clothing, exploring new forms of creative expression.</li>
                    <li><strong className="text-emerald-700"> Prototyping and Product Development:</strong>3D printing is widely used in industries for rapid prototyping. It allows companies to create models, test designs, and make adjustments quickly without the need for traditional manufacturing.</li>
                    <li><strong className="text-emerald-700">Medical and Healthcare:</strong> 3D printing has transformative applications in healthcare, such as creating custom prosthetics, implants, and surgical tools that are tailored to individual patients.
                    Bioprinting is emerging as a way to create tissue structures like skin, cartilage, and potentially organs in the future.</li>
                    <li><strong className="text-emerald-700">STEM Education:</strong> Explore cool science and math concepts in 3D!</li>
                  </ul>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="tips">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-emerald-800 text-3xl">Tips for Parents and Teachers</CardTitle>
                </CardHeader>
                <CardContent>
                  <ul className="list-disc list-inside space-y-2 text-gray-800">
                    <li><strong className="text-emerald-700">Choose the Right Material:</strong> PLA is the most beginner-friendly filament, ideal for simple projects as it is easy to print and non-toxic.
ABS and PETG are stronger but require more control over temperature and environment.
Select the filament based on the strength, flexibility, and temperature resistance required for your project.</li>
                    <li><strong className="text-emerald-700">Optimize Your 3D Model:</strong> Ensure your 3D model is watertight (no gaps between surfaces) and properly scaled.
Minimize overhangs to reduce the need for supports; try to keep angles below 45°.
Hollow out large parts to save material and printing time, adding support structures inside if needed.</li>
                    <li><strong className="text-emerald-700">Check Bed Leveling and Adhesion</strong> A well-leveled print bed is essential for ensuring the first layer sticks well to the bed. Use a piece of paper to check that the nozzle lightly scrapes the paper at all points.
                    Use adhesives like glue sticks, painter’s tape, or specific print bed surfaces (e.g., PEI sheets) to improve adhesion and prevent warping.</li>
                    <li><strong className="text-emerald-700">Use the Right Print Settings:</strong> Adjust the layer height for detail and speed (0.1mm for high detail, 0.2mm for faster prints).
Set the right infill percentage based on the strength needed (20-30% for most projects, higher for stronger parts).
Use supports sparingly, only where necessary to reduce post-processing work.</li>
                  </ul>
                  <p className="mt-4 text-gray-800">Always monitor the first few layers closely to ensure the print is sticking to the bed and proceeding smoothly.
                  Pause the print if something looks off, like improper extrusion or layers shifting. This prevents wasting filament and time.</p>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="projects">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-center text-emerald-800 text-3xl">3D Printing Projects</CardTitle>
                  <CardDescription className="text-center text-emerald-700">Step-by-step guides for creating cool 3D educational projects</CardDescription>
                </CardHeader>
                <CardContent>
                  <Tabs defaultValue={projects[0].name} onValueChange={handleProjectChange}>
                    <TabsList className="justify-center bg-emerald-100 rounded-lg p-1">
                      {projects.map((project, index) => (
                        <TabsTrigger key={index} value={project.name} className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">
                          {project.name}
                        </TabsTrigger>
                      ))}
                    </TabsList>
                    {projects.map((project, index) => (
                      <TabsContent key={index} value={project.name}>
                        {renderSteps(project.steps)}
                      </TabsContent>
                    ))}
                  </Tabs>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="Document">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-emerald-800 text-3xl">Document for TinkerCAD projects</CardTitle>
                </CardHeader>
                <CardContent>
                  <iframe src={designPdf} width='100%' height='680px' />
                </CardContent>
              </Card>
            </TabsContent>

          </Tabs>

          {zoomedImage && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[60]">
              {console.log(zoomedImage)}
              <div className="relative max-w-4xl max-h-[90vh]">
                <img
                  src={zoomedImage}
                  width={1200}
                  height={600}
                  alt="Zoomed image"
                  className="rounded-lg border-4 border-emerald-500 shadow-xl max-w-full max-h-full object-contain"
                />
                <Button
                  className="absolute top-2 right-2 bg-emerald-700 hover:bg-emerald-800 text-white"
                  onClick={() => setZoomedImage(null)}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}