const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const codeGeneration = require('../codeGeneration');
let setupCode = '';
let loopCode = '';
let defineCode = '';
let bodycode = '';
let codeContext = 'setup';
const definedFunctions = new Set();
const definedServos = new Set();

class Scratch3RoboticSpiderBlocks {
  constructor(runtime) {
    this.runtime = runtime;
    codeContext = localStorage.getItem('codeContext');
  }

  getInfo() {
    return {
      id: 'roboticspider',
      name: formatMessage({
        id: 'roboticspider.categoryName',
        default: 'Robotic Spider',
        description: 'Extension to control a robotic spider based on Arduino.'
      }),
      blocks: [
        {
          opcode: 'setServo',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticspider.setServo',
            default: 'Set servo [servo] to pin number [pinNo]',
            description: 'Attach a servo to the specified pin.'
          }),
          arguments: {
            servo: {
              type: ArgumentType.STRING,
              menu: 'servoMenu',
              defaultValue: 'servo1'
            },
            pinNo: {
              type: ArgumentType.STRING,
              menu: 'pinMenu',
              defaultValue: '2'
            }
          },
          func: 'setServo'
        },
        {
          opcode: 'moveleg',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticspider.moveleg',
            default: 'Move coxa by [degree1] fimure by [degree2] tibia by [degree3] of [leg_num]',
            description: 'Attach a servo to the specified pin.'
          }),
          arguments: {
            degree1: {
              type: ArgumentType.ANGLE,
              defaultValue: 0
            },
            degree2: {
              type: ArgumentType.ANGLE,
              defaultValue: 0
            },
            degree3: {
              type: ArgumentType.ANGLE,
              defaultValue: 0
            },
            leg_num: {
              type: ArgumentType.STRING,
              menu: 'legnumMenu',
              defaultValue: 'leg 1'
            }
          },
        },
        {
          opcode: 'moveAxis',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticspider.moveAxis',
            default: 'Move spider in [axis] axis',
            description: 'Move the axis by the specified degree.'
          }),
          arguments: {
            axis: {
              type: ArgumentType.STRING,
              menu: 'axisMenu',
              defaultValue: 'X'
            }
          },
        },
        {
          opcode: 'moveForwardBackward',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticspider.moveForwardBackward',
            default: 'Move spider [direction] by [steps] steps',
            description: 'Move the spider forward.'
          }),
          arguments: {
            steps: {
              type: ArgumentType.STRING,
              defaultValue: 1
            },
            direction: {
              type: ArgumentType.STRING,
              menu: 'directionMenu',
              defaultValue: 'forward'
            }
          },
        },
        {
          opcode: 'moveLeftRight',
          blockType: BlockType.COMMAND,
          text: formatMessage({
            id: 'roboticspider.moveLeftRight',
            default: 'Move spider [side] by [steps] steps',
            description: 'Move the spider left or right.'
          }),
          arguments: {
            steps: {
              type: ArgumentType.STRING,
              defaultValue: 1
            },
            side: {
              type: ArgumentType.STRING,
              menu: 'sideMenu',
              defaultValue: 'left'
            }
          },
        },
      ],
      menus: {
        servoMenu: {
          items: Array.from({ length: 12 }, (_, i) => `servo${i + 1}`)
        },
        pinMenu: {
          items: ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']
        },
        legnumMenu: {
          items: ['leg 1','leg 2','leg 3','leg 4']
        },
        axisMenu: {
          items: ['X','Y','Z']
        },
        directionMenu: {
          items: ['forward','backward']
        },
        sideMenu: {
          items: ['left','right']
        }
      }
    };
  }


  resetCode() {
    setupCode = '';
    loopCode = '';
    defineCode = '';
    bodycode = '';
    definedServos.clear();
    definedFunctions.clear();
    codeGeneration.resetCode();
    console.log('Code has been reset.');
  }

  accumulateCode() {
    codeGeneration.accumulateCode(defineCode, bodycode, setupCode, loopCode);
  }

  setServo(args) {
    const servo = args.servo;
    const pinNo = args.pinNo;
    const servoVar = `servo_${servo.split('servo')[1]}`;

    // Add the servo variable initialization to the define code if not already present
    if (!definedServos.has(servoVar)) {
      defineCode += `Servo ${servoVar};\n`;
      definedServos.add(servoVar);
    }

    // Add the servo attachment to the setup code
    setupCode += `${servoVar}.attach(${pinNo});\n`;
  }
  moveleg(args) {
    
  }
  moveAxis(args) {
    const axis = args.axis;
    console.log(axis);
  }
  moveForwardBackward(args) {
    const steps = args.steps;
    const direction = args.direction;
    console.log(steps, direction);
  }
  moveLeftRight(args) {
    const steps = args.steps;
    const side = args.side;
    console.log(steps, side);
  }
}

module.exports = Scratch3RoboticSpiderBlocks;