const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const Cast = require('../../util/cast');

class CoordinateGeometryExtension {
    constructor(runtime) {
        this.runtime = runtime;
        this.canvas = null;
        this.context = null;
        this.coefficients = { A: 1, B: 1, C: 0 }; // Default coefficients
        this.points = []; // Initialize the points array
        this.pointsArray = [];  // Array to hold all plotted points
        }

    getInfo() {
        return {
            id: 'coordinateGeometry',
            name: 'Co-ordinate Geometry',
            blocks: [
                {
                    opcode: 'drawCoordinateSystem',
                    blockType: BlockType.COMMAND,
                    text: 'Draw Coordinate System',
                },
                {
                    opcode: 'drawPoints',
                    blockType: BlockType.COMMAND,
                    text: 'Draw Point at x: [x] y: [y]',
                    arguments: {
                        x: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        },
                        y: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        }
                    }
                },
                {
                    opcode: 'clearPoints',
                    blockType: BlockType.COMMAND,
                    text: 'Clear All Points',
                },                
                // {
                //     opcode: 'drawShape',
                //     blockType: BlockType.COMMAND,
                //     text: 'Create Shape from Points',
                // },
                {
                    opcode: 'drawShapeOnCoordinateSystem',  // Block identifier
                    blockType: BlockType.COMMAND,  // Command block
                    text: 'Draw [shape] on the Coordinate System',  // Display text for the block
                    arguments: {
                        shape: {
                            type: ArgumentType.STRING,  // The type is string for the shape
                            menu: 'shapeMenu',  // Reference to the dropdown menu
                            defaultValue: 'none',  // Default shape is a circle
                        },
                    },
                },                           
                {
                    opcode: 'setEquation',
                    blockType: BlockType.COMMAND,
                    text: 'Set A: [A], B: [B], C: [C]',
                    arguments: {
                        A: { type: ArgumentType.NUMBER, defaultValue: 1 },
                        B: { type: ArgumentType.NUMBER, defaultValue: 1 },
                        C: { type: ArgumentType.NUMBER, defaultValue: 0 },
                    },
                },
                {
                    opcode: 'displayEquation',
                    blockType: BlockType.COMMAND,
                    text: 'Display Equation',
                },
                {
                    opcode: 'storeVariableValue',
                    blockType: BlockType.COMMAND,
                    text: 'Display the Coordinates [variable] value: [value]',
                    arguments: {
                        variable: {
                            type: ArgumentType.STRING,
                            menu: 'variableMenu',
                            defaultValue: 'x',
                        },
                        value: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1,
                        }
                    }
                },
                {
                    opcode: 'plotCalculatedPoints',
                    blockType: BlockType.COMMAND,
                    text: 'Plot calculated points',
                    arguments: {}
                },
                {
                    opcode: 'plotEquation',
                    blockType: BlockType.COMMAND,
                    text: 'Plot Equation',
                },
                {
                    opcode: 'giveValuesOf',
                    blockType: BlockType.COMMAND,
                    text: 'Give values of ([x1], [y1]) and ([x2], [y2])',
                    arguments: {
                        x1: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        },
                        y1: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 0
                        },
                        x2: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1
                        },
                        y2: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1
                        }
                    }
                },
                {
                    opcode: 'calculateSlope',
                    blockType: BlockType.COMMAND,
                    text: 'Calculate slope of points',
                    arguments: {}
                },
                {
                    opcode: 'calculateC',
                    blockType: BlockType.COMMAND,
                    text: 'Calculate y-intercept (C) of points',
                    arguments: {},
                },
                {
                    opcode: 'calculateDistance',
                    blockType: BlockType.COMMAND,
                    text: 'Calculate Distance',
                },
                {
                    opcode: 'showEquation',
                    blockType: BlockType.COMMAND,
                    text: 'showEquation Y=MX+C',
                },
                {
                    opcode: 'plotLine',
                    blockType: BlockType.COMMAND,
                    text: 'Plot Line Y=MX + C',
                    arguments: {}
                },
                {
                    opcode: 'clearCanvas',
                    blockType: BlockType.COMMAND,
                    text: 'clearCanvas',
                },
               
            ],
            menus: {
                variableMenu: {
                    acceptReporters: true,
                    items: ['x', 'y'],  // Options in the dropdown
                },
                shapeMenu: {
                    acceptReporters: true,
                    items: [
                        { text: 'None', value: 'none' },  // "None" option to erase the shape
                        { text: 'Circle', value: 'circle' },
                        { text: 'Square', value: 'square' },
                        { text: 'Rectangle', value: 'rectangle' },
                        { text: 'Triangle', value: 'triangle' },
                        { text: 'Line', value: 'line' },
                        { text: 'Pentagon', value: 'pentagon' },
                        { text: 'Hexagon', value: 'hexagon' },
                        { text: 'Heptagon', value: 'heptagon' },
                        { text: 'Octagon', value: 'octagon' },
                        { text: 'Nonagon', value: 'nonagon' },
                        // Add more shapes if needed
                    ],
                }
            }
            
        };
    }

    // Initialize or retrieve the canvas
    _initializeCanvas() {
        const canvas = document.getElementById('coordinateCanvas');
        if (!this.canvas) {
            //  Create a new canvas
            this.canvas = document.createElement('canvas');
            this.canvas.width = 480; // Match Scratch stage width
            this.canvas.height = 360; // Match Scratch stage height
            this.canvas.style.position = 'absolute';
            this.canvas.style.top = '90px'; // Position it 10px from the top
            this.canvas.style.right = '10px'; // Position it 10px from the right
            this.canvas.style.left = 'auto';
            this.canvas.style.pointerEvents = 'none'; // Allow interactions with Scratch UI
            this.canvas.style.zIndex = '10'; // Ensure it overlays above the stage
            this.canvas.style.border = '1px solid black';

            document.body.appendChild(this.canvas);
            this.context = this.canvas.getContext('2d');
        }
        return this.canvas;
    }

 // Draw the coordinate system
_drawCoordinateSystem() {
    const canvas = this._initializeCanvas();
    const ctx = canvas.getContext('2d');

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const width = this.canvas.width;
    const height = this.canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const gridSize = 20;

    // Draw the grid
    ctx.strokeStyle = '#cccccc';
    ctx.lineWidth = 0.5;
    for (let x = 0; x <= width; x += gridSize) {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
        ctx.stroke();
    }
    for (let y = 0; y <= height; y += gridSize) {
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(width, y);
        ctx.stroke();
    }

    // Draw the axes
    ctx.strokeStyle = '#800080';
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(centerX, 0);
    ctx.lineTo(centerX, height);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(0, centerY);
    ctx.lineTo(width, centerY);
    ctx.stroke();

    // Label the axes
    ctx.fillStyle = '#800080';
    ctx.font = '12px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // X-axis numbers (positive and negative)
    for (let x = centerX + gridSize; x <= width; x += gridSize) {
        const label = (x - centerX) / gridSize;
        ctx.fillText(label, x, centerY + 12);
    }
    for (let x = centerX - gridSize; x >= 0; x -= gridSize) {
        const label = (x - centerX) / gridSize;
        ctx.fillText(label, x, centerY + 12);
    }

    // Y-axis numbers (positive and negative)
    for (let y = centerY - gridSize; y >= 0; y -= gridSize) {
        const label = (centerY - y) / gridSize;
        ctx.fillText(label, centerX - 12, y);
    }
    for (let y = centerY + gridSize; y <= height; y += gridSize) {
        const label = (centerY - y) / gridSize;
        ctx.fillText(label, centerX - 12, y);
    }

    // Label origin
    ctx.fillText('0', centerX - 12, centerY + 12);

    // Label "X" axis above the positive X-axis
    ctx.fillText("X", width - 15, centerY - 12);  // Positioned above positive X-axis

    // Label "X" axis below the negative X-axis
    ctx.fillText("X'", 15, centerY - 12);  // Positioned below negative X-axis

    // Label "Y" axis to the right of the positive Y-axis
    ctx.fillText("Y", centerX + 12, 15);  // Positioned right of positive Y-axis

    // Label "Y" axis to the left of the negative Y-axis
    ctx.fillText("Y'", centerX + 12, height - 15);  // Positioned left of negative Y-axis
}

// Block to draw the coordinate system
drawCoordinateSystem() {
    this._drawCoordinateSystem();
}
    // Block to set the coefficients A, B, C
    setEquation(args) {
        this.coefficients.A = Cast.toNumber(args.A);
        this.coefficients.B = Cast.toNumber(args.B);
        this.coefficients.C = Cast.toNumber(args.C);

         // Clear previous points when setting a new equation
    this.points = [];
    }
    // Function to plot the equation on the existing coordinate system
_plotEquation() {
    const canvas = this._initializeCanvas();
    if (!canvas) return;

    const ctx = this.context;
    const { A, B, C } = this.coefficients;

    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const gridSize = 20;

    // Optionally, clear only the equation display area (top-right corner)
    const textHeight = 20; // Height for a single line of text
    const textWidth = 150; // Width of the equation area
    ctx.clearRect(width - textWidth - 10, 0, textWidth, textHeight + 5); // Clear the equation region only

    // Call the existing function to draw the coordinate system
    this._drawCoordinateSystem(ctx, width, height, centerX, centerY, gridSize);

    // Plot the equation line
    ctx.strokeStyle = '#ff0000'; // Red color for the equation
    ctx.lineWidth = 2;

    if (B !== 0) {
        // Calculate two points on the line
        const x1 = -centerX / gridSize; // Left-most X on the grid
        const y1 = -(A * x1 + C) / B;
        const x2 = centerX / gridSize; // Right-most X on the grid
        const y2 = -(A * x2 + C) / B;

        ctx.beginPath();
        ctx.moveTo(centerX + x1 * gridSize, centerY - y1 * gridSize);
        ctx.lineTo(centerX + x2 * gridSize, centerY - y2 * gridSize);
        ctx.stroke();
    } else if (A !== 0) {
        // Vertical line when B = 0
        const x = -C / A;
        ctx.beginPath();
        ctx.moveTo(centerX + x * gridSize, 0);
        ctx.lineTo(centerX + x * gridSize, height);
        ctx.stroke();
    }

    // Set font and style for text
    ctx.font = '14px Arial'; // Smaller font size
    ctx.textAlign = 'right';
    ctx.fillStyle = '#FF0000'; // Red color for the equation text

    // Format the coefficients correctly
    const formatCoefficient = (coef, isFirst) => {
        if (coef === 0) return '';
        const sign = coef > 0 ? (isFirst ? '' : '+') : '-';
        return `${sign} ${Math.abs(coef)}`;
    };

    // Format the equation string
    let equationText = `${formatCoefficient(A, true)}X ${formatCoefficient(B, false)}Y ${formatCoefficient(C, false)} = 0`;

    // Draw the equation text
    ctx.fillText(`Equation: ${equationText}`, width - 10, 20);

    // After plotting the equation, call the function to display coordinates and points
    this._displayCoordinates(); // Display coordinates in green (or your chosen color)
    this.plotCalculatedPoints(); // If you have a function to display plotted points, call it here
}

// Block to plot the equation
plotEquation() {
    this._plotEquation();
}
   // Block to display the equation as text on the canvas
displayEquation() {
    const canvas = this._initializeCanvas();
    if (!canvas) return;

    const ctx = this.context;
    const { A, B, C } = this.coefficients;

    // No need to clear a background region since we are only drawing text directly on the grid.
    // Set font and style for text
    ctx.font = '14px Arial'; // Smaller font size
    ctx.textAlign = 'right';
    ctx.fillStyle = '#FF0000'; // Red color for the equation text

    // Format the equation: Handle + and - signs appropriately
    const formatCoefficient = (coef) => {
        if (coef === 0) return '';
        return coef > 0 ? `+ ${coef}` : `- ${Math.abs(coef)}`;
    };

    const AText = formatCoefficient(A);
    const BText = formatCoefficient(B);
    const CText = formatCoefficient(C);

    // Remove the leading "+" for the first coefficient if A or B is positive
    const equationText = `${AText}X ${BText}Y ${CText} = 0`.replace(/^(\+|\s)/, '');

    // Draw the equation at the top-right corner of the canvas
    ctx.fillText(`Equation: ${equationText}`, canvas.width - 10, 20); // Position near the top-right corner
}
// Function to store the calculated variable values
storeVariableValue(args) {
    const { variable, value } = args;

    // Parse the input value as a number
    const inputValue = Number(value);

    // Calculate the other variable based on the selected one
    let calculatedX = null;
    let calculatedY = null;

    // Check if the coefficient values exist
    if (this.coefficients.A && this.coefficients.B) {
        if (variable === 'x') {
            calculatedX = inputValue;
            calculatedY = -(this.coefficients.A * calculatedX + this.coefficients.C) / this.coefficients.B;
        } else if (variable === 'y') {
            calculatedY = inputValue;
            calculatedX = -(this.coefficients.B * calculatedY + this.coefficients.C) / this.coefficients.A;
        }

        // Store the point with both input and calculated values
        const point = { input: inputValue, calculated: variable === 'x' ? calculatedY : calculatedX };

        // Ensure that points array is initialized if not already done
        if (!this.points) this.points = [];

        // Add the point to the array, making sure it doesn't already exist
        const duplicate = this.points.some(p => p.input === point.input && p.calculated === point.calculated);
        if (!duplicate) {
            this.points.push(point);
        }

        // Update the displayed coordinates
        this._displayCoordinates();
    }
}

// Function to display coordinates on the canvas
_displayCoordinates() {
    const canvas = this._initializeCanvas();
    if (!canvas) return;

    const ctx = this.context;

    // Set text style
    ctx.font = '14px Arial';
    ctx.textAlign = 'left';

    // Define spacing and layout
    const leftMargin = 10; // Space from the left edge
    const topMargin = 10; // Initial space from the top edge
    const lineHeight = 20; // Height between lines

    // Set the color for all points to green
    const pointColor = '#008000'; // Green color
    ctx.fillStyle = pointColor;

    // Start drawing the coordinates
    let currentTopMargin = topMargin; // Starting vertical position for the first line

    if (this.points && this.points.length > 0) {
        this.points.forEach((point) => {
            // Prepare the text for each point
            const text = `(${point.input.toFixed(2)}, ${point.calculated.toFixed(2)})`;

            // Draw the text in green
            ctx.fillText(text, leftMargin, currentTopMargin);

            // Move down for the next line
            currentTopMargin += lineHeight;
        });
    } else {
        // Display a placeholder if no points are available
        ctx.fillStyle = '#999999'; // Gray color for placeholder
        ctx.fillText("No points stored.", leftMargin, currentTopMargin);
    }
}
plotCalculatedPoints() {
    if (!this.points || this.points.length === 0) {
        console.log("No points to plot.");
        return;
    }

    const canvas = this._initializeCanvas();
    const ctx = this.context;

    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const gridSize = 20;

    this.points.forEach((point) => {
        const { input: x, calculated: y } = point;

        // Convert the (x, y) coordinates to the canvas scale
        const canvasX = centerX + x * gridSize;
        const canvasY = centerY - y * gridSize;

        // Draw horizontal line from the X-axis
        ctx.beginPath();
        ctx.moveTo(centerX, canvasY); // From Y-axis
        ctx.lineTo(canvasX, canvasY); // To the point
        ctx.strokeStyle = '#ff0000'; // Red color
        ctx.stroke();

        // Draw vertical line from the Y-axis
        ctx.beginPath();
        ctx.moveTo(canvasX, centerY); // From X-axis
        ctx.lineTo(canvasX, canvasY); // To the point
        ctx.strokeStyle = '#0000ff'; // Blue color
        ctx.stroke();

        // Plot the point as a circle
        ctx.beginPath();
        ctx.arc(canvasX, canvasY, 5, 0, Math.PI * 2, true);
        ctx.fillStyle = '#00ff00'; // Green color
        ctx.fill();
        ctx.stroke();

        // Display (x, y) values on the axes
        ctx.font = "12px Arial";
        ctx.fillStyle = "#000000"; // Black text
        ctx.fillText(`(${x}, ${y})`, canvasX + 5, canvasY - 5); // Near the point
        // ctx.fillText(`${x}`, canvasX - 10, centerY + 15); // On the X-axis
        // ctx.fillText(`${y}`, centerX - 20, canvasY + 5); // On the Y-axis
    });
}

// Block to store points
giveValuesOf(args) {
    const { x1, y1, x2, y2 } = args;

    // Store the points globally for use in the calculateSlope block
    this.points = [
        { x: x1, y: y1 },
        { x: x2, y: y2 }
    ];

    // Draw the coordinate system and plot the points
    this._drawCoordinateSystem();

    const canvas = this._initializeCanvas();
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const gridSize = 20;

    this.points.forEach((point) => {
        const { x, y } = point;

        // Convert the (x, y) coordinates to the canvas scale
        const canvasX = centerX + x * gridSize;
        const canvasY = centerY - y * gridSize;

         // Draw horizontal line from the X-axis
         ctx.beginPath();
         ctx.moveTo(centerX, canvasY); // From Y-axis
         ctx.lineTo(canvasX, canvasY); // To the point
         ctx.strokeStyle = '#ff0000'; // Red color
         ctx.stroke();
 
         // Draw vertical line from the Y-axis
         ctx.beginPath();
         ctx.moveTo(canvasX, centerY); // From X-axis
         ctx.lineTo(canvasX, canvasY); // To the point
         ctx.strokeStyle = '#0000ff'; // Blue color
         ctx.stroke();
 
         // Plot the point as a circle
         ctx.beginPath();
         ctx.arc(canvasX, canvasY, 5, 0, Math.PI * 2, true);
         ctx.fillStyle = '#00ff00'; // Green color
         ctx.fill();
         ctx.stroke();
 
         // Display (x, y) values on the axes
         ctx.font = "12px Arial";
         ctx.fillStyle = "#000000"; // Black text
         ctx.fillText(`(${x}, ${y})`, canvasX + 5, canvasY - 5); // Near the point
         // ctx.fillText(`${x}`, canvasX - 10, centerY + 15); // On the X-axis
         // ctx.fillText(`${y}`, centerX - 20, canvasY + 5); // On the Y-axis
    });
}
// Block to calculate slope and display it along with points
calculateSlope() {
    if (!this.points || this.points.length !== 2) {
        console.log("Points are not defined correctly.");
        return;
    }

    const { x: x1, y: y1 } = this.points[0];
    const { x: x2, y: y2 } = this.points[1];

    // Check for valid input (to prevent division by zero)
    if (x1 === x2) {
        console.log("Cannot calculate slope. The x-values of the points are the same.");
        return;
    }

    // Calculate the slope
    const slope = (y2 - y1) / (x2 - x1);

    // Draw the coordinate system again to refresh the canvas
    this._drawCoordinateSystem();

    // Plot the points again to ensure they are visible
    const canvas = this._initializeCanvas();
    const ctx = canvas.getContext('2d');
    const width = this.canvas.width;
    const height = this.canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const gridSize = 20;

    // Re-plot the points
    this.points.forEach((point) => {
        const { x, y } = point;

        // Convert the (x, y) coordinates to the canvas scale
        const canvasX = centerX + x * gridSize;
        const canvasY = centerY - y * gridSize;

        // Draw horizontal line from the X-axis
        ctx.beginPath();
        ctx.moveTo(centerX, canvasY); // From Y-axis
        ctx.lineTo(canvasX, canvasY); // To the point
        ctx.strokeStyle = '#ff0000'; // Red color
        ctx.stroke();

        // Draw vertical line from the Y-axis
        ctx.beginPath();
        ctx.moveTo(canvasX, centerY); // From X-axis
        ctx.lineTo(canvasX, canvasY); // To the point
        ctx.strokeStyle = '#0000ff'; // Blue color
        ctx.stroke();

        // Plot the point as a circle
        ctx.beginPath();
        ctx.arc(canvasX, canvasY, 5, 0, Math.PI * 2, true);
        ctx.fillStyle = '#00ff00'; // Green color
        ctx.fill();
        ctx.stroke();

        // Display (x, y) values on the axes
        ctx.font = "12px Arial";
        ctx.fillStyle = "#000000"; // Black text
        ctx.fillText(`(${x}, ${y})`, canvasX + 5, canvasY - 5); // Near the point
        // ctx.fillText(`${x}`, canvasX - 10, centerY + 15); // On the X-axis
        // ctx.fillText(`${y}`, centerX - 20, canvasY + 5); // On the Y-axis
    });

    // Display the slope at the top-left corner
    ctx.fillStyle = '#006400';  // Black color for text
    ctx.font = '14px Arial';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    ctx.fillText(`Slope: ${slope.toFixed(2)}`, 10, 10);  // Adjust the position as needed

    // Return the slope value
    return slope;
}
calculateC() {
    if (!this.points || this.points.length !== 2) {
        console.log("Points are not defined correctly.");
        return;
    }

    const { x: x1, y: y1 } = this.points[0];
    const { x: x2, y: y2 } = this.points[1];

    // Check for valid input (to prevent division by zero in slope calculation)
    if (x1 === x2) {
        console.log("Cannot calculate y-intercept. The x-values of the points are the same.");
        return;
    }

    // Calculate the slope
    const slope = (y2 - y1) / (x2 - x1);

    // Use the slope and one point (x1, y1) to calculate y-intercept: c = y - mx
    const yIntercept = y1 - slope * x1;

    // Refresh the canvas
    this._drawCoordinateSystem();

    // Plot the points again to ensure they are visible
    const canvas = this._initializeCanvas();
    const ctx = canvas.getContext('2d');
    const width = this.canvas.width;
    const height = this.canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const gridSize = 20;

    // Re-plot the points
    this.points.forEach((point) => {
        const { x, y } = point;

        // Convert the (x, y) coordinates to the canvas scale
        const canvasX = centerX + x * gridSize;
        const canvasY = centerY - y * gridSize;

         // Draw horizontal line from the X-axis
         ctx.beginPath();
         ctx.moveTo(centerX, canvasY); // From Y-axis
         ctx.lineTo(canvasX, canvasY); // To the point
         ctx.strokeStyle = '#ff0000'; // Red color
         ctx.stroke();
 
         // Draw vertical line from the Y-axis
         ctx.beginPath();
         ctx.moveTo(canvasX, centerY); // From X-axis
         ctx.lineTo(canvasX, canvasY); // To the point
         ctx.strokeStyle = '#0000ff'; // Blue color
         ctx.stroke();
 
         // Plot the point as a circle
         ctx.beginPath();
         ctx.arc(canvasX, canvasY, 5, 0, Math.PI * 2, true);
         ctx.fillStyle = '#00ff00'; // Green color
         ctx.fill();
         ctx.stroke();
 
         // Display (x, y) values on the axes
         ctx.font = "12px Arial";
         ctx.fillStyle = "#000000"; // Black text
         ctx.fillText(`(${x}, ${y})`, canvasX + 5, canvasY - 5); // Near the point
         // ctx.fillText(`${x}`, canvasX - 10, centerY + 15); // On the X-axis
         // ctx.fillText(`${y}`, centerX - 20, canvasY + 5); // On the Y-axis
    });

    // Display the slope at the top-left corner
    ctx.fillStyle = '#006400';  // Black color for text
    ctx.font = '14px Arial';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    ctx.fillText(`Slope: ${slope.toFixed(2)}`, 10, 10);  // Adjust the position as needed

    // Display the y-intercept below the slope
    ctx.fillStyle = '#006400';  // Black color for text
    ctx.fillText(`Y-Intercept: ${yIntercept.toFixed(2)}`, 10, 30);  // Adjust the position as needed

    // Return the y-intercept value
    return yIntercept;
}
// Function to calculate the distance between two points
calculateDistance() {
    if (!this.points || this.points.length !== 2) {
        console.log("Points are not defined correctly.");
        return;
    }

    const { x: x1, y: y1 } = this.points[0];
    const { x: x2, y: y2 } = this.points[1];

    // Calculate the distance using the distance formula
    const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

     // Calculate the slope
     const slope = (y2 - y1) / (x2 - x1);

     // Use the slope and one point (x1, y1) to calculate y-intercept: c = y - mx
     const yIntercept = y1 - slope * x1;
 

    // Refresh the canvas and redraw coordinate system
    this._drawCoordinateSystem();

    // Plot the points again to ensure they are visible
    const canvas = this._initializeCanvas();
    const ctx = canvas.getContext('2d');
    const width = this.canvas.width;
    const height = this.canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const gridSize = 20;

    // Re-plot the points
    this.points.forEach((point) => {
        const { x, y } = point;

        // Convert the (x, y) coordinates to the canvas scale
        const canvasX = centerX + x * gridSize;
        const canvasY = centerY - y * gridSize;

         // Draw horizontal line from the X-axis
         ctx.beginPath();
         ctx.moveTo(centerX, canvasY); // From Y-axis
         ctx.lineTo(canvasX, canvasY); // To the point
         ctx.strokeStyle = '#ff0000'; // Red color
         ctx.stroke();
 
         // Draw vertical line from the Y-axis
         ctx.beginPath();
         ctx.moveTo(canvasX, centerY); // From X-axis
         ctx.lineTo(canvasX, canvasY); // To the point
         ctx.strokeStyle = '#0000ff'; // Blue color
         ctx.stroke();
 
         // Plot the point as a circle
         ctx.beginPath();
         ctx.arc(canvasX, canvasY, 5, 0, Math.PI * 2, true);
         ctx.fillStyle = '#00ff00'; // Green color
         ctx.fill();
         ctx.stroke();
 
         // Display (x, y) values on the axes
         ctx.font = "12px Arial";
         ctx.fillStyle = "#000000"; // Black text
         ctx.fillText(`(${x}, ${y})`, canvasX + 5, canvasY - 5); // Near the point
         // ctx.fillText(`${x}`, canvasX - 10, centerY + 15); // On the X-axis
         // ctx.fillText(`${y}`, centerX - 20, canvasY + 5); // On the Y-axis
    });

    // Display the slope at the top-left corner
    ctx.fillStyle = '#006400';  // Black color for text
    ctx.font = '14px Arial';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    ctx.fillText(`Slope: ${slope.toFixed(2)}`, 10, 10);  // Adjust the position as needed

    // Display the y-intercept below the slope
    ctx.fillStyle = '#006400';  // Black color for text
    ctx.fillText(`Y-Intercept: ${yIntercept.toFixed(2)}`, 10, 30);  // Adjust the position as needed

    // Display the distance between the points below the y-intercept
    ctx.fillStyle = '#006400';  // Green color for text
    ctx.fillText(`Distance: ${distance.toFixed(2)}`, 10, 50);  // Displaying distance below the y-intercept

    // Return the distance value
    return distance;
}

plotLine() {
    if (!this.points || this.points.length !== 2) {
        console.log("Points are not defined correctly.");
        return;
    }

    const { x: x1, y: y1 } = this.points[0];
    const { x: x2, y: y2 } = this.points[1];

    // Check for valid input (to prevent division by zero in slope calculation)
    if (x1 === x2) {
        console.log("Cannot plot the graph. The x-values of the points are the same.");
        return;
    }

    // Calculate the slope
    const slope = (y2 - y1) / (x2 - x1);

    // Calculate the y-intercept
    const yIntercept = y1 - slope * x1;

     // Calculate the distance using the distance formula
     const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

    // Refresh the canvas
    this._drawCoordinateSystem();

    // Plot the points
    const canvas = this._initializeCanvas();
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const gridSize = 20;

    // Re-plot the points
    this.points.forEach((point) => {
        const { x, y } = point;

        // Convert the (x, y) coordinates to the canvas scale
        const canvasX = centerX + x * gridSize;
        const canvasY = centerY - y * gridSize;

        // Draw horizontal line from the X-axis
        ctx.beginPath();
        ctx.moveTo(centerX, canvasY); // From Y-axis
        ctx.lineTo(canvasX, canvasY); // To the point
        ctx.strokeStyle = '#ff0000'; // Red color
        ctx.stroke();

        // Draw vertical line from the Y-axis
        ctx.beginPath();
        ctx.moveTo(canvasX, centerY); // From X-axis
        ctx.lineTo(canvasX, canvasY); // To the point
        ctx.strokeStyle = '#0000ff'; // Blue color
        ctx.stroke();

        // Plot the point as a circle
        ctx.beginPath();
        ctx.arc(canvasX, canvasY, 5, 0, Math.PI * 2, true);
        ctx.fillStyle = '#00ff00'; // Green color
        ctx.fill();
        ctx.stroke();

        // Display (x, y) values on the axes
        ctx.font = "12px Arial";
        ctx.fillStyle = "#000000"; // Black text
        ctx.fillText(`(${x}, ${y})`, canvasX + 5, canvasY - 5); // Near the point
        // ctx.fillText(`${x}`, canvasX - 10, centerY + 15); // On the X-axis
        // ctx.fillText(`${y}`, centerX - 20, canvasY + 5); // On the Y-axis
    });

    // Calculate the line endpoints (extend to canvas edges)
    const xStart = -centerX / gridSize; // Minimum x on the canvas
    const xEnd = (width - centerX) / gridSize; // Maximum x on the canvas
    const yStart = slope * xStart + yIntercept; // Corresponding y for xStart
    const yEnd = slope * xEnd + yIntercept; // Corresponding y for xEnd

    // Convert graph coordinates to canvas coordinates
    const canvasXStart = centerX + xStart * gridSize;
    const canvasYStart = centerY - yStart * gridSize;
    const canvasXEnd = centerX + xEnd * gridSize;
    const canvasYEnd = centerY - yEnd * gridSize;

    // Plot the line
    ctx.beginPath();
    ctx.moveTo(canvasXStart, canvasYStart);
    ctx.lineTo(canvasXEnd, canvasYEnd);
    ctx.strokeStyle = '#0000ff'; // Blue color for the graph line
    ctx.lineWidth = 2;
    ctx.stroke();

    // Display the slope
    ctx.fillStyle = '#006400';  // Black color for text
    ctx.font = '14px Arial';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';
    ctx.fillText(`Slope: ${slope.toFixed(2)}`, 10, 10); // Top-left corner

    // Display the y-intercept
    ctx.fillStyle = '#006400';  // Black color for text
    ctx.fillText(`Y-Intercept: ${yIntercept.toFixed(2)}`, 10, 30); // Below slope
   
    // Display the distance between the points below the y-intercept
    ctx.fillStyle = '#006400';  // Green color for text
    ctx.fillText(`Distance: ${distance.toFixed(2)}`, 10, 50);  // Displaying distance below the y-intercept

    // Display the equation of the line
    ctx.fillStyle = '#ff0000';  // Black color for text
    const equation = `y = ${slope.toFixed(2)}x + ${yIntercept.toFixed(2)}`;
    ctx.textAlign = 'right';
    ctx.fillText(equation, width - 10, 10); // Top-right corner

    console.log(`Graph plotted: y = ${slope.toFixed(2)}x + ${yIntercept.toFixed(2)}`);
}
// Block to display the equation of the line
showEquation() {
    if (!this.points || this.points.length !== 2) {
        console.log("Points are not defined correctly.");
        return;
    }

    const { x: x1, y: y1 } = this.points[0];
    const { x: x2, y: y2 } = this.points[1];

    // Check for valid input (to prevent division by zero in slope calculation)
    if (x1 === x2) {
        console.log("Cannot calculate the equation. The x-values of the points are the same.");
        return;
    }

    // Calculate the slope
    const slope = (y2 - y1) / (x2 - x1);

    // Use the slope and one point (x1, y1) to calculate y-intercept: c = y - mx
    const yIntercept = y1 - slope * x1;

     // Calculate the distance using the distance formula
     const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

    // Format the equation as y = mx + c
    const slopeFormatted = slope.toFixed(2);
    const yInterceptFormatted = yIntercept.toFixed(2);
    const equation = `y = ${slopeFormatted}x + ${yInterceptFormatted}`;

    // Refresh the canvas
    this._drawCoordinateSystem();

    // Plot the points again to ensure they are visible
    const canvas = this._initializeCanvas();
    const ctx = canvas.getContext('2d');
    const width = this.canvas.width;
    const height = this.canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const gridSize = 20;

    // Re-plot the points
    this.points.forEach((point) => {
        const { x, y } = point;

        // Convert the (x, y) coordinates to the canvas scale
        const canvasX = centerX + x * gridSize;
        const canvasY = centerY - y * gridSize;

        // Draw horizontal line from the X-axis
        ctx.beginPath();
        ctx.moveTo(centerX, canvasY); // From Y-axis
        ctx.lineTo(canvasX, canvasY); // To the point
        ctx.strokeStyle = '#ff0000'; // Red color
        ctx.stroke();

        // Draw vertical line from the Y-axis
        ctx.beginPath();
        ctx.moveTo(canvasX, centerY); // From X-axis
        ctx.lineTo(canvasX, canvasY); // To the point
        ctx.strokeStyle = '#0000ff'; // Blue color
        ctx.stroke();

        // Plot the point as a circle
        ctx.beginPath();
        ctx.arc(canvasX, canvasY, 5, 0, Math.PI * 2, true);
        ctx.fillStyle = '#00ff00'; // Green color
        ctx.fill();
        ctx.stroke();

        // Display (x, y) values on the axes
        ctx.font = "12px Arial";
        ctx.fillStyle = "#000000"; // Black text
        ctx.fillText(`(${x}, ${y})`, canvasX + 5, canvasY - 5); // Near the point
        // ctx.fillText(`${x}`, canvasX - 10, centerY + 15); // On the X-axis
        // ctx.fillText(`${y}`, centerX - 20, canvasY + 5); // On the Y-axis
    });

    // Display the equation on the top-right corner
    ctx.fillStyle = '#ff0000';  // Black color for text
    ctx.font = '14px Arial';
    ctx.textAlign = 'right';
    ctx.textBaseline = 'top';
    ctx.fillText(equation, width - 10, 10);  // Adjust position to the top-right corner

    // Also display the slope and y-intercept below for completeness
    ctx.fillStyle = '#006400';  // Black color for slope and y-intercept
    ctx.textAlign = 'left';
    ctx.fillText(`Slope: ${slopeFormatted}`, 10, 10);       // Top-left corner
    ctx.fillText(`Y-Intercept: ${yInterceptFormatted}`, 10, 30);  // Below slope

    // Display the distance between the points below the y-intercept
    ctx.fillStyle = '#006400';  // Green color for text
    ctx.fillText(`Distance: ${distance.toFixed(2)}`, 10, 50);  // Displaying distance below the y-intercept
}
// Block to store and draw points
drawPoints(args) {
    const { x, y } = args;

    // Initialize points array if not already
    if (!this.points) {
        this.points = [];
    }

    // Remove any existing point that matches the same coordinates
    const existingPointIndex = this.points.findIndex(point => point.x === x && point.y === y);
    if (existingPointIndex !== -1) {
        // Replace the old point with the new one (with updated values)
        this.points[existingPointIndex] = { x, y };
    } else {
        // Add the new point if it doesn't exist
        this.points.push({ x, y });
    }

    // Initialize canvas and context
    const canvas = this._initializeCanvas();
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const gridSize = 20;

    // Clear the canvas before redrawing points
    ctx.clearRect(0, 0, width, height);

    // Draw the coordinate system first (this should be redrawn every time)
    this._drawCoordinateSystem();

    // Redraw all points
    this.points.forEach((point) => {
        const { x, y } = point;

        // Convert the (x, y) coordinates to the canvas scale
        const canvasX = centerX + x * gridSize;
        const canvasY = centerY - y * gridSize;

         // Draw horizontal line from the X-axis
         ctx.beginPath();
         ctx.moveTo(centerX, canvasY); // From Y-axis
         ctx.lineTo(canvasX, canvasY); // To the point
         ctx.strokeStyle = '#ff0000'; // Red color
         ctx.stroke();
 
         // Draw vertical line from the Y-axis
         ctx.beginPath();
         ctx.moveTo(canvasX, centerY); // From X-axis
         ctx.lineTo(canvasX, canvasY); // To the point
         ctx.strokeStyle = '#0000ff'; // Blue color
         ctx.stroke();
 
         // Plot the point as a circle
         ctx.beginPath();
         ctx.arc(canvasX, canvasY, 5, 0, Math.PI * 2, true);
         ctx.fillStyle = '#00ff00'; // Green color
         ctx.fill();
         ctx.stroke();
 
         // Display (x, y) values on the axes
         ctx.font = "12px Arial";
         ctx.fillStyle = "#000000"; // Black text
         ctx.fillText(`(${x}, ${y})`, canvasX + 5, canvasY - 5); // Near the point
        
    });
}
// Block to clear all points
clearPoints() {
    // Reset the points array to empty
    this.points = [];

    // Initialize canvas and context
    const canvas = this._initializeCanvas();
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    // Clear the entire canvas
    ctx.clearRect(0, 0, width, height);

    // Redraw the coordinate system (this will remain fixed)
    this._drawCoordinateSystem();
}
// Block to draw a shape combining all points and ensuring correct order
drawShape(){
    // Ensure we have at least 3 points to form a closed shape
    if (this.points.length < 2) {
        console.error("At least two points are needed to draw a shape.");
        return;
    }

    // Sort the points for shape (square, rectangle, etc.)
    if (this.points.length === 4) {
        // Sort points for a square or rectangle
        this.points = this.sortRectanglePoints(this.points);
    }

    // Initialize canvas and context
    const canvas = this._initializeCanvas();
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const gridSize = 20;

    // Clear the canvas before redrawing
    ctx.clearRect(0, 0, width, height);

    // Redraw the coordinate system
    this._drawCoordinateSystem();

    // Redraw the points
    this.points.forEach((point) => {
        const { x, y } = point;

        // Convert the (x, y) coordinates to the canvas scale
        const canvasX = centerX + x * gridSize;
        const canvasY = centerY - y * gridSize;

        // Plot the point as a circle
        ctx.beginPath();
        ctx.arc(canvasX, canvasY, 5, 0, Math.PI * 2, true);
        ctx.fillStyle = '#00ff00'; // Green color for points
        ctx.fill();
        ctx.stroke();
    });

    // Draw the shape by connecting all points
    ctx.beginPath();

    // Move to the first point
    const firstPoint = this.points[0];
    const firstX = centerX + firstPoint.x * gridSize;
    const firstY = centerY - firstPoint.y * gridSize;
    ctx.moveTo(firstX, firstY);

    // Draw lines connecting all points in order
    this.points.forEach((point, index) => {
        const x = centerX + point.x * gridSize;
        const y = centerY - point.y * gridSize;
        ctx.lineTo(x, y);
    });

    // Close the shape by connecting the last point to the first point
    ctx.closePath();

    // Set the stroke style and draw the shape
    ctx.strokeStyle = '#0000ff';  // Blue color for the shape
    ctx.lineWidth = 2;
    ctx.stroke();

    // Optional: Fill the shape
    ctx.fillStyle = '#ff0000';  // Red color for filling
    ctx.fill();
}

// Function to sort the points into proper order for a rectangle or square
sortRectanglePoints(points) {
    // Sort points based on x and y coordinates to form a rectangle
    points.sort((a, b) => a.x - b.x); // Sort by x-coordinate first
    const leftMost = points[0];  // Point with smallest x-coordinate
    const rightMost = points[3]; // Point with largest x-coordinate

    // Now sort the points with the same x coordinate by y
    points.sort((a, b) => a.y - b.y);

    const bottomMost = points[0]; // Point with smallest y-coordinate
    const topMost = points[3];    // Point with largest y-coordinate

    // Rearrange the points in a clockwise order: bottom-left, bottom-right, top-right, top-left
    return [leftMost, bottomMost, rightMost, topMost];
}
// Block handler for drawing the shape on the coordinate system
drawShapeOnCoordinateSystem(args) {
    const { shape } = args;  // Get the selected shape from the arguments

    const canvas = this._initializeCanvas();
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    const centerX = width / 2;
    const centerY = height / 2;
    const gridSize = 20;  // Size for scaling coordinates

    // Clear the canvas before drawing a new shape
    ctx.clearRect(0, 0, width, height);

    // Draw the coordinate system first (always drawn)
    this._drawCoordinateSystem();

    // If "None" is selected, don't draw anything
    if (shape === 'none') {
        return; // No shape to draw, exit the function
    }
    // Draw the shape based on the selected shape type
    switch(shape) {
        case 'circle':
            this.drawCircle(centerX, centerY, 3 * gridSize, ctx);
            break;
        case 'square':
            this.drawSquare(centerX - 2 * gridSize, centerY - 2 * gridSize, 4 * gridSize, ctx);
            break;
        case 'rectangle':
            this.drawRectangle(centerX - 3 * gridSize, centerY - 1 * gridSize, 6 * gridSize, 2 * gridSize, ctx);
            break;
        case 'triangle':
            this.drawTriangle(centerX - 3 * gridSize, centerY + 3 * gridSize, 6 * gridSize, ctx);
            break;
        case 'line':
            this.drawLine(centerX - 3 * gridSize, centerY - 2 * gridSize, centerX + 3 * gridSize, centerY + 2 * gridSize, ctx);
            break;
        case 'pentagon':
            this.drawPolygon(centerX, centerY, 5, 3 * gridSize, ctx);
            break;
        case 'hexagon':
            this.drawPolygon(centerX, centerY, 6, 3 * gridSize, ctx);
            break;
        case 'heptagon':
            this.drawPolygon(centerX, centerY, 7, 3 * gridSize, ctx);
            break;
        case 'octagon':
            this.drawPolygon(centerX, centerY, 8, 3 * gridSize, ctx);
            break;
        case 'nonagon':
            this.drawPolygon(centerX, centerY, 9, 3 * gridSize, ctx);
            break;
        default:
            console.error("Invalid shape selected");
            break;
    }
}
// Function to draw a circle
drawCircle(centerX, centerY, radius, ctx) {
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
    ctx.fillStyle = 'green';
    ctx.fill();
    ctx.stroke();
}

// Function to draw a square
drawSquare(x, y, size, ctx) {
    ctx.beginPath();
    ctx.rect(x, y, size, size);
    ctx.fillStyle = 'blue';
    ctx.fill();
    ctx.stroke();
}

// Function to draw a rectangle
drawRectangle(x, y, width, height, ctx) {
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.fillStyle = 'red';
    ctx.fill();
    ctx.stroke();
}

// Function to draw a triangle
drawTriangle(x, y, size, ctx) {
    ctx.beginPath();
    ctx.moveTo(x, y);  // Starting point
    ctx.lineTo(x + size, y);  // Top point
    ctx.lineTo(x + size / 2, y - size);  // Bottom point
    ctx.closePath();
    ctx.fillStyle = 'yellow';
    ctx.fill();
    ctx.stroke();
}

// Function to draw a line
drawLine(x1, y1, x2, y2, ctx) {
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.strokeStyle = 'purple';
    ctx.stroke();
}

// Function to draw a polygon (for shapes like pentagon, hexagon, etc.)
drawPolygon(centerX, centerY, sides, radius, ctx) {
    const angle = (2 * Math.PI) / sides;  // Angle between each vertex
    ctx.beginPath();

    for (let i = 0; i < sides; i++) {
        const x = centerX + radius * Math.cos(i * angle);
        const y = centerY + radius * Math.sin(i * angle);
        ctx.lineTo(x, y);
    }

    ctx.closePath();
    ctx.fillStyle = 'orange';
    ctx.fill();
    ctx.stroke();
}
// Clear the canvas
clearCanvas()  {
    const canvas = this._initializeCanvas();
    const ctx = canvas.getContext('2d');

    // Clear the grid and axes by covering the entire canvas with a white background
    ctx.clearRect(0, 0, canvas.width, canvas.height);
}

}
module.exports = CoordinateGeometryExtension;
