import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import VM from 'scratch-vm';
import { injectIntl, intlShape } from 'react-intl';
import ErrorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import { getIsError, getIsShowingProject } from '../reducers/project-state';
import { BLOCKS_TAB_INDEX, COSTUMES_TAB_INDEX, SOUNDS_TAB_INDEX, PYTHON_TAB_INDEX, GAME_TAB_INDEX,activateTab } from '../reducers/editor-tab';
import { closeCostumeLibrary, closeBackdropLibrary, closeTelemetryModal, openExtensionLibrary } from '../reducers/modals';
import FontLoaderHOC from '../lib/font-loader-hoc.jsx';
import LocalizationHOC from '../lib/localization-hoc.jsx';
import SBFileUploaderHOC from '../lib/sb-file-uploader-hoc.jsx';
import ProjectFetcherHOC from '../lib/project-fetcher-hoc.jsx';
import TitledHOC from '../lib/titled-hoc.jsx';
import ProjectSaverHOC from '../lib/project-saver-hoc.jsx';
import QueryParserHOC from '../lib/query-parser-hoc.jsx';
import storage from '../lib/storage';
import vmListenerHOC from '../lib/vm-listener-hoc.jsx';
import vmManagerHOC from '../lib/vm-manager-hoc.jsx';
import cloudManagerHOC from '../lib/cloud-manager-hoc.jsx';
import systemPreferencesHOC from '../lib/system-preferences-hoc.jsx';
import GUIComponent from '../components/gui/gui.jsx';
import { setIsScratchDesktop } from '../lib/isScratchDesktop.js';

const { RequestMetadata, setMetadata, unsetMetadata } = storage.scratchFetch;

const setProjectIdMetadata = projectId => {
  if (projectId && projectId !== '0') {
    setMetadata(RequestMetadata.ProjectId, projectId);
  } else {
    unsetMetadata(RequestMetadata.ProjectId);
  }
};

class GUI extends React.Component {
  componentDidMount() {
    setIsScratchDesktop(this.props.isScratchDesktop);
    this.props.onStorageInit(storage);
    this.props.onVmInit(this.props.vm);
    setProjectIdMetadata(this.props.projectId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.projectId !== prevProps.projectId) {
      if (this.props.projectId !== null) {
        this.props.onUpdateProjectId(this.props.projectId);
      }
      setProjectIdMetadata(this.props.projectId);
    }
    if (this.props.isShowingProject && !prevProps.isShowingProject) {
      this.props.onProjectLoaded();
    }
  }

  render() {
    if (this.props.isError) {
      throw new Error(
        `Error in Scratch GUI [location=${window.location}]: ${this.props.error}`
      );
    }
    const {
      /* eslint-disable no-unused-vars */
      assetHost,
      cloudHost,
      error,
      isError,
      isScratchDesktop,
      isShowingProject,
      onProjectLoaded,
      onStorageInit,
      onUpdateProjectId,
      onVmInit,
      projectHost,
      projectId,
      /* eslint-enable no-unused-vars */
      children,
      fetchingProject,
      isLoading,
      loadingStateVisible,
      ...componentProps
    } = this.props;

    return (
      <GUIComponent
        loading={fetchingProject || isLoading || loadingStateVisible}
        {...componentProps}
      >
        {children}
      </GUIComponent>
    );
  }
}

GUI.propTypes = {
  assetHost: PropTypes.string,
  children: PropTypes.node,
  cloudHost: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fetchingProject: PropTypes.bool,
  intl: intlShape.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isScratchDesktop: PropTypes.bool,
  isShowingProject: PropTypes.bool,
  isTotallyNormal: PropTypes.bool,
  loadingStateVisible: PropTypes.bool,
  onProjectLoaded: PropTypes.func,
  onSeeCommunity: PropTypes.func,
  onStorageInit: PropTypes.func,
  onUpdateProjectId: PropTypes.func,
  onVmInit: PropTypes.func,
  projectHost: PropTypes.string,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  telemetryModalVisible: PropTypes.bool,
  vm: PropTypes.instanceOf(VM).isRequired,
  pythonTabVisible: PropTypes.bool.isRequired,
};

GUI.defaultProps = {
  isScratchDesktop: false,
  isTotallyNormal: false,
  onStorageInit: storageInstance => storageInstance.addOfficialScratchWebStores(),
  onProjectLoaded: () => { },
  onUpdateProjectId: () => { },
  onVmInit: (/* vm */) => { }
};

const mapStateToProps = state => {
  const loadingState = state.scratchGui.projectState.loadingState;
  return {
    activeTabIndex: state.scratchGui.editorTab.activeTabIndex,
    alertsVisible: state.scratchGui.alerts.visible,
    backdropLibraryVisible: state.scratchGui.modals.backdropLibrary,
    blocksTabVisible: state.scratchGui.editorTab.activeTabIndex === BLOCKS_TAB_INDEX,
    cardsVisible: state.scratchGui.cards.visible,
    connectionModalVisible: state.scratchGui.modals.connectionModal,
    costumeLibraryVisible: state.scratchGui.modals.costumeLibrary,
    costumesTabVisible: state.scratchGui.editorTab.activeTabIndex === COSTUMES_TAB_INDEX,
    error: state.scratchGui.projectState.error,
    isError: getIsError(loadingState),
    isFullScreen: state.scratchGui.mode.isFullScreen,
    isPlayerOnly: state.scratchGui.mode.isPlayerOnly,
    isRtl: state.locales.isRtl,
    isShowingProject: getIsShowingProject(loadingState),
    loadingStateVisible: state.scratchGui.modals.loadingProject,
    projectId: state.scratchGui.projectState.projectId,
    soundsTabVisible: state.scratchGui.editorTab.activeTabIndex === SOUNDS_TAB_INDEX,
    pythonTabVisible: state.scratchGui.editorTab.activeTabIndex === PYTHON_TAB_INDEX,
    gameTabVisible: state.scratchGui.editorTab.activeTabIndex === GAME_TAB_INDEX,
    targetIsStage: (
      state.scratchGui.targets.stage &&
      state.scratchGui.targets.stage.id === state.scratchGui.targets.editingTarget
    ),
    telemetryModalVisible: state.scratchGui.modals.telemetryModal,
    tipsLibraryVisible: state.scratchGui.modals.tipsLibrary,
    vm: state.scratchGui.vm
  };
};

const mapDispatchToProps = dispatch => ({
  onExtensionButtonClick: () => dispatch(openExtensionLibrary()),
  onActivateTab: tab => dispatch(activateTab(tab)),
  onActivateCostumesTab: () => dispatch(activateTab(COSTUMES_TAB_INDEX)),
  onActivateSoundsTab: () => dispatch(activateTab(SOUNDS_TAB_INDEX)),
  onActivatePythonTab: () => dispatch(activateTab(PYTHON_TAB_INDEX)),
  onRequestCloseBackdropLibrary: () => dispatch(closeBackdropLibrary()),
  onRequestCloseCostumeLibrary: () => dispatch(closeCostumeLibrary()),
  onRequestCloseTelemetryModal: () => dispatch(closeTelemetryModal())
});

const ConnectedGUI = injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(GUI));

const WrappedGui = compose(
  LocalizationHOC,
  ErrorBoundaryHOC('Top Level App'),
  FontLoaderHOC,
  QueryParserHOC,
  ProjectFetcherHOC,
  TitledHOC,
  ProjectSaverHOC,
  vmListenerHOC,
  vmManagerHOC,
  SBFileUploaderHOC,
  cloudManagerHOC,
  systemPreferencesHOC
)(ConnectedGUI);

WrappedGui.setAppElement = ReactModal.setAppElement;
export default WrappedGui;
