import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../ui/card"
import { Button } from "../../ui/button"
import { ChevronRight, ChevronLeft, X } from "lucide-react"
import closeIcon from '../../../components/cards/icon--close.svg'
import arvrIcon from '../ar-vr_img/ar.jpg'
import appPdf from './app.pdf'
import tower from '../appDev/tower.jpg'
import app1 from '../appDev/app1.jpg'
import code from '../appDev/code.png'

export default function Appdocs({ isOpen, onClose }) {
  const [currentStep, setCurrentStep] = useState(1)
  const [zoomedImage, setZoomedImage] = useState(null)
  const [currentProject, setCurrentProject] = useState("Life Cycle of Frog")

  if (!isOpen) return null

  const projects = [
    {
      name: "Magic",
      steps: [
        {
          title: "Open MIT App Inventor platform and sign in",
          content: [
            {
              text: "The concept of this project is When we click on the button the image will automatically appear",
              image: tower
            },
           
          ]
        },
        {
          title: "Code",
          content: [
            {
              text: "The following is the code to develop the Project",
              image: code
            },
           
          ]
        },
      ]
    }
  ]

  const handleProjectChange = (projectName) => {
    setCurrentProject(projectName)
    setCurrentStep(1)  // Reset to the first step when changing projects
  }

  const renderSteps = (steps) => {
    return (
      <div className="space-y-8 max-w-3xl mx-auto">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            {steps.map((step, index) => (
              <div key={index} className={currentStep === index + 1 ? "" : "hidden"}>
                <h3 className="text-2xl font-bold mb-4 text-center text-emerald-800">{step.title}</h3>
                {step.content.map((content, contentIndex) => (
                  <motion.div
                    key={contentIndex}
                    className="mb-6"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: contentIndex * 0.2 }}
                  >
                    <p className="text-lg mb-4 text-gray-800">{content.text}</p>
                    {content.image&&<div className="relative">
                      <img
                        src={content.image}
                        width={600}
                        height={300}
                        alt="AR and VR concept illustration"
                        className="rounded-lg mx-auto my-6 cursor-pointer transition-transform hover:scale-105 border-2 border-emerald-500 shadow-md"

                      />
                      <div className="absolute inset-0 bg-emerald-100 bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity rounded-lg w-[600px] h-[315px] mx-auto"
                        onClick={() => setZoomedImage(content.image)}>
                        <span className="text-emerald-800 text-lg font-bold">Click to zoom</span>
                      </div>
                    </div>}
                  </motion.div>
                ))}
                <div className="flex justify-between items-center mt-6">
                  <Button
                    variant="outline"
                    onClick={() => setCurrentStep(prev => Math.max(1, prev - 1))}
                    disabled={currentStep === 1}
                    className="bg-emerald-200 text-emerald-800 hover:bg-emerald-300 border-emerald-500"
                  >
                    <ChevronLeft className="mr-2 h-4 w-4" /> Previous
                  </Button>
                  <span className="text-sm text-emerald-800">Step {currentStep} of {steps.length}</span>
                  <Button
                    variant="outline"
                    onClick={() => setCurrentStep(prev => Math.min(steps.length, prev + 1))}
                    disabled={currentStep === steps.length}
                    className="bg-emerald-200 text-emerald-800 hover:bg-emerald-300 border-emerald-500"
                  >
                    Next <ChevronRight className="ml-2 h-4 w-4" />
                  </Button>
                </div>
              </div>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    )
  }

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gradient-to-br from-emerald-100 via-teal-50 to-cyan-100">
      <div className="bg-gradient-to-br from-emerald-100 via-teal-50 to-cyan-100 text-gray-900 p-4 rounded-lg shadow-xl w-full mx-auto">
        <button
          onClick={onClose}
          className="fixed right-4 top-4 bg-indigo-500 text-white px-3 py-1 rounded-full hover:bg-indigo-600 transition-colors z-50"
        >
          <img
            src={closeIcon}
            alt="Close"
          />
        </button>
        <div className="container mx-auto">
          <motion.h1
            className="text-4xl font-bold mb-6 text-center text-emerald-800"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            MIT App Inventor Educational Projects Guide
          </motion.h1>

          <Tabs defaultValue="intro" className="space-y-4">
            <TabsList className="justify-center bg-white bg-opacity-70 rounded-lg p-1 shadow-md">
              <TabsTrigger value="intro" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Introduction</TabsTrigger>
              {/* <TabsTrigger value="setup" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Setup Guide</TabsTrigger> */}
              {/* <TabsTrigger value="applications" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Applications</TabsTrigger> */}
              {/* <TabsTrigger value="tips" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Tips</TabsTrigger> */}
              <TabsTrigger value="projects" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Projects</TabsTrigger>
              <TabsTrigger value="Document" className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">Document</TabsTrigger>
            </TabsList>

            <TabsContent value="intro">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-center text-emerald-800 text-3xl">Introduction to App Development</CardTitle>
                </CardHeader>
                <CardContent className="max-w-3xl mx-auto space-y-6">
                  <motion.p
                    className="text-lg text-gray-800"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                   App development is the process of creating software applications that run on mobile devices, desktops, or web platforms. It involves planning, designing, coding, testing, and maintaining an app to meet the needs of users. The process typically begins with an idea or problem to solve, followed by the development of a user-friendly interface and the logic that powers the app's functionality.
                  </motion.p>
                   <motion.div
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 0.4 }}
                    className="relative"
                  > 
                    <img
                      src={app1}
                      width={600}
                      height={300}
                      alt="AR and VR concept illustration"
                      className="rounded-lg mx-auto my-6 cursor-pointer transition-transform hover:scale-105 border-2 border-emerald-500 shadow-md"

                    />
                    <div className="absolute inset-0 bg-emerald-100 bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity rounded-lg w-[600px] h-[315px] mx-auto"
                      onClick={() => setZoomedImage(arvrIcon)}>
                      <span className="text-emerald-800 text-lg font-bold">Click to zoom</span>
                    </div>
                  </motion.div>
                 
                </CardContent>
              </Card>
            </TabsContent>

           
            <TabsContent value="projects">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-center text-emerald-800 text-3xl">MIT App Projects</CardTitle>
                  <CardDescription className="text-center text-emerald-700">Step-by-step guides for creating cool MIT App Inventor educational projects</CardDescription>
                </CardHeader>
                <CardContent>
                  <Tabs defaultValue={projects[0].name} onValueChange={handleProjectChange}>
                    <TabsList className="justify-center bg-emerald-100 rounded-lg p-1">
                      {projects.map((project, index) => (
                        <TabsTrigger key={index} value={project.name} className="data-[state=active]:bg-emerald-200 data-[state=active]:text-emerald-800">
                          {project.name}
                        </TabsTrigger>
                      ))}
                    </TabsList>
                    {projects.map((project, index) => (
                      <TabsContent key={index} value={project.name}>
                        {renderSteps(project.steps)}
                      </TabsContent>
                    ))}
                  </Tabs>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="Document">
              <Card className="bg-white bg-opacity-90 border-emerald-300 shadow-lg">
                <CardHeader>
                  <CardTitle className="text-emerald-800 text-3xl">Document for MIT App inventor projects</CardTitle>
                </CardHeader>
                <CardContent>
                  <iframe src={appPdf} width='100%' height='680px' />
                </CardContent>
              </Card>
            </TabsContent>

          </Tabs>

          {zoomedImage && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[60]">
              {console.log(zoomedImage)}
              <div className="relative max-w-4xl max-h-[90vh]">
                <img
                  src={zoomedImage}
                  width={1200}
                  height={600}
                  alt="Zoomed image"
                  className="rounded-lg border-4 border-emerald-500 shadow-xl max-w-full max-h-full object-contain"
                />
                <Button
                  className="absolute top-2 right-2 bg-emerald-700 hover:bg-emerald-800 text-white"
                  onClick={() => setZoomedImage(null)}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}