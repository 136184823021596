import React, { useState, useRef, useCallback, useEffect } from 'react'
import Editor from '@monaco-editor/react'
import ReactConsole from 'react-console-emulator'
import { Play, Trash2, GripHorizontal, Sun, MoonStarIcon } from 'lucide-react'
import { Button } from "../../components/ui/button"
import { Label } from "../../components/ui/label"

let suggestionsRegistered = false;

export default function PYTHONTab({ height = "800px", width = "100%", zIndex = 10 }) {
  const [code, setCode] = useState('')
  const [userInput, setUserInput] = useState('')
  const [terminalHeight, setTerminalHeight] = useState(200)
  const [isDragging, setIsDragging] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(true)
  const consoleRef = useRef()
  const containerRef = useRef()
  const dragStartRef = useRef(0)
  const initialHeightRef = useRef(0)
  const editorRef = useRef(null)
  const completionProviderRef = useRef(null)

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;

    // Only register suggestions if they haven't been registered yet
    if (!suggestionsRegistered) {
      const suggestions = [
        {
          label: 'print',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'print(${1})',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Print objects to the text stream',
          documentation: 'Print objects to the text stream file, separated by sep and followed by end.'
        },
        {
          label: 'input',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'input(${1})',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Read a string from standard input',
          documentation: 'Read a string from standard input.'
        },
        {
          label: 'len',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'len(${1})',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Return the length of an object',
          documentation: 'Return the length (the number of items) of an object.'
        },
        {
          label: 'if',
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: 'if ${1:condition}:\n\t${2}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'If statement',
          documentation: 'Execute a block of code if a condition is true.'
        },
        {
          label: 'elif',
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: 'elif ${1:condition}:\n\t${2}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Else If statement',
          documentation: 'Execute a block of code if a condition is true.'
        },
        {
          label: 'else',
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: 'else:\n\t${1}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Else statement',
          documentation: 'Execute a block of code if none of the conditions are true.'
        },
        {
          label: 'for',
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: 'for ${1:item} in ${2:items}:\n\t${3}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'For loop',
          documentation: 'Iterate over a sequence of items.'
        },
        {
          label: 'while',
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: 'while ${1:condition}:\n\t${2}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'While loop',
          documentation: 'Execute statements repeatedly as long as a condition is true.'
        },
        {
          label: 'def',
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: 'def ${1:function_name}(${2:parameters}):\n\t${3:pass}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Function definition',
          documentation: 'Define a new function.'
        },
        {
          label: 'class',
          kind: monaco.languages.CompletionItemKind.Snippet,
          insertText: 'class ${1:ClassName}:\n\tdef __init__(self):\n\t\t${2:pass}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Class definition',
          documentation: 'Define a new class.'
        },
        {
          label: 'import',
          kind: monaco.languages.CompletionItemKind.Keyword,
          insertText: 'import ${1:module}',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          detail: 'Import statement',
          documentation: 'Import a module.'
        }
      ];

      // Register new completion provider only if not already registered
      if (!completionProviderRef.current) {
        completionProviderRef.current = monaco.languages.registerCompletionItemProvider('python', {
          provideCompletionItems: (model, position) => {
            const word = model.getWordUntilPosition(position);
            const range = {
              startLineNumber: position.lineNumber,
              endLineNumber: position.lineNumber,
              startColumn: word.startColumn,
              endColumn: word.endColumn
            };

            return {
              suggestions: suggestions.map(s => ({ ...s, range })),
              incomplete: false
            };
          },
          triggerCharacters: ['.']
        });

        suggestionsRegistered = true;
      }
    }

    // Configure editor settings
    editor.updateOptions({
      snippetSuggestions: 'inline',
      suggestOnTriggerCharacters: true,
      parameterHints: { enabled: true },
      quickSuggestions: { other: true, comments: false, strings: false },
      wordBasedSuggestions: true,
      suggest: {
        localityBonus: true,
        snippetsPreventQuickSuggestions: false,
        showIcons: true,
        showStatusBar: true,
        preview: true,
        filterGraceful: true
      }
    });
  };

  // Cleanup function to dispose of the completion provider
  useEffect(() => {
    return () => {
      if (completionProviderRef.current) {
        completionProviderRef.current.dispose();
        completionProviderRef.current = null;
        suggestionsRegistered = false;
      }
    };
  }, []);

  const handleEditorChange = (value) => {
    setCode(value)
  }

  const handleRunCode = async () => {
    try {
      const response = await fetch('https://emkc.org/api/v2/piston/execute', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          language: 'python',
          version: '3.10.0',
          files: [
            {
              name: 'main.py',
              content: code,
            },
          ],
          stdin: userInput,
        }),
      })

      const result = await response.json()
      const output = result.run?.output || result.run?.stderr || 'No output returned'
      consoleRef.current.pushToStdout(output)
    } catch (error) {
      consoleRef.current.pushToStdout('Error executing code: ' + error.message)
    }
  }

  const handleClearCode = () => {
    setCode('')
    consoleRef.current.clearStdout()
  }

  const handlePythonCommand = async (input) => {
    const command = input.join(' ')
    try {
      const response = await fetch('https://emkc.org/api/v2/piston/execute', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          language: 'python',
          version: '3.10.0',
          files: [
            {
              name: 'main.py',
              content: command,
            },
          ],
        }),
      })
      const result = await response.json()
      const output = result.run?.output || result.run?.stderr || 'No output returned'
      return output
    } catch (error) {
      return 'Error executing command: ' + error.message
    }
  }

  const commands = {
    run: {
      description: 'Run the code in the editor',
      usage: 'run',
      fn: handleRunCode,
    },
    python: {
      description: 'Execute a Python command',
      usage: 'python <command>',
      fn: (...args) => handlePythonCommand(args),
    },
  }

  const handleMouseDown = useCallback((e) => {
    setIsDragging(true)
    dragStartRef.current = e.clientY
    initialHeightRef.current = terminalHeight
    e.preventDefault()
  }, [terminalHeight])

  const handleMouseMove = useCallback((e) => {
    if (!isDragging || !containerRef.current) return

    const containerRect = containerRef.current.getBoundingClientRect()
    const containerHeight = containerRect.height
    const delta = dragStartRef.current - e.clientY
    const newHeight = Math.max(100, Math.min(
      containerHeight - 200,
      initialHeightRef.current + delta
    ))

    requestAnimationFrame(() => {
      setTerminalHeight(newHeight)
    })
  }, [isDragging])

  const handleMouseUp = useCallback(() => {
    setIsDragging(false)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.offsetHeight
        setTerminalHeight(prev => Math.min(prev, containerHeight - 200))
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
      document.body.style.userSelect = 'none'
    } else {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
      document.body.style.userSelect = ''
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
      document.body.style.userSelect = ''
    }
  }, [isDragging, handleMouseMove, handleMouseUp])

  return (
    <div
      ref={containerRef}
      className={`rounded-lg overflow-hidden shadow-lg w-full transition-colors duration-300 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'
        }`}
      style={{
        height,
        width,
        zIndex,
        minHeight: '400px',
        position: 'relative'
      }}
    >
      <div className="flex flex-col h-full">
        <div className={`flex justify-between items-center p-3 border-b ${isDarkMode ? 'border-gray-700 bg-gray-800' : 'border-gray-200 bg-gray-50'
          }`}>
          <div className="flex items-center space-x-3">
            <div className="flex items-center space-x-2">
              <Label htmlFor="dark-mode" className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'
                }`}>
              </Label>
              <div className="flex items-center space-x-2">
                <Sun
                  size={18}
                  className={`transform transition-transform duration-300 hover:scale-125 hover:rotate-45 ${isDarkMode ? 'text-gray-400' : 'text-yellow-600'
                    }`}
                />
                <div
                  className={`relative w-12 h-6 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'
                    }`}
                  onClick={() => setIsDarkMode(!isDarkMode)}
                >
                  <div
                    className={`w-4 h-4 bg-white rounded-full shadow-md transform transition-all duration-500 ${isDarkMode ? 'translate-x-6 rotate-[360deg]' : ''
                      }`}
                  ></div>
                </div>
                <MoonStarIcon
                  size={18}
                  className={`transform transition-transform duration-300 hover:scale-125 ${isDarkMode ? 'text-blue-700' : 'text-gray-400'
                    }`}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Rest of the component remains the same */}
        <div style={{ height: `calc(100% - ${terminalHeight}px - 80px)` }}>
          <Editor
            height="100%"
            language="python"
            theme={isDarkMode ? "vs-dark" : "light"}
            value={code}
            onChange={handleEditorChange}
            onMount={handleEditorDidMount}
            className="transition-opacity duration-300"
            options={{
              minimap: { enabled: false },
              fontSize: 14,
              lineNumbers: 'on',
              scrollBeyondLastLine: false,
              padding: { top: 16 },
              automaticLayout: true,
              suggestOnTriggerCharacters: true,
              quickSuggestions: true,
              snippetSuggestions: 'inline',
              parameterHints: { enabled: true },
              suggest: {
                localityBonus: true,
                snippetsPreventQuickSuggestions: false,
                showIcons: true,
                showStatusBar: true,
                preview: true,
              }
            }}
          />
        </div>

        <div className={`border-t p-2 flex flex-wrap items-center justify-between gap-2 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-gray-100 border-gray-300'
          }`}>
          <div className="flex gap-2 flex-shrink-0">
            <Button
              onClick={handleRunCode}
              disabled={code.trim() === ''}
              variant={isDarkMode ? "default" : "secondary"}
              size="sm"
              className="group relative overflow-hidden"
            >
              <div className="absolute inset-0 group-hover:bg-white/20 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-700" />
              <Play
                size={16}
                className="mr-1 transition-all duration-300 group-hover:scale-110 group-hover:rotate-90"
              />
              Run
            </Button>
            <Button
              onClick={handleClearCode}
              variant={isDarkMode ? "destructive" : "outline"}
              size="sm"
              className="transform transition-transform duration-200 ease-out hover:-rotate-6 will-change-transform"
            >
              <Trash2 size={16} className="mr-1" />
              Clear
            </Button>
          </div>
          <input
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Enter input for the code (if required)..."
            className={`
                    flex-1 min-w-[200px] px-2 py-1 rounded text-sm 
                    focus:outline-none relative
                    before:absolute before:inset-0 before:rounded before:p-[2px]
                    before:bg-gradient-to-r before:from-blue-500 before:via-purple-500 before:to-pink-500
                    before:animate-gradient-x focus:before:opacity-100 before:opacity-0
                    transition-all duration-300
                    ${isDarkMode
                ? 'bg-gray-700 text-gray-200 placeholder-gray-400'
                : 'bg-white text-gray-900 placeholder-gray-500 border border-gray-300'
              }
                 `}
          />
          <style jsx global>{`
            @keyframes gradient-x {
              0% { background-position: 0% 50%; }
              50% { background-position: 100% 50%; }
              100% { background-position: 0% 50%; }
            }
            .animate-gradient-x {
              animation: gradient-x 3s linear infinite;
              background-size: 200% 200%;
            }
          `}</style>
        </div>

        <div
          className={`h-2 cursor-row-resize flex items-center justify-center border-t border-b select-none ${isDarkMode
            ? 'bg-gray-800 hover:bg-gray-700 border-gray-700'
            : 'bg-gray-200 hover:bg-gray-300 border-gray-300'
            } ${isDragging ? (isDarkMode ? 'bg-gray-700' : 'bg-gray-300') : ''}`}
          onMouseDown={handleMouseDown}
          style={{ touchAction: 'none' }}
        >
          <GripHorizontal size={16} className={isDarkMode ? 'text-gray-500' : 'text-gray-600'} />
        </div>

        <div
          style={{ height: `${terminalHeight}px` }}
          className={`border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-300'}`}
        >
          <ReactConsole
            commands={commands}
            noCommandFound="Command not found. Try 'python <command>' or 'run'"
            welcomeMessage="Welcome to the Python Terminal. Type 'help' for available commands."
            promptLabel=">>>"
            readOnly={false}
            autoFocus={false}
            ref={consoleRef}
            className={isDarkMode ? 'console-dark-mode' : 'console-light-mode'}
            style={{
              height: '100%',
              fontFamily: 'monospace',
              fontSize: '14px',
              padding: '1rem',
              overflowY: 'auto',
            }}
          />
        </div>
      </div>
    </div>
  )
}