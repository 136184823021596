import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import tabStyles from 'react-tabs/style/react-tabs.css';
import VM from 'scratch-vm';
import Renderer from 'scratch-render';
import { LayoutTemplate, CircleXIcon } from 'lucide-react';

import PythonIDE from './code-editor.jsx';
import Blocks from '../../containers/blocks.jsx';
import CostumeTab from '../../containers/costume-tab.jsx';
import TargetPane from '../../containers/target-pane.jsx';
import SoundTab from '../../containers/sound-tab.jsx';
import StageWrapper from '../../containers/stage-wrapper.jsx';
import Loader from '../loader/loader.jsx';
import Box from '../box/box.jsx';
import MenuBar from '../menu-bar/menu-bar.jsx';
import CostumeLibrary from '../../containers/costume-library.jsx';
import BackdropLibrary from '../../containers/backdrop-library.jsx';
import Watermark from '../../containers/watermark.jsx';
import Backpack from '../../containers/backpack.jsx';
// import WebGlModal from '../../containers/webgl-modal.jsx';
import TipsLibrary from '../../containers/tips-library.jsx';
import Cards from '../../containers/cards.jsx';
import Alerts from '../../containers/alerts.jsx';
import DragLayer from '../../containers/drag-layer.jsx';
import ConnectionModal from '../../containers/connection-modal.jsx';
import TelemetryModal from '../telemetry-modal/telemetry-modal.jsx';

import layout, { STAGE_SIZE_MODES } from '../../lib/layout-constants';
import { resolveStageSize } from '../../lib/screen-utils';
import { themeMap } from '../../lib/themes';

import styles from './gui.css';
// import { Button } from '../../components/ui/button'
// import { Card } from '../../components/ui/card'
// import { Input } from '../../components/ui/input'
// import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/ui/tabs'
import addExtensionIcon from './icon--extensions.svg';
import codeIcon from './icon--code.svg';
import costumesIcon from './icon--costumes.svg';
import soundsIcon from './icon--sounds.svg';
import logoalt from './img--stemworld.png';
import codingImage from './img--coding.png';
import aiMlImage from './img--ai_ml.png';
import iotElecImage from './img--iot_elec.png';
import arVrImage from './img--ar_vr.png';
import roboticsImage from './img--robotics.png';
import gameDevImage from './img--gamedev.png';
import print3dImage from './img--3dprint.png';
import dronesImage from './img--drones.png';
import gen_ai_img from './img--gen_ai.png';
import Cyber_img from './img--cyber.png';
import pythonIcon from './icon--python.svg';
import gameIcon from './icon--game.svg';
import ResetPasswordForm from '../menu-bar/reset-password.jsx';

import LandingPage from './stemverse-home.jsx';
import LoginDropdown from '../../components/menu-bar/login-dropdown.jsx';
const messages = defineMessages({
    addExtension: {
        id: 'gui.gui.addExtension',
        description: 'Button to add an extension in the target pane',
        defaultMessage: 'Add Extension'
    }
});

// let isRendererSupported = null;

const GUIComponent = props => {
    const {
        accountNavOpen,
        activeTabIndex,
        alertsVisible,
        authorId,
        authorThumbnailUrl,
        authorUsername,
        basePath,
        backdropLibraryVisible,
        backpackHost,
        backpackVisible,
        blocksId,
        blocksTabVisible,
        cardsVisible,
        canChangeLanguage,
        audioVideoSetting,
        canChangeTheme,
        canCreateNew,
        canEditTitle,
        canManageFiles,
        canRemix,
        canSave,
        canCreateCopy,
        canShare,
        canUseCloud,
        children,
        connectionModalVisible,
        costumeLibraryVisible,
        costumesTabVisible,
        enableCommunity,
        intl,
        isCreating,
        isFullScreen,
        isPlayerOnly,
        isRtl,
        isShared,
        isTelemetryEnabled,
        isTotallyNormal,
        loading,
        logo,
        renderLogin,
        onClickAbout,
        onClickAccountNav,
        onCloseAccountNav,
        onLogOut,
        onOpenRegistration,
        onToggleLoginOpen,
        onActivateCostumesTab,
        onActivateSoundsTab,
        onPythontab,
        onActivateTab,
        onClickLogo,
        onExtensionButtonClick,
        onProjectTelemetryEvent,
        onRequestCloseBackdropLibrary,
        onRequestCloseCostumeLibrary,
        onRequestCloseTelemetryModal,
        onSeeCommunity,
        onShare,
        onShowPrivacyPolicy,
        onStartSelectingFileUpload,
        onTelemetryModalCancel,
        onTelemetryModalOptIn,
        onTelemetryModalOptOut,
        showComingSoon,
        soundsTabVisible,
        pythonTabVisible,
        stageSizeMode,
        targetIsStage,
        telemetryModalVisible,
        theme,
        tipsLibraryVisible,
        vm,
        ...componentProps
    } = props;

    const [isModalOpen, setIsModalOpen] = useState(true);

    const [isRendererSupported, setIsRendererSupported] = useState(Renderer.isSupported());
    const [isSimulationOpen, setIsSimulationOpen] = useState(false);

    const handleToggleSimulation = () => {
        const newSimulationState = !isSimulationOpen;
        setIsSimulationOpen(newSimulationState);
        setIsRendererSupported(!newSimulationState);
    };
    const [isLoginOpen, setIsLoginOpen] = useState(false);

    // Function to close the main modal
    // const handleCloseModal = () => {
    //     setIsModalOpen(false);
    //     if (timeoutId) {
    //         clearTimeout(timeoutId);
    //     }
    // };
    // Function to handle option click in the modal
    const handleOptionClick = (option) => {
        onExtensionButtonClick();
        console.log('Option clicked:', option);

        // Close the main modal
        setIsModalOpen(false);

        // After closing the main modal, set a timeout to open the login modal after 2 seconds
        // setTimeout(() => {
        //     if(localStorage.getItem('isLoginModalOpen') === false || localStorage.getItem('isLoginModalOpen') === null){
        //         setIsLoginOpen(true);
        //         console.log("LoginDropdown should open now."); // Check if this logs
        //     }
        // }, 2000); // 2000 milliseconds = 2 seconds
    };

    // Class names for tabs styling
    const tabClassNames = {
        tabs: styles.tabs,
        tab: `${tabStyles.reactTabsTab} ${styles.tab}`,
        tabList: `${tabStyles.reactTabsTabList} ${styles.tabList}`,
        tabPanel: `${tabStyles.reactTabsTabPanel} ${styles.tabPanel}`,
        tabPanelSelected: `${tabStyles.reactTabsTabPanelSelected} ${styles.isSelected}`,
        tabSelected: `${tabStyles.reactTabsTabSelected} ${styles.isSelected}`
    };

    useEffect(() => {
        localStorage?.setItem('isLoginModalOpen', true);
    }, []);

    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
    const [resetToken, setResetToken] = useState(null);

    // Add this useEffect to handle reset password
    useEffect(() => {
        // Define the global handler
        window.handleResetLinkClick = (token) => {
            setResetToken(token);
            setIsResetPasswordModalOpen(true);
        };

        // Check URL for reset token
        const url = new URL(window.location.href);
        const urlToken = url.searchParams.get('reset_token');
        if (urlToken) {
            window.handleResetLinkClick(urlToken);
        }

        // Cleanup
        return () => {
            delete window.handleResetLinkClick;
        };
    }, []);

    if (isResetPasswordModalOpen) {
        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div className="w-full max-w-md bg-white/90 backdrop-blur-md shadow-xl rounded-lg p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold">Reset Password</h2>
                        {/* <button
                            onClick={() => setIsResetPasswordModalOpen(false)}
                            className="text-gray-500 hover:text-gray-700"
                        >
                            <CircleXIcon className="h-6 w-6" />
                        </button> */}
                    </div>
                    <ResetPasswordForm 
                        token={resetToken}
                        onSuccess={() => {
                            setIsResetPasswordModalOpen(false);
                            alert('Password reset successfully! Please login with your new password.');
                        }}
                    />
                </div>
            </div>
        );
    }


    return (
        <>
            {
                isModalOpen && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 9999,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add backdrop color

                        }}
                    >
                        <LandingPage handleTopicClick={handleOptionClick} />
                    </div>
                )}

            {/* LoginDropdown Modal */}
            {isLoginOpen && (
                <div
                    style={{
                        position: 'fixed',
                        // top: '50%',
                        // left: '50%',
                        // transform: 'translate(-50%, -50%)',
                        // backgroundColor: 'balck',
                        // padding: '20px',
                        // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                        zIndex: 10000, // Ensure it's above everything else
                        width: '50%', // Adjust modal width
                        // borderRadius: '10px', // Add some border radius for styling
                        // pointerEvents: 'none' // Prevent interaction with background
                    }}
                >
                    <LoginDropdown
                        isOpen={isLoginOpen}
                        onClose={() => setIsLoginOpen(false)}
                    />
                </div>
            )
            }

            <MediaQuery minWidth={layout.fullSizeMinWidth}>
                {isFullSize => {
                    const stageSize = resolveStageSize(stageSizeMode, isFullSize);
                    return isPlayerOnly ? (
                        <StageWrapper
                            isFullScreen={isFullScreen}
                            isRendererSupported={isRendererSupported}
                            isRtl={isRtl}
                            loading={loading}
                            stageSize={STAGE_SIZE_MODES.large}
                            vm={vm}
                            onToggleSimulation={handleToggleSimulation}
                            isSimulationOpen={isSimulationOpen}
                        >
                            {alertsVisible ? (
                                <Alerts className={styles.alertsContainer} />
                            ) : null}
                        </StageWrapper>
                    ) : (
                        <Box
                            className={styles.pageWrapper}
                            dir={isRtl ? 'rtl' : 'ltr'}
                            {...componentProps}
                        >
                            {/* Render the LoginDropdown based on isLoginOpen state */}
                            {/* {isLoginOpen && (
                                <LoginDropdown
                                    isOpen={isLoginOpen}
                                    onClose={() => setIsLoginOpen(false)}
                                    className="bg-red-500"
                                />
                            )} */}

                            {/* Other content of your GUI */}
                            {telemetryModalVisible ? (
                                <TelemetryModal
                                    isRtl={isRtl}
                                    isTelemetryEnabled={isTelemetryEnabled}
                                    onCancel={onTelemetryModalCancel}
                                    onOptIn={onTelemetryModalOptIn}
                                    onOptOut={onTelemetryModalOptOut}
                                    onRequestClose={onRequestCloseTelemetryModal}
                                    onShowPrivacyPolicy={onShowPrivacyPolicy}
                                />
                            ) : null}
                            {loading ? (
                                <Loader />
                            ) : null}
                            {isCreating ? (
                                <Loader messageId="gui.loader.creating" />
                            ) : null}
                            {tipsLibraryVisible ? (
                                <TipsLibrary />
                            ) : null}
                            {cardsVisible ? (
                                <Cards />
                            ) : null}
                            {alertsVisible ? (
                                <Alerts className={styles.alertsContainer} />
                            ) : null}
                            {connectionModalVisible ? (
                                <ConnectionModal
                                    vm={vm}
                                />
                            ) : null}
                            {costumeLibraryVisible ? (
                                <CostumeLibrary
                                    vm={vm}
                                    onRequestClose={onRequestCloseCostumeLibrary}
                                />
                            ) : null}
                            {backdropLibraryVisible ? (
                                <BackdropLibrary
                                    vm={vm}
                                    onRequestClose={onRequestCloseBackdropLibrary}
                                />
                            ) : null}
                            <MenuBar
                                accountNavOpen={accountNavOpen}
                                authorId={authorId}
                                authorThumbnailUrl={authorThumbnailUrl}
                                authorUsername={authorUsername}
                                canChangeLanguage={canChangeLanguage}
                                audioVideoSetting={audioVideoSetting}
                                canChangeTheme={canChangeTheme}
                                canCreateCopy={canCreateCopy}
                                canCreateNew={canCreateNew}
                                canEditTitle={canEditTitle}
                                canManageFiles={canManageFiles}
                                canRemix={canRemix}
                                canSave={canSave}
                                canShare={canShare}
                                className={styles.menuBarPosition}
                                enableCommunity={enableCommunity}
                                isShared={isShared}
                                isTotallyNormal={isTotallyNormal}
                                logo={logo}
                                renderLogin={renderLogin}
                                showComingSoon={showComingSoon}
                                onClickAbout={onClickAbout}
                                onClickAccountNav={onClickAccountNav}
                                onClickLogo={onClickLogo}
                                onCloseAccountNav={onCloseAccountNav}
                                onLogOut={onLogOut}
                                onOpenRegistration={onOpenRegistration}
                                onProjectTelemetryEvent={onProjectTelemetryEvent}
                                onSeeCommunity={onSeeCommunity}
                                onShare={onShare}
                                onStartSelectingFileUpload={onStartSelectingFileUpload}
                                onToggleLoginOpen={onToggleLoginOpen}
                            />
                            <Box className={styles.bodyWrapper}>
                                <Box className={styles.flexWrapper}>
                                    <Box className={styles.editorWrapper}>
                                        <Tabs
                                            forceRenderTabPanel
                                            className={tabClassNames.tabs}
                                            selectedIndex={activeTabIndex}
                                            selectedTabClassName={tabClassNames.tabSelected}
                                            selectedTabPanelClassName={tabClassNames.tabPanelSelected}
                                            onSelect={onActivateTab}
                                        >
                                            <TabList className={tabClassNames.tabList}>
                                                <Tab
                                                    className={tabClassNames.tab}
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <img
                                                        draggable={false}
                                                        src={codeIcon}
                                                    />
                                                    <FormattedMessage
                                                        defaultMessage="Code"
                                                        description="Button to get to the code panel"
                                                        id="gui.gui.codeTab"
                                                    />
                                                </Tab>
                                                <Tab
                                                    className={tabClassNames.tab}
                                                    onClick={onPythontab}
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <img
                                                        draggable={false}
                                                        src={pythonIcon}
                                                    />
                                                    <FormattedMessage
                                                        defaultMessage='Python'
                                                        description='Button to upload python script from file in the editor tab'
                                                        id='gui.pythonTab.uploadPythonScript' // Ensure this line is correctly closed with a '

                                                    />
                                                </Tab>
                                                <Tab
                                                    className={tabClassNames.tab}
                                                    onClick={onActivateCostumesTab}
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <img
                                                        draggable={false}
                                                        src={costumesIcon}
                                                    />
                                                    {targetIsStage ? (
                                                        <FormattedMessage
                                                            defaultMessage="Backdrops"
                                                            description="Button to get to the backdrops panel"
                                                            id="gui.gui.backdropsTab"
                                                        />
                                                    ) : (
                                                        <FormattedMessage
                                                            defaultMessage="Costumes"
                                                            description="Button to get to the costumes panel"
                                                            id="gui.gui.costumesTab"
                                                        />
                                                    )}
                                                </Tab>
                                                <Tab
                                                    className={tabClassNames.tab}
                                                    onClick={onActivateSoundsTab}
                                                    style={{ marginRight: '10px' }}
                                                >
                                                    <img
                                                        draggable={false}
                                                        src={soundsIcon}
                                                    />
                                                    <FormattedMessage
                                                        defaultMessage="Sounds"
                                                        description="Button to get to the sounds panel"
                                                        id="gui.gui.soundsTab"
                                                    />
                                                </Tab>
                                            </TabList>
                                            <TabPanel className={tabClassNames.tabPanel}>
                                                <Box className={styles.blocksWrapper}>
                                                    <Blocks
                                                        key={`${blocksId}/${theme}`}
                                                        canUseCloud={canUseCloud}
                                                        grow={1}
                                                        isVisible={blocksTabVisible}
                                                        options={{
                                                            media: `${basePath}static/${themeMap[theme].blocksMediaFolder}/`
                                                        }}
                                                        stageSize={stageSize}
                                                        theme={theme}
                                                        vm={vm}
                                                    />
                                                </Box>
                                                <Box className={styles.extensionButtonContainer}>
                                                    <button
                                                        className="flex items-center justify-center p-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold font-sm shadow-md border-none outline-none"
                                                        title={intl.formatMessage(messages.addExtension)}
                                                        onClick={onExtensionButtonClick}
                                                        style={{ width: '7.5rem', height: '2.3rem' }}
                                                    >
                                                        <LayoutTemplate className="w-6 h-6 mr-2" /> {/* Add the icon */}
                                                        <span>Extensions</span>
                                                    </button>
                                                </Box>
                                                <Box className={styles.watermark}>
                                                    <Watermark />
                                                </Box>
                                            </TabPanel>
                                            <TabPanel className={tabClassNames.tabPanel}>
                                                {pythonTabVisible ? <PythonIDE vm={vm} /> : null}
                                            </TabPanel>
                                            <TabPanel className={tabClassNames.tabPanel}>
                                                {costumesTabVisible ? <CostumeTab vm={vm} /> : null}
                                            </TabPanel>
                                            <TabPanel className={tabClassNames.tabPanel}>
                                                {soundsTabVisible ? <SoundTab vm={vm} /> : null}
                                            </TabPanel>
                                        </Tabs>
                                        {backpackVisible ? (
                                            <Backpack host={backpackHost} />
                                        ) : null}
                                    </Box>
                                    <Box className={`${styles.stageAndTargetWrapper} ${styles[stageSize]}`}>
                                        <StageWrapper
                                            isFullScreen={isFullScreen}
                                            isRendererSupported={isRendererSupported}
                                            isRtl={isRtl}
                                            stageSize={stageSize}
                                            vm={vm}
                                            onToggleSimulation={handleToggleSimulation}
                                            isSimulationOpen={isSimulationOpen}
                                        />
                                        {!isSimulationOpen ? (
                                            <Box className={styles.targetWrapper}>
                                                <TargetPane stageSize={stageSize} vm={vm} />
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Box>
                            </Box>
                            <DragLayer />
                        </Box>
                    );
                }
                }
            </MediaQuery >
        </>
    );
};

GUIComponent.propTypes = {
    accountNavOpen: PropTypes.bool,
    activeTabIndex: PropTypes.number,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    backdropLibraryVisible: PropTypes.bool,
    backpackHost: PropTypes.string,
    backpackVisible: PropTypes.bool,
    basePath: PropTypes.string,
    blocksTabVisible: PropTypes.bool,
    blocksId: PropTypes.string,
    canChangeLanguage: PropTypes.bool,
    canChangeTheme: PropTypes.bool,
    audioVideoSetting: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    canUseCloud: PropTypes.bool,
    cardsVisible: PropTypes.bool,
    children: PropTypes.node,
    costumeLibraryVisible: PropTypes.bool,
    costumesTabVisible: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    intl: intlShape.isRequired,
    isCreating: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    isPlayerOnly: PropTypes.bool,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isTelemetryEnabled: PropTypes.bool,
    isTotallyNormal: PropTypes.bool,
    loading: PropTypes.bool,
    logo: PropTypes.string,
    onActivateCostumesTab: PropTypes.func,
    onActivateSoundsTab: PropTypes.func,
    onPythontab: PropTypes.func,
    onActivateTab: PropTypes.func,
    onClickAccountNav: PropTypes.func,
    onClickLogo: PropTypes.func,
    onCloseAccountNav: PropTypes.func,
    onExtensionButtonClick: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onRequestCloseBackdropLibrary: PropTypes.func,
    onRequestCloseCostumeLibrary: PropTypes.func,
    onRequestCloseTelemetryModal: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onShowPrivacyPolicy: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onTelemetryModalCancel: PropTypes.func,
    onTelemetryModalOptIn: PropTypes.func,
    onTelemetryModalOptOut: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    renderLogin: PropTypes.func,
    showComingSoon: PropTypes.bool,
    soundsTabVisible: PropTypes.bool,
    pythonTabVisible: PropTypes.bool,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    targetIsStage: PropTypes.bool,
    telemetryModalVisible: PropTypes.bool,
    theme: PropTypes.string,
    tipsLibraryVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

GUIComponent.defaultProps = {
    backpackHost: null,
    backpackVisible: false,
    basePath: './',
    blocksId: 'original',
    canChangeLanguage: true,
    canChangeTheme: true,
    audioVideoSetting: true,
    canCreateNew: false,
    canEditTitle: false,
    canManageFiles: true,
    canRemix: false,
    canSave: false,
    canCreateCopy: false,
    canShare: false,
    canUseCloud: false,
    enableCommunity: false,
    isCreating: false,
    isShared: false,
    isTotallyNormal: false,
    loading: false,
    showComingSoon: false,
    stageSizeMode: STAGE_SIZE_MODES.large
};

const mapStateToProps = state => ({
    blocksId: state.scratchGui.timeTravel.year.toString(),
    stageSizeMode: state.scratchGui.stageSize.stageSize,
    theme: state.scratchGui.theme.theme
});
export default injectIntl(connect(mapStateToProps)(GUIComponent));