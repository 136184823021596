
let defineCode = '';
let setupCode = '';
let loopCode = '';
let bodyCode= '';

const codeGeneration = {
    // Method to accumulate code from different extensions
    accumulateCode(define,body,setup, loop) {
        defineCode += define.trim() + '\n';
        setupCode += setup.trim() + '\n';
        loopCode += loop.trim() + '\n';
        bodyCode += body.trim() + '\n';
    },

    // Method to generate the final combined code
    generateFinalCode() {
        return `
#include <SPI.h>
#include <Wire.h>
#include <Adafruit_GFX.h>
#include <Adafruit_SSD1306.h>

#define OLED_RESET 4
Adafruit_SSD1306 display(OLED_RESET);

void setup() {
  // put your setup code here, to run once:
  display.begin(SSD1306_SWITCHCAPVCC, 0x3C);
  display.clearDisplay();

}

void loop() {
  // put your main code here, to run repeatedly:
    display.setTextSize(1);
    display.setTextColor(WHITE);
    display.setCursor(0,0);
    display.println("STEM World, Hyderabad!");
    display.display();

}
        `;
    },

    // Method to reset the code (if needed)
    resetCode() {
        defineCode = '';
        setupCode = '';
        loopCode = '';
        bodyCode ='';
    }
};

module.exports = codeGeneration;