const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');


class CyberBullyingPrevention {
    getInfo() {
        return {
            id: 'cyberBullyingPrevention',
            name: 'Cyber Bullying Prevention',
             // colours to use for your extension blocks
             color1: '#20B2AA',
             color1: '#20B2AA',

            blocks: [
                {
                    opcode: 'detectHarmfulMessage',
                    blockType: BlockType.BOOLEAN,
                    text: 'detect harmful message [MESSAGE]',
                    arguments: {
                        MESSAGE: {
                            type: ArgumentType.STRING,
                            defaultValue: 'You are terrible!'
                        }
                    }
                },
                {
                    opcode: 'suggestPositiveResponse',
                    blockType: BlockType.REPORTER,
                    text: 'suggest positive response to [MESSAGE]',
                    arguments: {
                        MESSAGE: {
                            type: ArgumentType.STRING,
                            defaultValue: 'You are terrible!'
                        }
                    }
                },
                {
                    opcode: 'reportInappropriateContent',
                    blockType: BlockType.COMMAND,
                    text: 'report inappropriate content [MESSAGE]',
                    arguments: {
                        MESSAGE: {
                            type: ArgumentType.STRING,
                            defaultValue: 'This is inappropriate!'
                        }
                    }
                },
                {
                    opcode: 'raiseAwareness',
                    blockType: BlockType.COMMAND,
                    text: 'raise awareness about cyberbullying'
                },
                {
                    opcode: 'promotePositiveInteraction',
                    blockType: BlockType.COMMAND,
                    text: 'promote positive interaction by sending message [MESSAGE]',
                    arguments: {
                        MESSAGE: {
                            type: ArgumentType.STRING,
                            defaultValue: 'You are doing great!'
                        }
                    }
                }
            ]
        };
    }

    detectHarmfulMessage(args) {
        const harmfulKeywords = ['terrible', 'stupid', 'idiot', 'hate'];
        const message = args.MESSAGE.toLowerCase();

        // Check if the message contains harmful keywords
        return harmfulKeywords.some(keyword => message.includes(keyword));
    }

    suggestPositiveResponse(args) {
        const positiveResponses = [
            "You are valued!",
            "Let's be kind to each other.",
            "How can I help you?",
            "It's okay, things will get better!"
        ];

        // Return a random positive response
        const randomIndex = Math.floor(Math.random() * positiveResponses.length);
        return positiveResponses[randomIndex];
    }

    reportInappropriateContent(args) {
        const message = args.MESSAGE;
        console.log(`Reporting inappropriate content: ${message}`);
        // In a real system, this could trigger an actual reporting action.
        alert("Inappropriate content has been reported.");
    }

    raiseAwareness() {
        alert("Cyberbullying hurts people. Let's be kind and supportive to each other!");
    }

    promotePositiveInteraction(args) {
        const message = args.MESSAGE;
        console.log(`Promoting positive interaction: ${message}`);
        alert(`Positive message sent: "${message}"`);
    }
}

module.exports = CyberBullyingPrevention;
