const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');

class PasswordSafety {
    getInfo() {
        return {
            id: 'passwordSafety',
            name: 'Password Safety',
             // colours to use for your extension blocks
             color1: '#0033FF',
            //  color2: '#99FFCC',

            blocks: [
                // {
                //     opcode: 'knowAboutCyberSecurity',
                //     blockType: BlockType.COMMAND,
                //     text: 'Know about cyber security'
                // },
                {
                    opcode: 'generateSecurePassword',
                    blockType: BlockType.REPORTER,
                    text: 'generate secure password of length [LENGTH]',
                    arguments: {
                        LENGTH: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 12
                        }
                    }
                },
                {
                    opcode: 'checkPasswordStrength',
                    blockType: BlockType.REPORTER,
                    text: 'check password strength [PASSWORD]',
                    arguments: {
                        PASSWORD: {
                            type: ArgumentType.STRING,
                            defaultValue: 'password123'
                        }
                    }
                },
                {
                    opcode: 'doesPasswordMatch',
                    blockType: BlockType.BOOLEAN,
                    text: 'does password [PASSWORD1] match confirm password [PASSWORD2]',
                    arguments: {
                        PASSWORD1: {
                            type: ArgumentType.STRING,
                            defaultValue: 'password123'
                        },
                        PASSWORD2: {
                            type: ArgumentType.STRING,
                            defaultValue: 'password123'
                        }
                    }
                },
                {
                    opcode: 'encryptPasswordAES',
                    blockType: BlockType.COMMAND,
                    text: 'encrypt text [TEXT] with AES',
                    arguments: {
                        TEXT: {
                            type: ArgumentType.STRING,
                            defaultValue: 'secret message'
                        }
                    }
                },
                {
                    opcode: 'decryptPasswordAES',
                    blockType: BlockType.COMMAND,
                    text: 'decrypt AES text [CIPHERTEXT]',
                    arguments: {
                        CIPHERTEXT: {
                            type: ArgumentType.STRING,
                            defaultValue: ''
                        }
                    }
                },
                {
                    opcode: 'getEncryptedResult',
                    blockType: BlockType.REPORTER,
                    text: 'encrypted result',
                },
                {
                    opcode: 'generateCustomPassword',
                    blockType: BlockType.COMMAND,
                    text: 'Generate password with [CHAR1] and [CHAR2] of length [LENGTH]',
                    arguments: {
                        CHAR1: {
                            type: ArgumentType.STRING,
                            menu: 'characterTypeMenu1',
                            defaultValue: 'Capital Letters'
                        },
                        CHAR2: {
                            type: ArgumentType.STRING,
                            menu: 'characterTypeMenu2',
                            defaultValue: 'Numbers'
                        },
                        LENGTH: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 8
                        }
                    }
                },
               

            
            ],
            menus: {
                characterTypeMenu1: {
                    acceptReporters: true,
                    items: ['Capital Letters', 'Small Letters', 'Numbers', 'Symbols']
                },
                characterTypeMenu2: {
                    acceptReporters: true,
                    items: ['Capital Letters', 'Small Letters', 'Numbers', 'Symbols']
                }
            }
           
        };
    }

    generateSecurePassword(args) {
        const length = args.LENGTH;
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';
        let password = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        return password;
    }

    checkPasswordStrength(args) {
        const password = args.PASSWORD;
        let strength = 'weak';
        if (password.length >= 8 && /[A-Z]/.test(password) && /[0-9]/.test(password) && /[!@#$%^&*()_+]/.test(password)) {
            strength = 'strong';
        } else if (password.length >= 6) {
            strength = 'medium';
        }
        return strength;
    }

    doesPasswordMatch(args) {
        return args.PASSWORD1 === args.PASSWORD2;
    }
    async generateKey() {
        if (!this.key) {
            this.key = await crypto.subtle.generateKey(
                {
                    name: "AES-GCM",
                    length: 256
                },
                true, // whether the key is extractable
                ["encrypt", "decrypt"]
            );
        }
        return this.key;
    }

    async encryptPasswordAES(args) {
        const text = args.TEXT;
        const key = await this.generateKey();
        const encoder = new TextEncoder();
        const encodedText = encoder.encode(text);

        // Generate a random initialization vector (IV)
        const iv = crypto.getRandomValues(new Uint8Array(12));

        // Encrypt the text
        const encrypted = await crypto.subtle.encrypt(
            {
                name: "AES-GCM",
                iv: iv
            },
            key,
            encodedText
        );

        // Combine the IV and the encrypted data (for later decryption)
        const encryptedArray = Array.from(new Uint8Array(encrypted));
        const ivArray = Array.from(iv);
        const combined = ivArray.concat(encryptedArray);

        // Convert to a base64-like string for easy transport
        const encryptedResult = btoa(String.fromCharCode(...combined));

        // Store the result for retrieval
        this.encryptedResult = encryptedResult;

        // Return the encrypted result
        return encryptedResult;
    }

    async decryptPasswordAES(args) {
        const ciphertext = args.CIPHERTEXT;

        // Convert base64 string back to binary data
        const combined = Uint8Array.from(atob(ciphertext), c => c.charCodeAt(0));

        // Extract the IV and encrypted data
        const iv = combined.slice(0, 12);
        const encrypted = combined.slice(12);

        const key = await this.generateKey();

        try {
            // Decrypt the data
            const decrypted = await crypto.subtle.decrypt(
                {
                    name: "AES-GCM",
                    iv: iv
                },
                key,
                encrypted
            );

            // Decode back to text
            const decoder = new TextDecoder();
            return decoder.decode(decrypted);
        } catch (e) {
            return "Decryption failed!";
        }
    }

    getEncryptedResult() {
        // Return the last encrypted result
        return this.encryptedResult || 'No encryption performed yet';
    }

    async generateKey() {
        if (!this.key) {
            this.key = await crypto.subtle.generateKey(
                {
                    name: "AES-GCM",
                    length: 256
                },
                true, // whether the key is extractable
                ["encrypt", "decrypt"]
            );
        }
        return this.key;
    }
    displayHelpLink() {
        return ' https://edu.sphero.com/collections/170 '; // Replace with your actual PDF link
    }
        // Helper function to get character set based on selection
        getCharacterSet(selection) {
            const capitalLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const smallLetters = 'abcdefghijklmnopqrstuvwxyz';
            const numbers = '0123456789';
            const symbols = '!@#$%^&*()-_=+[]{};:,.<>?';
            
            switch (selection) {
                case 'Capital Letters':
                    return capitalLetters;
                case 'Small Letters':
                    return smallLetters;
                case 'Numbers':
                    return numbers;
                case 'Symbols':
                    return symbols;
                default:
                    return '';  // Default to empty string if no valid selection
            }
        }
          // Function to generate password based on two character selections and length
    generateCustomPassword(args) {
        const length = parseInt(args.LENGTH, 10) || 8;
        const char1Type = args.CHAR1;
        const char2Type = args.CHAR2;

        // Get the character sets for the two selected types
        const charSet1 = this.getCharacterSet(char1Type);
        const charSet2 = this.getCharacterSet(char2Type);

        // If either character set is empty, return a default warning message
        if (!charSet1 || !charSet2) {
            return 'Invalid character selection';
        }

        // Create a combined character set for generating the password
        const combinedCharacterSet = charSet1 + charSet2;

        let password = '';
        for (let i = 0; i < length; i++) {
            // Randomly select characters from the combined set
            password += combinedCharacterSet.charAt(Math.floor(Math.random() * combinedCharacterSet.length));
        }

        return password;
    }
    // knowAboutCyberSecurity() {
    //     window.open('https://teachablemachine.withgoogle.com/train', '_blank');
    // }

}
module.exports = PasswordSafety;
