import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Container, Grid, Paper, LinearProgress, Button, Typography, IconButton, CircularProgress, AppBar, Toolbar } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import UploadIcon from '@mui/icons-material/Upload';
import { ArrowLeft, Upload, Camera, Plus, X } from 'lucide-react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Webcam from 'react-webcam';
import * as tf from '@tensorflow/tfjs';
import * as posenet from '@tensorflow-models/posenet';
import * as knnClassifier from '@tensorflow-models/knn-classifier';
import html2canvas from 'html2canvas';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import mlImage from './image.png';
import * as cocossd from '@tensorflow-models/coco-ssd';
const PoseClassifier = ({ name, description, type }) => {
  const [classes, setClasses] = useState([
    { id: 1, name: 'Class 0', color: '#df4ef2', images: [] },
    { id: 2, name: 'Class 1', color: '#89f582', images: [] }
  ]);
  const [isPredicting, setIsPredicting] = useState(false);
  const [isTraining, setIsTraining] = useState(false);
  const [isModelTrained, setIsModelTrained] = useState(false);
  const [classifier, setClassifier] = useState(knnClassifier.create());
  const [activeWebcam, setActiveWebcam] = useState(null);
  const [net, setNet] = useState(null); // PoseNet model
  const [capturedPoses, setCapturedPoses] = useState([]);
  const [imageSrc, setImageSrc] = useState('');
  const [predictionResult, setPredictionResult] = useState('');
  const navigate = useNavigate();
  const [model, setModel] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loading state for webcam

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  // Load the model (CoCo SSD in this case)
  useEffect(() => {
    const loadModel = async () => {
      const loadedModel = await cocossd.load(); // Load the CoCo SSD model
      setNet(loadedModel); // Set the model (renamed to `net` to match existing code)
    };
    loadModel(); // Load the model on component mount
  }, []);

  // Toggle webcam for a specific class
  const toggleWebcamForClass = (classId) => {
    if (activeWebcam === classId) {
      setActiveWebcam(null);
    } else {
      setIsLoading(true); // Start loading
      setActiveWebcam(classId);

      // Simulate a short delay for camera to load
      setTimeout(() => {
        setIsLoading(false); // Stop loading once webcam feed is ready
      }, 1000); // Adjust this delay based on your webcam load time
    }
  };

  const handleBackClick = () => {
    navigate('../'); // Navigate back to the previous page
  };

  // Draw object detections (bbox, className, and score) with resizing
  const drawDetections = (detections, ctx, videoWidth, videoHeight, canvasWidth, canvasHeight) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    const scaleX = canvasWidth / videoWidth;
    const scaleY = canvasHeight / videoHeight;

    detections.forEach(({ bbox, class: className, score }) => {
      const [x, y, width, height] = bbox;
      const resizingFactor = 0.8;
      const resizedWidth = width * resizingFactor * scaleX;
      const resizedHeight = height * resizingFactor * scaleY;
      const verticalLift = 0.1 * height;
      const offsetX = (width - resizedWidth / scaleX) / 2;
      const offsetY = (height - resizedHeight / scaleY) / 2 - verticalLift;

      const scaledX = (x + offsetX) * scaleX;
      const scaledY = (y + offsetY) * scaleY;

      // Draw bounding box
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      ctx.strokeRect(scaledX, scaledY, resizedWidth, resizedHeight);

      // Draw label
      ctx.fillStyle = 'red';
      ctx.font = '16px Arial';
      ctx.fillText(`${className} (${Math.round(score * 100)}%)`, scaledX, scaledY > 10 ? scaledY - 5 : 10);
    });
  };

  // Capture and draw objects on the canvas
  useEffect(() => {
    let lastCall = 0;
    const throttleDelay = 200; // Call detection every 200ms
  
    const detectObjects = async () => {
      if (webcamRef.current && net) {
        const now = Date.now();
        if (now - lastCall < throttleDelay) {
          requestAnimationFrame(detectObjects);
          return;
        }
        lastCall = now;
  
        const video = webcamRef.current.video;
        if (video.readyState === 4) {
          const canvas = canvasRef.current;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  
          const detections = await net.detect(video);
          drawDetections(detections, ctx, video.videoWidth, video.videoHeight, canvas.width, canvas.height);
        }
      }
      requestAnimationFrame(detectObjects);
    };
  
    detectObjects();
  }, [net]);  

  // Updated capture image function to ensure webcam video and bounding box is drawn before capture
  const addImageToClassifier = (imageSrc, classId) => {
    const imgElement = new Image();
    imgElement.src = imageSrc;
    imgElement.onload = () => {
      const imgTensor = tf.browser.fromPixels(imgElement)
        .resizeBilinear([224, 224])
        .toFloat()
        .expandDims(0);

      classifier.addExample(imgTensor, classId - 1);
    };
    imgElement.onerror = () => {
      console.error('Error loading image for classification');
    };
  };

  const captureImage = useCallback((classId) => {
    if (canvasRef.current && webcamRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(webcamRef.current.video, 0, 0, canvas.width, canvas.height);
  
      // Use a pre-rendered canvas image for detection
      html2canvas(canvas).then(capturedCanvas => {
        const imageSrc = capturedCanvas.toDataURL('image/png');
        if (imageSrc) {
          setClasses(prevClasses =>
            prevClasses.map(cls => 
              cls.id === classId ? { ...cls, images: [...cls.images, imageSrc] } : cls
            )
          );
        }
      });
    }
  }, []);  

  // Handle image upload
  const handleImageUpload = (event, classId) => {
    const imageSrc = URL.createObjectURL(event.target.files[0]);
    setClasses(prevClasses =>
      prevClasses.map(cls =>
        cls.id === classId ? { ...cls, images: [...cls.images, imageSrc] } : cls
      )
    );
    addImageToClassifier(imageSrc, classId);
  };

  const handleTraining = async () => {
    setIsTraining(true);

    classes.forEach(cls => {
      cls.images.forEach(imageSrc => {
        const imgElement = new Image();
        imgElement.src = imageSrc;
        imgElement.onload = () => {
          const imgTensor = tf.browser.fromPixels(imgElement)
            .resizeBilinear([224, 224])
            .toFloat()
            .expandDims(0);
          classifier.addExample(imgTensor, cls.id - 1);
        };
      });
    });
    await new Promise(resolve => setTimeout(resolve, 3000));
    setIsTraining(false);
    setIsModelTrained(true);
    alert('Training Complete!');
  };
  const predictFromCanvas = async () => {
    if (canvasRef.current && isPredicting && classifier?.getNumClasses() > 0) {
      // Wrap the entire prediction process inside tf.tidy() to clean up intermediate tensors
      tf.tidy(() => {
        const ctx = canvasRef.current.getContext('2d');
        const imgData = ctx.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);

        // Create a tensor from the image data and resize it
        const imgTensor = tf.browser.fromPixels(imgData)
          .resizeBilinear([224, 224])
          .toFloat()
          .expandDims(0); // Add batch dimension
        // Perform prediction
        classifier.predictClass(imgTensor).then(prediction => {
          const adjustedClassIndex = prediction.classIndex + 1;
          const adjustedLabel = parseInt(prediction.label) + 1;

          // Update prediction result
          setPredictionResult({
            classIndex: adjustedClassIndex,
            label: adjustedLabel.toString(),
            confidences: prediction.confidences,
          });

          // Request the next frame for continuous prediction
          requestAnimationFrame(predictFromCanvas);
        }).catch(error => {
          console.error("Prediction error: ", error);
          setPredictionResult({
            classIndex: null,
            label: 'Prediction failed',
            confidences: {},
          });
        });
      });
    }
  };

  const toggleWebcamForPrediction = () => {
    setIsPredicting(prev => !prev);
    setActiveWebcam(isPredicting ? null : 'prediction');
    setPredictionResult('');
  };
  const addClass = () => {
    const newClassId = classes.length + 1;
    const colors = ['#34ebba', '#83f29d', '#b583f2', '#f2839d', '#d7fc5b'];
    setClasses([...classes, {
      id: newClassId,
      name: `class${newClassId}`,
      color: colors[newClassId % colors.length],
      images: []
    }]);
  };
  useEffect(() => {
    let interval;

    if (isPredicting) {
      interval = setInterval(() => {
        predictFromCanvas();
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isPredicting, classifier]);

  return (
    <div
      className="min-h-screen bg-gray-50 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${mlImage})` }}
    >
      <Container className="py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Training Section */}
          <div className="lg:col-span-2">
            <Paper className="p-6 mb-6 bg-transparent shadow-none">
              <div className="flex justify-between items-center mb-6">
                <Typography variant="h5" className="font-medium">
                  Training
                </Typography>
                <Button
                  onClick={addClass}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  className="bg-blue-500 text-white rounded-full px-5 py-2.5 hover:shadow-lg"
                >
                  Add New Class
                </Button>
              </div>
              <div className="flex flex-col items-center space-y-6">
                {classes.map((cls) => (
                  <Paper
                    key={cls.id}
                    className="overflow-hidden w-[400px] bg-transparent shadow-none"
                  >
                    <div className={`p-4 bg-[${cls.color}]`}>
                      <div className="flex justify-between items-center">
                        <Typography className="text-white font-medium">
                          {cls.name}
                        </Typography>
                        <div className="flex gap-2">
                          <Button
                            variant="contained"
                            component="label"
                            className="bg-white text-[cls.color] rounded-full px-5 py-2.5 hover:shadow-lg"
                            startIcon={<UploadIcon />}
                          >
                            Upload
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              onChange={(e) => handleImageUpload(e, cls.id)}
                            />
                          </Button>
                          <Button
                            variant="contained"
                            className="bg-white text-[cls.color] rounded-full px-5 py-2.5 hover:shadow-lg"
                            startIcon={<CameraAltIcon />}
                            onClick={() => toggleWebcamForClass(cls.id)}
                          >
                            {activeWebcam === cls.id ? 'Stop' : 'Start'}
                          </Button>
                        </div>
                      </div>
                      {/* Webcam Section */}
                      {activeWebcam === cls.id && (
                        <div className="mt-4 flex flex-col items-center">
                          <div className="relative w-[300px] h-[300px]">
                            {isLoading ? (
                              <div className="flex flex-col items-center justify-center w-full h-full">
                                <CircularProgress />
                                <Typography variant="body2" className="mt-2">
                                  Opening Camera...
                                </Typography>
                              </div>
                            ) : (
                              <>
                                <Webcam
                                  audio={false}
                                  ref={webcamRef}
                                  screenshotFormat="image/jpeg"
                                  className="absolute top-0 left-0 w-full h-full"
                                  videoConstraints={{
                                    width: 300,
                                    height: 300,
                                    facingMode: 'user',
                                  }}
                                />
                                <canvas
                                  ref={canvasRef}
                                  className="absolute top-0 left-0 w-full h-full"
                                />
                              </>
                            )}
                          </div>
                          <Button
                            variant="contained"
                            className="mt-4 bg-white text-[cls.color] rounded-full px-5 py-2.5 hover:shadow-lg"
                            onClick={() => captureImage(cls.id)}
                          >
                            Capture
                          </Button>
                        </div>
                      )}
                      {/* Image Gallery */}
                      {cls.images.length > 0 && (
                        <div className="mt-4 flex flex-wrap gap-2 justify-center">
                          {cls.images.map((image, index) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Sample ${index + 1}`}
                              className="w-12 h-12 object-cover rounded"
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </Paper>
                ))}
              </div>
            </Paper>
          </div>
          {/* Preview Section */}
          <div className="lg:col-span-1">
            <Paper className="p-6 sticky top-6">
              <Typography variant="h5" className="font-medium mb-6">
                Preview
              </Typography>
              <div className="space-y-4">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="rounded-full px-5 py-2.5 h-12 hover:shadow-lg"
                  onClick={handleTraining}
                  disabled={isTraining || isModelTrained}
                >
                  {isTraining ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Train Model'
                  )}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  className="h-12 hover:shadow-lg"
                  onClick={toggleWebcamForPrediction}
                >
                  {isPredicting ? 'Stop Preview' : 'Start Preview'}
                </Button>
                {isPredicting && (
                  <div className="mt-6">
                    <div className="flex justify-center">
                      <div className="relative w-[300px] h-[300px]">
                        <Webcam
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          className="absolute top-0 left-0 w-full h-full"
                          videoConstraints={{
                            width: 300,
                            height: 300,
                            facingMode: 'user',
                          }}
                        />
                        <canvas
                          ref={canvasRef}
                          className="absolute top-0 left-0 w-full h-full"
                        />
                      </div>
                    </div>
                    <Paper className="mt-4 p-4 bg-gray-50">
                      <Typography variant="subtitle1" className="font-medium">
                        Prediction Results
                      </Typography>
                      <div className="mt-2 space-y-4">
                        {predictionResult.confidences &&
                          Object.entries(predictionResult.confidences).map(
                            ([key, value]) => (
                              <div key={key} className="space-y-1">
                                <Typography
                                  variant="body2"
                                  className="text-gray-600"
                                >
                                  Class: {key}
                                </Typography>
                                <LinearProgress
                                  variant="determinate"
                                  value={value * 100}
                                  className="h-2 rounded"
                                />
                                <Typography
                                  variant="body2"
                                  className="text-gray-600"
                                >
                                  Confidence: {(value * 100).toFixed(1)}%
                                </Typography>
                              </div>
                            )
                          )}
                      </div>
                    </Paper>
                  </div>
                )}
              </div>
            </Paper>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default PoseClassifier;
