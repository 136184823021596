import React, { useState, useRef } from 'react';
import {
  Button, Container, Typography, TextField, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, AppBar, Toolbar, IconButton, Grid, Dialog, DialogTitle, DialogContent,
  DialogActions, useTheme
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import PictoBloxMLEnvironment from './components/PictoBloxMLEnvironment.js';
import AudioClassification from './components/AudioClassifier.js';
import PoseClassifier from './components/PoseClassifier.js';
import ObjectDetection from './components/ObjectDetection.js';
import HandDetection from './components/HandPose.js';
import image1 from './images/image1.png';
import poseClassifier from './images/image2.png';
import Object from './images/image3.png';
import Hand from './images/4.png';
import Audio from './images/image4.png';

// Custom theme with updated color
const theme = createTheme({
  palette: {
    primary: {
      main: '#003AD4', // Updated color
    },
    secondary: {
      main: '#003AD4', // Lighter teal for secondary elements
    },
    background: {
      default: '#f5f5f5',
    },
  },
});

function CreateProject() {
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [comingSoonOpen, setComingSoonOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null); // New state to track active component
  const navigate = useNavigate();

  const projectTypes = [
    { name: 'Image Classifier', image: image1, component: <PictoBloxMLEnvironment /> },
    { name: 'Pose Classifier', image: poseClassifier, component: <PoseClassifier /> },
    { name: 'Object Detection', image: Object, component: <ObjectDetection /> },
    { name: 'Hand Pose Classifier', image: Hand, component: <HandDetection /> },
    { name: 'Audio Classifier', image: Audio, component: <AudioClassification /> },
  ];

  const handleSelectProjectType = (type) => {
    console.log('Selected project type:', type);
    setSelectedType(type); // Set selected type when a project type is clicked
  };

  const handleCreateProject = () => {
    console.log('Creating project:', { projectName, projectDescription, selectedType });

    const foundType = projectTypes.find(pt => pt.name === selectedType);
    if (foundType) {
      setActiveComponent(foundType.component); // Show the selected component
    } else {
      setActiveComponent(<PictoBloxMLEnvironment />); // Default component
    }
  };

  const handleComingSoonClose = () => {
    setComingSoonOpen(false);
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <AppBar position="static" color="primary">
        <Toolbar className="bg-blue-600">
          <IconButton
            onClick={handleBackClick}
            className="text-white hover:bg-blue-700"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            className="flex-grow ml-2 font-bold text-white"
          >
            StemVerse Machine Learning Environment
          </Typography>
          <IconButton color="inherit">
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div className="flex-grow p-8 bg-white rounded-md shadow-md">
        {activeComponent ? (
          activeComponent
        ) : (
          <>
            <Typography
              variant="h5"
              className="mb-5 text-blue-600 font-bold"
            >
              Create New Project
            </Typography>

            <TextField
              label="Project Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
              className="mb-5"
            />

            <TextField
              label="Project Description"
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
              className="mb-5"
            />

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-8">
              {projectTypes.map((type) => (
                <div
                  key={type.name}
                  className={`p-4 cursor-pointer rounded-lg transition-transform shadow-md ${selectedType === type.name
                      ? 'bg-blue-100 transform scale-105 shadow-lg'
                      : 'bg-white transform scale-100'
                    }`}
                  onClick={() => handleSelectProjectType(type.name)}
                >
                  <div className="relative w-full h-36 overflow-hidden rounded-md">
                    <img
                      src={type.image}
                      alt={type.name}
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <Typography
                    variant="h6"
                    className="mt-3 text-center text-blue-600 font-bold"
                  >
                    {type.name}
                  </Typography>
                </div>
              ))}
            </div>

            <div className="mt-10 text-center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateProject}
                className="px-6 py-3 font-bold shadow-md"
                disabled={!selectedType}
              >
                Create Project
              </Button>
            </div>
          </>
        )}
      </div>

      {/* Coming Soon Dialog */}
      <Dialog open={comingSoonOpen} onClose={handleComingSoonClose}>
        <DialogTitle>Coming Soon</DialogTitle>
        <DialogContent>
          <Typography>
            Object Detection, Hand Pose Classifier, Audio Classifier are coming
            soon!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleComingSoonClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<CreateProject />} />
          {/* Remove the other routes since we're handling component rendering in CreateProject */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;