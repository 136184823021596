const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const axios = require('axios');
const codeGeneration = require('../codeGeneration');

let setupCode = '';
let loopCode = '';
let defineCode = '';
let bodyCode = '';
let codeContext = 'setup';
let initializedPins = new Set();
let definedFunctions = new Set();

class Esp32Blocks {
    constructor(runtime) {
        this.runtime = runtime;
        this.definedVariables = new Set();
        codeContext = localStorage.getItem('codeContext') || 'setup';
        codeContext = localStorage.setItem('codeContext', 'setup');
    }

    getInfo() {
        return {
            id: 'esp32',
            color1: '#AF7AC5',
            name: formatMessage({
                id: 'esp32.categoryName',
                default: 'ESP32',
                description: 'ESP32 extension category'
            }),
            blocks: [
                {
                    opcode: 'includeHeader',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'esp32.includeHeader',
                            default: 'Include header [headerFile]',
                        description: 'Include a header file'
                    }),
                    arguments: {
                        headerFile: {
                            type: ArgumentType.STRING,
                            menu: 'headerMenu',
                            defaultValue: 'Wire.h'
                        }
                    }
                },
                {
                    opcode: 'setCodeContext',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                                    id: 'roboticarm.setCodeContext',
                        default: 'call the fuction inside [context]',
                        description: 'Set whether to add code to setup or loop section.'
                    }),
                    arguments: {
                        context: {
                            type: ArgumentType.STRING,
                            menu: 'contextMenu',
                            defaultValue: 'setup'
                        }
                    },
                    func: 'setCodeContext'
                },
                {
                    opcode: 'ifBlock',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.ifBlock',
                        default: 'If [condition] then execute',
                        description: 'Evaluate condition and execute code'
                    }),
                    arguments: {
                        condition: {
                            type: ArgumentType.BOOLEAN,
                            defaultValue: true
                        }
                    }
                },
                {
                    opcode: 'elseifBlock',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.elseifBlock',
                        default: 'else if [condition] then execute',
                        description: 'Evaluate condition and execute code'
                    }),
                    arguments: {
                        condition: {
                            type: ArgumentType.BOOLEAN,
                            defaultValue: true
                        }
                    }
                },
                {
                    opcode: 'elseBlock',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.elseBlock',
                        default: 'else then execute',
                        description: 'Evaluate condition and execute code'
                    })
                },
                {
                    opcode: 'closeBlock',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.closeBlock',
                        default: 'Close the condition block',
                        description: 'Close the condition block'
                    })
                },
                {
                    opcode: 'setSerialBaudRate',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.setSerialBaudRate',
                        default: 'Set serial baud rate to [baudrate]',
                        description: 'Set the baud rate for serial communication'
                    }),
                    arguments: {
                        baudrate: {
                            type: ArgumentType.STRING,
                            menu: 'baudrateMenu',
                            defaultValue: '9600'
                        }
                    }
                },
                {
                    opcode: 'readSerialValue',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.readSerialValue',
                        default: 'Read Serial value',
                        description: 'Read serial value from defined serial port'
                    })
                },
                {
                    opcode: 'storeSerialValue',
                    blockType: BlockType.COMMAND,
                    text: formatMessage({
                        id: 'arduinouno.storeSerialValue',
                        default: 'Store the serial value as [datatype]',
                        description: 'Read serial value from defined serial port'
                    }),
                    arguments: {
                        datatype: {
                            type: ArgumentType.STRING,
                            menu: 'datatypeMenu',
                            defaultValue: 'int'
                        }
                    }
                },
            ],
            menus: {
                digitalPortMenu: {
                    items: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']
                },
                ipopMenu: {
                    items: ['OUTPUT', 'INPUT']
                },
                onoffMenu1: {
                    items: ['ACTIVE', 'INACTIVE']
                },
                varMenu1: {
                    items: ['m1a', 'm1b', 'm2a', 'm2b']
                },
                headerMenu: {
                    items: ['Wire.h', 'SPI.h','Adafruit_GFX.h', 'Adafruit_SSD1306.h']
                },
                varMenu2: {
                    acceptReporters: true,
                    items: ['v1', 'v2', 'v3', 'v4']
                },
                numMenu1: {
                    items: ['1', '2', '3', '4']
                },
                numMenu2: {
                    items: ['1', '2', '3', '4']
                },
                numMenu3: {
                    items: ['5', '6', '7', '8', '9', '10', '11', '12']
                },
                dirMenu: {
                    items: ['forward', 'backward']
                },
                stateMenu: {
                    items: ['free', 'hold']
                },
                onoffMenu2: {
                    items: ['ON', 'OFF']
                },
                contextMenu: {
                    items: ['setup', 'loop']
                },
                baudrateMenu: {
                    items: ['300', '1200', '2400', '4800', '9600', '19200', '38400', '57600', '115200', '230400', '250000', '500000', '1000000', '2000000']
                },
                datatypeMenu: {
                    items: ['int', 'float', 'char', 'long', 'double']
                }
            }
        };
    }

    accumulateCode() {
        codeGeneration.accumulateCode(defineCode, bodyCode, setupCode, loopCode);
    }

    // Add this method to clear loopCode
    clearLoopCode() {
        loopCode = '';
    }


    resetCode() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
    }

    full_reset() {
        setupCode = '';
        loopCode = '';
        defineCode = '';
        bodyCode = '';
        initializedPins = new Set();
        this.clearLoopCode();
        this.definedVariables.clear();
        definedFunctions = new Set();
    }

    setCodeContext(args) {
        const context = args.context;
        if (context === 'setup' || context === 'loop') {
            codeContext = context;
            // Save codeContext to local storage
            localStorage.setItem('codeContext', context);
        } else {
            console.error('Invalid context. Must be "setup" or "loop".');
        }
    }

    includeHeader(args) {
        const headerFile = args.headerFile;
        defineCode += `#include <${headerFile}>\n`;
        this.accumulateCode();
        this.resetCode();
    }
    setSerialBaudRate(args) {
        const baudrate = args.baudrate;
        if (!setupCode.includes('Serial.begin')) {
            setupCode += `Serial.begin(${baudrate});\n`;

        }
        this.accumulateCode();
        this.resetCode();
    }
    readSerialValue() {
        if (!definedFunctions.has('Serial_val')) {
            bodyCode += `int serial_val(){
                    if (Serial.available()>0){
                        int val =Serial.read();
                    return val;
                    }
                    }
                `;
            definedFunctions.add('Serial_val');
        }
        this.accumulateCode();
        this.resetCode();
    }
    storeSerialValue(args) {
        const datatype = args.datatype;  // Get the selected data type
        const codeToAdd = `${datatype} val = serial_val();\n`;  // Use the selected datatype

        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += codeToAdd;
        } else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += codeToAdd;
        }

        this.accumulateCode();
        this.resetCode();
    }
    ifBlock(args) {
        const condi = args.condition
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `if(${condi}){\n`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `if(${condi}){\n`;
        }
        this.accumulateCode();
        this.resetCode();
    }
    elseifBlock(args) {
        const condi = args.condition
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `}else if(${condi}){\n`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `}else if(${condi}){\n`;
        }
        this.accumulateCode();
        this.resetCode();
    }
    elseBlock() {
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `}else{\n`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `}else{\n`;
        }
        this.accumulateCode();
        this.resetCode();
    }
    closeBlock(){
        if (localStorage.getItem('codeContext') === 'setup') {
            setupCode += `}`;
        }
        else if (localStorage.getItem('codeContext') === 'loop') {
            loopCode += `}`;
        }
        this.accumulateCode();
        this.resetCode();
    }
}

module.exports = Esp32Blocks;
